import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import LineTitle from "../../atom/LineTitle";
import AnimationSearchInput from "../../molecule/AnimationSearchInput";
import InputGrid from "../../organism/InputGrid";
import PCBStore from "../../store/PCBStore";
import { MultiSearchInputStyle, PageStyle } from "../../Style";
import { PCBTopInputList } from "../../x-pcb/utility";
import txt from "../../Text";

const PCBInfoStyle = styled(PageStyle)`
  .multiContainer {
    width: 50%;
    margin-top: 15px;
  }
`;
export default function PCBInformationSection({ readOnly }) {
  const {
    setinformationRef,
    setsearchBoxType,
    setsearchBoxTargetIndex,
    searchBoxType,
    searchBoxTargetIndex,
    stateObj,
    setStateByKey,
  } = PCBStore();

  const PCBstore = PCBStore();
  const informationRef = useRef();

  useEffect(() => {
    setinformationRef(informationRef);
  }, []);
  const suppllierInputList =
    stateObj.produceOrganizationId &&
    stateObj.produceOrganizationId.map((item, i) => {
      return (
        <MultiSearchInputStyle key={i}>
          {!readOnly &&
            (i === stateObj.produceOrganizationId.length - 1 ? (
              <span
                className="plus"
                onClick={() => {
                  if (
                    stateObj.produceOrganizationId[
                      stateObj.produceOrganizationId.length - 1
                    ].id !== ""
                  ) {
                    setStateByKey("produceOrganizationId")([
                      ...stateObj.produceOrganizationId,
                      { id: "", name: "" },
                    ]);
                  }
                }}
              >
                +
              </span>
            ) : (
              <span className="plus"> </span>
            ))}
          <AnimationSearchInput
            readOnly={readOnly}
            width="100%"
            height="40px"
            placeholder={txt.mpba.produceOrganizationId}
            state={item.name || item.name2 || item.code1 || item.code2}
            onClick={() => {
              setsearchBoxType("produceOrganizationId");
              setsearchBoxTargetIndex(i);
            }}
            isNow={
              searchBoxType === "produceOrganizationId" &&
              searchBoxTargetIndex === i
            }
          />
        </MultiSearchInputStyle>
      );
    });
  return (
    <PCBInfoStyle ref={informationRef}>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        Item Information
      </LineTitle>
      <InputGrid
        readOnly={readOnly}
        modulestore={PCBstore}
        inPutList={PCBTopInputList}
      />

      <div className="multiContainer">{suppllierInputList}</div>
    </PCBInfoStyle>
  );
}
