import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import TableIndexRow from "../../atom/TableIndexRow";
import ScrollController from "../../molecule/ScrollController";
import ModalBox from "../../organism/ModalBox";
import boardStore from "../../store/boardStore";
import {
  BlueButtonStyle,
  PageStyle,
  ScrollDefault,
  SectionGap,
} from "../../Style";
import ChildRow from "../../molecule/ChildRow";
import { useGetPBABomData, useGetPBAData } from "../utility";
import RouteSection from "../../organism/RouteSection";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import PBAStore from "../../store/PBAStore";

const BoardBomPageStyle = styled(PageStyle)`
  display: flex;

  width: 100%;
  .mainPart {
    margin-left: 24px;
    height: calc(100vh - 280px);
    width: calc(100% - 100px);
    ${ScrollDefault}
    .flex {
      display: flex;
      flex-shrink: 1;
      .rightIndex {
        width: 55vw;
        flex-shrink: 0;
      }
    }
  }
  .childRows {
    position: relative;

    padding-left: 12px;
    ::before {
      content: "";
      display: block;
      position: absolute;
      top: -20px;
      height: 100%;
      border-left: 2px solid var(--textGray);
    }
    .newPBA {
      cursor: pointer;
    }
  }
  .routeBox {
    width: 45%;
  }
`;
export default function PBABOMPage() {
  const tokenAxios = useTokenAxios();
  const [isFold, setisFold] = useState(false);
  const store = PBAStore();
  const params = useParams();
  const { type } = params;
  const getPBAData = useGetPBAData(params.id, store);
  const getBomData = useGetPBABomData(params.id, store);

  useEffect(() => {
    getPBAData();
    getBomData();
    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  return (
    <BoardBomPageStyle childNum={store.pbaRowList.length}>
      <div className="mainPart">
        <div className="flex">
          <TableIndexRow
            widthArray={[6, 6]}
            itemArray={["", "Item Number"]}
            backgroundColor="white"
          />
          <div className="rightIndex">
            <TableIndexRow
              widthArray={[6, 6, 7, 8]}
              itemArray={[
                "Item Name",
                "Item Classification",
                "Specification",
                "Revision",
              ]}
              backgroundColor="white"
            />
          </div>
        </div>

        {!isFold && (
          <div className="childRows">
            <ChildRow
              widthArray={[7, 7, 8, 7, 3]}
              isAddButton={false}
              onClick={() => store.openModal("pba")}
              isFirst={false}
              key={0}
              border={!store.pbaRowList.id && "solid 3px var(--textGray)"}
              backgroundColor="white"
              readOnly
              leftItemArray={
                store.pbaRowList?.id
                  ? ["PBA", store.pbaRowList.number]
                  : [
                      <span
                        className={type !== "detail" && "newPBA"}
                        onClick={() =>
                          type !== "detail" && store.openModal("pba")
                        }
                      >
                        {type !== "detail" ? "Click to add PBA" : "no item"}
                      </span>,
                    ]
              }
              rightItemArray={
                store.pbaRowList?.id
                  ? [
                      store.pbaRowList.name,
                      store.pbaRowList.classificationId,
                      store.pbaRowList.specification,
                      store.pbaRowList.revision,
                      "",
                    ]
                  : ""
              }
              treeData={store.pbaRowList.children}
            />
          </div>
        )}
        <SectionGap />
        <div className="routeBox">
          {store.isRouteActive && (
            <RouteSection
              activate
              readOnly={type === "detail"}
              moduleStore={store}
            />
          )}
        </div>
      </div>
    </BoardBomPageStyle>
  );
}
