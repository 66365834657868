import React from "react";
import styled from "styled-components";
import BoldText from "./BoldText";

const TableIndexRowStyle = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  min-width: ${(props) => (props.width ? props.width : "")};
  height: calc(${(props) => (props.height ? props.height : "50px")});
  padding: 0 10px;
  border-bottom: ${(props) =>
    props.noBorder ? "" : "solid var(--eciBlue) 1px"};
  border-radius: 10px 10px 0 0;
  display: grid;
  grid-template-columns: ${(props) => props.widthRatio};
  grid-template-rows: 1;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
  ${(props) => props.cssStyle}
`;
export default function TableIndexRow({
  widthArray = [],
  itemArray = [],
  width,
  height,
  noBorder,
  backgroundColor,
  fontSize = "12px",
  cssStyle,
}) {
  const row = itemArray.map((item, i) =>
    widthArray[i] > 0 ? (
      <BoldText
        key={i}
        fontSize={fontSize}
        color="var(--textDarkGray)"
        width={width}
      >
        {item}
      </BoldText>
    ) : (
      <div key={i} />
    )
  );
  const widthRatio = widthArray.map((num) => `${num}fr `);

  return (
    <TableIndexRowStyle
      cssStyle={cssStyle}
      noBorder={noBorder}
      widthRatio={widthRatio}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
    >
      {row}
    </TableIndexRowStyle>
  );
}
