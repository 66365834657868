import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ScrollContainer from "../../organism/ScrollContainer";
import AttachmentSection from "../../organism/AttachmentSection";
import Button from "../../atom/Button";
import RouteInitSection from "../../organism/RouteInitSection";

import RouteSection from "../../organism/RouteSection";

import { PageStyle, SectionGap } from "../../Style";
import { conditionByTypeObject } from "../../utility/Source";

import { appendProjectForm, useGetProjectData } from "../utility";
import projectStore from "../../store/projectStore";
import ProjectInformationSection from "../organism/ProjectInformationSection";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import useSaveFunction from "../../utility/hooks/useSaveFunction";
import useTempSaveFunction from "../../utility/hooks/useTempSaveFunction";
import TreeModal from "../../organism/TreeModal/TreeModal";
import SearchBoxGroup from "../../organism/SearchBoxGroup";

export default function ProjectPage() {
  const tokenAxios = useTokenAxios();
  const params = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const navigate = useNavigate();
  //    ---MODEL
  const store = projectStore();
  const phase =
    searchParam.get("phase") > 10
      ? searchParam.get("phase") - 10
      : searchParam.get("phase"); // phase 11,12일 경우 선행개발 -> 1,2와 동일
  const { type } = params;
  const reviseId = searchParam.get("reviseId") || "";

  //    ---VIEW

  //    ---ACTION
  const saveFunc = useSaveFunction(
    "project",
    appendProjectForm,
    store,
    conditionByTypeObject[type].saveFuncType === "put" || phase * 1 > 1,
    params.id,
    reviseId
  );
  const saveTempFunc = useTempSaveFunction(
    "project",
    appendProjectForm,
    store,
    conditionByTypeObject[type].saveFuncType === "put",
    params.id
  );
  //  ---BUSINESS LOGIC

  const getData = useGetProjectData(params.id, store);

  useEffect(() => {
    store.setisRouteActive(
      (phase && phase % 2 === 0) || conditionByTypeObject[type].isRouteActive
    );
    if (params.id !== "new") {
      getData();
    }
    // period세팅
    if (phase > 0) {
      store.setperiod(phase * 1);
    }
    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  return (
    <PageStyle>
      <TreeModal
        url="project/product-page"
        treeConfigure={(data) =>
          (data.find((obj) => obj.id === 1) || {}).children
        }
        isActivate={store.isModal}
        setisActivate={store.setisModal}
        applyFunction={(finalItemList) => {
          // 중복제거
          const tmpArr = [...store.products, ...finalItemList];
          store.setproducts(tmpArr);
        }}
      />
      <ScrollContainer
        isWideScroll={conditionByTypeObject[type].isScrollWide}
        scrollRefList={[
          [store.informationRef, "Project Information"],
          [store.attachmentRef, "Project Attachment"],
          [store.routeRef, "Route Information"],
        ]}
        searchBoxComponent={<SearchBoxGroup store={store} />}
        tempButtonTitle={!reviseId && "save as draft"}
        tempButtonOnclick={saveTempFunc}
      >
        <ProjectInformationSection readOnly={store.isRouteActive} />
        <SectionGap />

        <AttachmentSection
          editMode={conditionByTypeObject[type].isAttachmentEdit || phase > 2}
          title="Item Attachment"
          readOnly={store.isRouteActive}
          moduleStore={store}
        />
        <SectionGap />
        {!store.isRouteActive && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveFunc}
            condition={store.getisConditionFullfill()}
          >
            Save and Route
          </Button>
        )}
        {store.isRouteActive &&
          (conditionByTypeObject[type].routeSectionType === "init" &&
          phase < 2 ? (
            <RouteInitSection
              activate
              routeType="Project"
              typeId={store.stateObj.projectTypeId}
              itemId={store.id || params.id}
              moduleStore={store}
              afterUrl="/"
            />
          ) : (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={store}
            />
          ))}
      </ScrollContainer>
    </PageStyle>
  );
}
