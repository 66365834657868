import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BoldText from "../../atom/BoldText";
import Input from "../../atom/Input";
import Button from "../../atom/Button";
import PlainSearchInput from "../../molecule/PlainSearchInput";
import TeamSearchUi from "./TeamSearchUi";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import Logo from "../../static/logo1.svg";
import txt from "../../Text";

const SignupModalStyle = styled.div`
  padding: 20px 0;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  width: 400px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .isAdminPart {
    display: flex;
    justify-content: space-between;
    color: var(--textDarkGray);
    font-size: 12px;
    width: 300px;
  }
  .teamModal {
    width: 200px;
    position: absolute;
    right: calc(50% - 420px);
    bottom: 0;
  }
  .positionModal {
    width: 200px;
    min-height: 240px;
    position: absolute;
    right: calc(50% - 420px);
    bottom: 0;

    background-color: white;
    border-radius: 10px;
    padding: 22px 10px;
    color: var(--textGray);
    .check {
      display: flex;
      border-bottom: solid 1px var(--textDarkGray);
      height: 40px;
      line-height: 40px;
      input,
      div {
        font-size: 12px;
        color: var(--textDarkGray);
      }
    }
  }
  .profileImg {
    display: flex;
    flex-direction: column;
    height: 80px;
    align-items: center;
    justify-content: space-evenly;
    color: var(--textGray);
    font-size: 14px;
    position: relative;
    .sp {
      font-size: 12px;
      color: black;
      margin: 0 auto;
      text-align: center;
    }
    img {
      width: 45px;
      height: 45px;
      border-radius: 400px;
      background-color: white;
    }
    label {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 40px;
      right: -5px;
      background-color: white;
      border-radius: 3px;
      border: solid black 1px;
    }
  }
`;
const InputStyle = styled.div`
  border-bottom: solid 1px var(--textDarkGray);
  margin-bottom: 8px;
`;
const ErrorStyle = styled.div`
  height: 15px;
  margin-top: 5px;
  line-height: 15px;
  font: 8px red;
  color: red;
`;
const ContainerStyle = styled.div`
  position: relative;
  width: 1000px;
  max-width: 90%;
`;
export default function SignupModal({
  readOnly,
  memberObj = {
    username: "",
    email: "",
    departmentId: "",
    positionId: "",
    profileImage: "",
  },
  setisModal,
}) {
  // ---CONST
  const CHECKLIST = txt.positionCheckList;
  const PASSWORDERRMSG = txt.passwordErrorMessage;
  const PHONENUMBERERRMSG = "전화번호는 - 없이 작성해주세요.";
  const tokenAxios = useTokenAxios();
  const regExPhoneNumber = /^[0-9]{3}[0-9]{3,4}[0-9]{4}$/;
  const regExPassword = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*+=-])(?=.*[0-9]).{8,15}$/;
  const unValidRegEx = /^(?=314159365358979axx).+/;
  // ---MODEL
  const [name, setname] = useState(memberObj.username);
  const [email, setemail] = useState(memberObj.email);
  const [password, setpassword] = useState("");
  const [phoneNumber, setphoneNumber] = useState(memberObj.contact);
  const [isAdmin, setisAdmin] = useState(false);
  const [team, setteam] = useState(memberObj.departmentId);
  const [position, setposition] = useState(memberObj.positionId);
  const [profileImage, setprofileImage] = useState({ Logo });
  const [ImagePreview, setImagePreview] = useState(memberObj.profileImage);

  const [isModalReadOnly, setisModalReadOnly] = useState(false);
  const [isModalEditMode, setisModalEditMode] = useState(false);
  const [isTeamModal, setisTeamModal] = useState(false);
  const [isPositionModal, setisPositionModal] = useState(false);

  const [teamTreeData, setteamTreeData] = useState([]);

  const [isPasswordValid, setisPasswordValid] = useState(true);
  const [isPhoneNumberValid, setisPhoneNumberValid] = useState(true);
  // ---ACTION
  const onChangeFiles = (e) => {
    const { files } = e.target;

    setprofileImage(files[0]);

    const reader = new FileReader();
    reader.onload = (eve) => {
      setImagePreview(eve.target.result);
    };
    reader.readAsDataURL(files[0]);
  };
  const sendEdit = async () => {
    const formData = new FormData();
    formData.append("username", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("contact", phoneNumber);
    formData.append(
      "department1Id",
      team.classificationId?.split("/")[0] || -1
    );
    formData.append(
      "department2Id",
      team.classificationId?.split("/")[1] || -1
    );
    formData.append(
      "department3Id",
      team.classificationId?.split("/")[2] || -1
    );
    formData.append("positionId", isAdmin ? [position.id, 0] : position.id);
    if (typeof profileImage !== "string" && !!profileImage.name) {
      formData.append("profileImage", profileImage);
    }
    await tokenAxios.put(`members/${memberObj.id}`, formData);
    alert("Done!");
    setisModal(false);
  };
  const sendPost = async () => {
    const formData = new FormData();
    formData.append("username", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("contact", phoneNumber);
    formData.append("department1Id", team.classification?.split("/")[0] || -1);
    formData.append("department2Id", team.classification?.split("/")[1] || -1);
    formData.append("department3Id", team.classification?.split("/")[2] || -1);
    formData.append("positionId", isAdmin ? [position.id, 0] : position.id);
    if (typeof profileImage !== "string" && !!profileImage.name) {
      formData.append("profileImage", profileImage);
    }
    await tokenAxios.post(`sign-up`, formData);
    alert("Done!");
    setisModal(false);
  };
  // ---BL
  const getTeamTreeData = async () => {
    const response = await tokenAxios.get("departments");
    setteamTreeData(response.data.result.data);
  };
  useEffect(() => {
    getTeamTreeData();
  }, []);
  useEffect(() => {
    if (phoneNumber) {
      if (!regExPhoneNumber.test(phoneNumber)) {
        setisPhoneNumberValid(false);
      } else {
        setisPhoneNumberValid(true);
      }
    }
  }, [phoneNumber]);
  useEffect(() => {
    if (password) {
      if (!regExPassword.test(password)) {
        setisPasswordValid(false);
      } else {
        setisPasswordValid(true);
      }
    }
  }, [password]);
  useEffect(() => {
    setemail(memberObj.email);
    setImagePreview(memberObj.profileImage);
    setname(memberObj.username);
    setphoneNumber(memberObj.contact);
    setteam(memberObj.departmentId);
    setposition(memberObj.positionId);
    setisModalReadOnly(readOnly);
    setisAdmin(memberObj.admin);
    return () => {
      setemail("");
      setImagePreview("");
      setname("");
      setphoneNumber("");
      setteam("");
      setposition("");
    };
  }, [memberObj]);
  return (
    <ContainerStyle>
      <SignupModalStyle>
        <BoldText color="var(--textDarkGray)" fontSize="20px">
          {isModalEditMode && "Edit Information"}
          {!isModalEditMode && !isModalReadOnly && "Add New Member"}
        </BoldText>
        <div className="profileImg">
          <img src={ImagePreview || Logo} alt="" />
          <label htmlFor="profileImage">
            <span className="material-icons sp">mode_edit</span>
            <input
              type="file"
              style={{ display: "none" }}
              id="profileImage"
              onChange={onChangeFiles}
              disabled={isModalReadOnly}
            />
          </label>
        </div>
        <InputStyle>
          <Input
            width="300px"
            height="28px"
            state={name}
            setState={setname}
            placeholder="Name"
            color="var(--textDarkGray)"
            readOnly={isModalReadOnly}
          />
        </InputStyle>
        <InputStyle>
          <Input
            width="300px"
            height="28px"
            state={email}
            setState={setemail}
            placeholder="Email"
            color="var(--textDarkGray)"
            readOnly={isModalReadOnly}
          />
        </InputStyle>
        <InputStyle>
          <Input
            width="300px"
            height="28px"
            state={password}
            setState={setpassword}
            placeholder="Password"
            color="var(--textDarkGray)"
            readOnly={isModalReadOnly}
            type="password"
          />
        </InputStyle>
        {!isPasswordValid && <ErrorStyle>{PASSWORDERRMSG}</ErrorStyle>}
        <InputStyle>
          <Input
            width="300px"
            height="28px"
            state={phoneNumber}
            setState={setphoneNumber}
            placeholder="Phone Number"
            color="var(--textDarkGray)"
            readOnly={isModalReadOnly}
          />
        </InputStyle>
        {!isPhoneNumberValid && <ErrorStyle>{PHONENUMBERERRMSG}</ErrorStyle>}
        <div className="isAdminPart">
          {txt.grantAdmin}
          <input
            type="checkbox"
            checked={isAdmin}
            onChange={() => {
              if (!isModalReadOnly) {
                setisAdmin(!isAdmin);
              }
            }}
          />
        </div>
        <br />
        <BoldText color="var(--textDarkGray)" fontSize="14px">
          Team Info
        </BoldText>
        <PlainSearchInput
          width="300px"
          height="30px"
          placeholder="Team"
          state={team?.value}
          onClick={() => {
            if (!isModalReadOnly) {
              setisTeamModal(true);
              setisPositionModal(false);
            }
          }}
          noAnimation
        />{" "}
        <PlainSearchInput
          width="300px"
          height="30px"
          placeholder="Position"
          state={position?.name}
          onClick={() => {
            if (!isModalReadOnly) {
              setisTeamModal(false);
              setisPositionModal(true);
            }
          }}
          noAnimation
        />
        <Button
          backgroundColor="var(--eciBlue)"
          color="var(--textDarkGray)"
          width="320px"
          height="50px"
          fontSize="14px"
          onClick={() => {
            if (isModalReadOnly) {
              setisModalReadOnly(false);
              setisModalEditMode(true);
            } else {
              if (isModalEditMode) {
                sendEdit();
              } else {
                sendPost();
              }
            }
          }}
          condition={
            isModalReadOnly
              ? true
              : !!name &&
                !!position &&
                !!email &&
                !!phoneNumber &&
                !!team.value &&
                // regExPassword.test(password) &&
                regExPhoneNumber.test(phoneNumber)
          }
        >
          {isModalReadOnly ? "Edit Information" : "Apply"}
        </Button>
        {isTeamModal && (
          <div className="teamModal">
            <TeamSearchUi
              width="200px"
              height="58vh "
              teamTreeData={teamTreeData}
              state={team}
              setstate={setteam}
            />
          </div>
        )}
        {isPositionModal && (
          <div className="positionModal">
            {CHECKLIST.map((item, index) => {
              return (
                <div className="check" key={index}>
                  {/* <label htmlFor={`cb${index}`}> */}
                  <input
                    type="checkbox"
                    id={`cb${index}`}
                    checked={item.name === position?.name}
                    onChange={(e) => {
                      setposition(item);
                      if (e.target.checked) {
                        setposition(item);
                      } else if (!e.target.checked) {
                        setposition("");
                      }
                    }}
                  />
                  {/* </label> */}
                  <div>{item.name}</div>
                </div>
              );
            })}
          </div>
        )}
      </SignupModalStyle>
    </ContainerStyle>
  );
}
