import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { PropModalStyle, ScrollDefault } from "../../Style";
import Table from "../../molecule/Table";
import TableIndexRow from "../../atom/TableIndexRow";

import productStore from "../../store/productStore";
import PriceRowTree from "../molecule/PriceRowTree";
import SelectBox from "../../atom/SelectBox";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import txt from "../../Text";

const ModalStyle = styled.div`
  width: 80vw;
  .currentTotal {
    height: 110px;
    width: 240px;
    padding: 0 20px;
    background-color: var(--eciBlue);
    border-radius: 10px 10px 0 0;
    color: white;
    font-size: 16px;
    .currencySelect {
      border-bottom: solid white 1px;
      margin-bottom: 12px;
    }
    .ab {
      display: flex;
      align-items: baseline;
      .total {
        font-size: 24px;
        font-weight: 600;

        margin-right: 32px;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .rightBorder {
    border-right: solid 1px;
    padding-right: 8px;
  }
  .buttonPart {
    display: flex;
    justify-content: center;
    height: 50px;
    background-color: white;
    border-radius: 0 0 10px 10px;
    padding-top: 20px;
    border-top: solid var(--textGray);
    transform: translateY(-20px);
    .button {
      cursor: pointer;
      color: var(--textDarkGray);
      font-weight: 600;
      height: 20px;
      padding: 0 20px;
      font-size: 14px;
    }
    .cancel {
      border-right: solid var(--textGray);
    }
  }
`;

export default function PriceModal({ cancelFunc, sr }) {
  const tokenAxios = useTokenAxios();
  const store = productStore();
  const widthArr = [2, 1.5, 3, 5, 6, 6, 4, 3, 6, 3, 5, 4];
  const onSave = async () => {
    const formData = new FormData();
    formData.append("productId", store.id);
    const newList = [];
    Object.keys(store.changedPriceObj).forEach((key) => {
      const tmpObj = { ...store.changedPriceObj[key] };
      tmpObj.price = `${tmpObj.price} ${tmpObj.priceUnit}`;
      tmpObj.additionalPrice = `${tmpObj.additionalPrice} ${tmpObj.additionalPriceUnit}`;
      tmpObj.itemId = tmpObj.id;
      tmpObj.productId = store.id;
      delete tmpObj.priceUnit;
      delete tmpObj.additionalPriceUnit;
      delete tmpObj.id;

      newList.push(JSON.stringify(tmpObj));
    });
    formData.append("list", `"[${newList}]"`);
    try {
      const response = await tokenAxios.put(
        sr ? "sr-price" : "product-price",
        formData
      );
      alert("done!");
      cancelFunc();
    } catch {
      alert("err!");
    }
  };

  const getExhchangeRateObj = async () => {
    const response = await tokenAxios.get("exchange-rate");
    store.initexchangeRateObj(response.data.result.data);
  };
  useEffect(() => {
    getExhchangeRateObj();
    return () => {
      //  init추가
    };
  }, []);
  return (
    <ModalStyle>
      <div className="currentTotal">
        <div className="currencySelect">
          <SelectBox
            width="100px"
            height="11px"
            backgroundColor="var(--eciBlue)"
            cssStyle="color:white;font-size:11px"
            state={store.totalPriceUnitObj[store.totalPriceUnit]}
            setState={store.settotalPriceUnit}
          >
            <option value="₩">KRW</option>
            <option value="¥">JPY</option>
            <option value="$">USD</option>
          </SelectBox>
        </div>
        Current Total of {store.stateObj.name}
        <br />
        <div className="ab">
          <div
            className="total"
            title={
              (store.totalPrice * 1) /
              store.exchangeRateObj[store.totalPriceUnit]
            }
          >
            {(
              (store.totalPrice * 1) /
              store.exchangeRateObj[store.totalPriceUnit]
            ).toFixed(2)}
          </div>
          {store.totalPriceUnitObj[store.totalPriceUnit]}{" "}
        </div>
      </div>
      <Table width="100%" minHeight="500px">
        <TableIndexRow
          widthArray={widthArr}
          itemArray={
            [
              "",
              "Level",
              "",
              "Item Number",
              "Item Name",
              "Specification",
              <span className="rightBorder">{txt.product.supplier}</span>,
              txt.product.priceType,
              txt.product.price,
              txt.product.dealCondition,
              txt.product.additionalCost,
              txt.product.subSum,
            ] // 이제 백에서 인덱스도 받아와
          }
        />
        {Array.isArray(store.priceBomTree) &&
          store.priceBomTree.map((itemObj) => (
            <PriceRowTree
              key={itemObj?.id}
              widthArr={widthArr}
              data={itemObj}
              level={2}
              parentId={store.id}
            />
          ))}
      </Table>
      <div className="buttonPart">
        <div className="button cancel" onClick={cancelFunc}>
          cancel
        </div>
        <div className="button" onClick={onSave}>
          save
        </div>
      </div>
    </ModalStyle>
  );
}
