import create from "zustand";
import { defaultStoreSeed, stateObjSeed } from "./defaultStore";

const boardStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  ...stateObjSeed(set, get),
  stateObj: {
    id: "",
    classificationId: "",
    partNumber: "",
    name: "",
    revision: "",
    specification: "",
    // produceOrganizationId: [{ id: "", name: "" }],
  },
  isModal: false,
  setisModal: (val) => {
    set(() => ({ isModal: val }));
  },
  modalType: "pba",
  pbaRowList: [1],
  setpbaRowList: (val) => {
    set(() => ({ pbaRowList: val }));
  },
  addedPbaRowList: [],
  setaddedPbaRowList: (val) => {
    set(() => ({ addedPbaRowList: val }));
  },
  firmwareRowList: [1],
  setfirmwareRowList: (val) => {
    set(() => ({ firmwareRowList: val }));
  },
  addedFirmwareRowList: [],
  setaddedFirmwareRowList: (val) => {
    set(() => ({ addedFirmwareRowList: val }));
  },
  deletedFirmwareRowList: [],
  setdeletedFirmwareRowList: (val) => {
    set(() => ({ deletedFirmwareRowList: val }));
  },
  deleteFirmware: (valObj) => {
    const { firmwareRowList, addedFirmwareRowList, deletedFirmwareRowList } =
      get();
    const tmpDeletedFirmwareList = [...deletedFirmwareRowList];

    const tmpFirmwareList = firmwareRowList.filter((obj) => {
      if (obj.id === valObj.id) {
        if (
          tmpDeletedFirmwareList.findIndex(
            (delObj) => delObj.id === valObj.id
          ) < 0
        ) {
          tmpDeletedFirmwareList.push(valObj);
        }
      }
      return obj.id !== valObj.id;
    });
    if (!tmpFirmwareList[0]) {
      tmpFirmwareList.push(1);
    }
    set(() => ({
      deletedFirmwareRowList: tmpDeletedFirmwareList,
      firmwareRowList: tmpFirmwareList,
      addedFirmwareRowList: addedFirmwareRowList.filter(
        (obj) => obj.id !== valObj.id
      ),
    }));
  },
  modalItemList: [],
  setmodalItemList: (val) => {
    set(() => ({ modalItemList: val }));
  },
  totalPage: "",
  settotalPage: (val) => {
    set(() => ({ totalPage: val }));
  },
  searchText: "",
  setsearchText: (val) => {
    set(() => ({ searchText: val }));
  },
  pageNum: 0,
  setpageNum: (val) => {
    set(() => ({ pageNum: val }));
  },
  rowsPerPage: 6,
  setrowsPerPage: (val) => {
    set(() => ({ rowsPerPage: val }));
  },
  modalTempCheckedList: [], // 추가로 그 아이템 정보

  modalCheckedItemList: [],

  // attachment
  attachmentTagOptionList: [{ id: 1, name: "ETC" }],
  setattachmentTagOptionList: (val) => {
    set(() => ({ attachmentTagOptionList: val }));
  },

  getisConditionFullfill: () => {
    const { stateObj, isAttachmentValid } = get();

    return !!stateObj.name && !!stateObj.partNumber && isAttachmentValid;
  },

  //    ACTION
  openModal: (type) => {
    set(() => ({ isModal: true, modalType: type }));
  },
  onCheckModalItem: (val) => {
    const arr = get().modalTempCheckedList;
    const type = get().modalType;
    const tmpArr = [...arr];
    const targetIndex = tmpArr.findIndex((itemObj) => itemObj.id === val.id);
    if (targetIndex > -1) {
      tmpArr.splice(targetIndex, 1);
    } else {
      tmpArr.push(val);
    }

    set(() => ({ modalTempCheckedList: type === "pba" ? [val] : tmpArr }));
  },
  cancelModal: () => {
    set(() => ({
      isModal: false,
      modalTempCheckedList: [],
      modalCheckedItemList: [],
    }));
  },
  applyModalLv2: () => {
    const tmpArr = [...get().modalTempCheckedList];
    set(() => ({ modalCheckedItemList: tmpArr }));
  },
  applyModalLv3: () => {
    const { modalType, modalCheckedItemList, firmwareRowList } = get();
    if (modalType === "pba") {
      const tmpArr = [...modalCheckedItemList];
      set(() => ({ pbaRowList: tmpArr, addedPbaRowList: tmpArr }));
    }
    if (modalType === "firmware") {
      const tmpSet = new Set([...firmwareRowList, ...modalCheckedItemList]);
      const tmpArr = [...tmpSet];
      if (tmpArr.findIndex((item) => item === 1) > -1) {
        tmpArr.splice(
          tmpArr.findIndex((item) => item === 1),
          1
        );
      }
      const tmpAddedArr = [...modalCheckedItemList];

      set(() => ({
        firmwareRowList: tmpArr,
        addedFirmwareRowList: tmpAddedArr,
      }));
    }
    set(() => ({
      isModal: false,
      modalTempCheckedList: [],
      modalCheckedItemList: [],
    }));
  },
  onDeleteModalAddedItems: (id) => {
    set(() => ({
      modalTempCheckedList: get().modalTempCheckedList.filter(
        (itemObj) => itemObj.id !== id
      ),
      modalCheckedItemList: get().modalCheckedItemList.filter(
        (itemObj) => itemObj.id !== id
      ),
    }));
  },
  initModuleStore: () => {
    set(() => ({
      stateObj: {
        id: "",
        classificationId: "",
        partNumber: "",
        name: "",
        revision: "",
        // produceOrganizationId: [{ id: "", name: "" }],
      },
      modalType: "pba",
      pbaRowList: [1],
      addedPbaRowList: [],
      firmwareRowList: [1],
      addedFirmwareRowList: [],
      deletedFirmwareRowList: [],

      modalItemList: [],
    }));
  },
}));
export default boardStore;
