import React, { useState } from "react";
import styled from "styled-components";
import useTokenAxios from "../utility/hooks/useTokenAxios";
import { userStore } from "../store/commonStore";

const PopupBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background: #f9f9f9;
  padding: 30px;
  border-radius: 12px;
  width: 400px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

const Button = styled.button`
  background: var(--eciBlue);
  color: white;
  border: none;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
  font-size: 16px;

  &:hover {
    background: #0056b3;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 10px;
`;

const SuccessMessage = styled.div`
  color: green;
  margin-top: 10px;
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function PasswordChangePopup({ onClose }) {
  /// ---CONSt
  const tokenAxios = useTokenAxios();
  const { userData } = userStore();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return "비밀번호는 최소 8자 이상이어야 합니다.";
    }
    if (!hasUpperCase) {
      return "비밀번호에는 대문자가 최소 한 개 이상 포함되어야 합니다.";
    }
    if (!hasLowerCase) {
      return "비밀번호에는 소문자가 최소 한 개 이상 포함되어야 합니다.";
    }
    if (!hasNumber) {
      return "비밀번호에는 숫자가 최소 한 개 이상 포함되어야 합니다.";
    }
    if (!hasSpecialChar) {
      return "비밀번호에는 특수 문자가 최소 한 개 이상 포함되어야 합니다.";
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const passwordError = validatePassword(newPassword);
    if (passwordError) {
      setError(passwordError);
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("신규 비밀번호와 확인 비밀번호가 같지 않습니다.");
      return;
    }

    try {
      await tokenAxios.put(
        `/members/${userData.id}/change-password`,
        {
          currentPassword,
          newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSuccess("비밀번호가 성공적으로 변경되었습니다.");
      setError(""); // 에러 메시지 초기화
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        setError(`${err.response.data.message}`);
      } else {
        setError("비밀번호 재설정에 실패했습니다. 서버 에러가 발생했습니다.");
      }
      setSuccess(""); // 성공 메시지 초기화
    }
  };

  return (
    <PopupBackground>
      <PopupContainer>
        <Title>Change Password</Title>
        <Form onSubmit={handleSubmit}>
          <Input
            type="password"
            placeholder="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <Button type="submit">Change Password</Button>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {success && <SuccessMessage>{success}</SuccessMessage>}
        </Form>
        <Button onClick={onClose}>Cancel</Button>
      </PopupContainer>
    </PopupBackground>
  );
}

export default PasswordChangePopup;
