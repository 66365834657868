import React, { useEffect, useState } from "react";
import axios from "axios";
import commonStore from "../../store/commonStore";
import txt from "../../Text";

export default function useTokenAxios() {
  const { addLoadingRequestCnt, setloadingRequestCnt } = commonStore();
  let receiveFlag;
  let isAdded = false;
  const tokenAxios2 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  tokenAxios2.interceptors.request.use(
    async (config) => {
      const configure = config;
      const auth = localStorage.getItem("token");
      configure.headers = {
        Authorization: auth,
        Accept: "application/json",
        "Content-Type":
          configure.headers["Content-Type"] || "multipart/form-data", // 동적으로 Content-Type 설정
      };
      receiveFlag = false;
      setTimeout(() => {
        if (!receiveFlag) {
          // response 없으면
          addLoadingRequestCnt(1);
          isAdded = true;
        }
      }, 2000);
      return configure;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  tokenAxios2.interceptors.response.use(
    (response) => {
      receiveFlag = true;

      if (isAdded) {
        addLoadingRequestCnt(-1);
      }

      return response;
    },
    (error) => {
      const originalRequest = error.config;
      originalRequest._retry = true;
      receiveFlag = true;
      setloadingRequestCnt(0);
      if (
        error.response.status > 499 ||
        (error.response.status <= 499 && !error.response.data.result)
      ) {
        if (error.config.method === "post" || error.config.method === "put") {
          // window.location.href="/notFound"
          throw error; // 추가됨
        }
      }
      if (error && error.response.data.result) {
        if (error.response.data.result.msg === txt.needRefresh) {
          fetch(`${process.env.REACT_APP_BASE_URL}/refresh-token`, {
            credentials: "include",
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: {},
          })
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(response.status);
              }
              return response.json();
            })
            .catch(() => {
              // setIsLogged(false);
              const userId = JSON.parse(localStorage.getItem("undefined")).state
                .userData.id;
              fetch(`${process.env.REACT_APP_BASE_URL}/logout/${userId}`, {
                // credentials: "include",
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded",
                },
                // body: {},
              });

              alert(txt.tokenExpired);
              localStorage.removeItem("token");
              localStorage.removeItem("alarmArr");

              window.location.reload(); // 추가됨
            })
            .then((response) => {
              let aToken = JSON.stringify(response.result.data.accessToken);
              aToken = aToken.replaceAll(`"`, ``);
              localStorage.setItem("token", aToken);
              const rauth = localStorage.getItem("token");
              axios.defaults.headers.common.Authorization = rauth;
              originalRequest.headers.Authorization = rauth;
              if (error.config.method === "get") {
                window.location.reload(); // 추가됨
              } else {
                return axios(originalRequest);
              }

              return axios(originalRequest);
            });
        } else {
          console.log(error.response);
          alert(error.response.data.result.msg);
          throw error;
        }
      }

      throw new Error(error);
    }
  );
  return tokenAxios2;
}
