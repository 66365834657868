import create from "zustand";
import { persist } from "zustand/middleware";

const commonStore = create((set, get) => ({
  isLogged: !!localStorage.getItem("token"),
  setIsLogged: (bool) => {
    set(() => ({ isLogged: bool }));
  },
  setUserToken: (token) => {
    localStorage.setItem("token", token.accessToken);
    set(() => ({ isLogged: true }));
  },
  loadingBoxType: "SPINNER", // ARROW, RETRY
  setloadingBoxType: (val) => {
    set(() => ({ loadingBoxType: val }));
  },
  loadingClickEvent: () => {},
  setloadingClickEvent: (func) => {
    set(() => ({ loadingClickEvent: func }));
  },
  loadingOnExit: () => {},
  setloadingOnExit: (func) => {
    set(() => ({ loadingOnExit: func }));
  },
  loadingRequestCnt: 0, // loading 카운트는 로딩이 필요한 axios요청(2초가 경과한 요청)의 개수를 말한다. 카운트가 0보다 크면 로딩 지속
  addLoadingRequestCnt: (val) => {
    set((state) => ({
      loadingRequestCnt:
        state.loadingRequestCnt + val >= 0
          ? state.loadingRequestCnt + val
          : state.loadingRequestCnt,
    }));
  },
  setloadingRequestCnt: (val) => {
    set(() => ({ loadingRequestCnt: val }));
  },
  isLoading: false,
  setisLoading: (bool) => {
    set(() => ({ isLoading: bool }));
  },
}));
export default commonStore;
export const userStore = create(
  persist((set) => ({
    userData: "",
    setUserData: (data) => {
      set(() => ({ userData: data }));
    },
  }))
);
