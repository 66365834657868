import React from "react";
import styled from "styled-components";
import { LightGrayInputContainer, ScrollDefault } from "../../Style";
import BoldText from "../../atom/BoldText";
import DeleteButton from "../../atom/DeleteButton";

const HistoryPopupStyle = styled.div`
  width: 400px;
  padding: 16px 0;
  max-height: 600px;
  ${ScrollDefault}
  overflow-x: hidden;
  border-radius: 8px;

  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(
    ${(props) => props.left - 420}px,
    ${(props) => props.top + 12}px
  );
  .title {
    padding: 0 16px;
  }
  .line {
    border-bottom: solid var(--textDarkGray) 1px;
    width: 100%;
  }
`;
const HistoryRowStyle = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.backgroundColor};
  .gap {
    width: 60px;
  }
`;
const LightGrayBox = styled.div`
  margin-left: 8px;
  border-radius: 10px;
  height: 30px;
  line-height: 30px;
  width: 100%;
  background-color: var(--lightGray);
  padding-left: 4px;
`;
export default function HistoryPopup({
  historyList = [],
  onDelete,
  top,
  left,
  setisActive,
  isLock,
}) {
  const isRowLock = (i) => {
    return i % 2 === (isLock ? 0 : 1);
  };
  return (
    <HistoryPopupStyle
      top={top}
      left={left}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="title">
        <BoldText fontSize="14px" color="var(--textDarkGray)">
          Lock History
        </BoldText>
      </div>

      <div className="line" />

      {historyList.map((itemObj, i) => (
        <HistoryRowStyle
          key={i}
          backgroundColor={isRowLock(i) ? "var(--textGray)" : ""}
        >
          <span className="material-icons">
            {isRowLock(i) ? "lock" : "lock_open"}
          </span>
          {itemObj.lockTime}
          <LightGrayBox>{itemObj.lockReason}</LightGrayBox>
          <div className="gap" />
        </HistoryRowStyle>
      ))}
    </HistoryPopupStyle>
  );
}
