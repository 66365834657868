import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import dashboardStore from "../../store/dashboardStore";
import { PageStyle } from "../../Style";

import TodoSection from "../organism/TodoSection";
import ListSection from "../organism/ListSection";
import useTokenAxios from "../../utility/hooks/useTokenAxios";

const DashboardStyle = styled(PageStyle)`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
export default function DashboardPage() {
  const store = dashboardStore();
  const navigate = useNavigate();
  const tokenAxios = useTokenAxios();
  const getTodoData = async () => {
    const response = await tokenAxios.get("todo");
    store.settodoDataObj(response.data.result.data);
  };
  useEffect(() => {
    getTodoData();
  }, []);

  return (
    <DashboardStyle currentModuleIndex={store.currentModuleIndex}>
      <TodoSection />
      <ListSection />
    </DashboardStyle>
  );
}
