import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";

import SelectBox from "../../atom/SelectBox";
import { GrayButton } from "../../Style";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import txt from "../../Text";
import SelectBoxForClassification from "../../atom/SelectBoxForClassification";

const ClassificationPropRowStyle = styled.div`
  width: 100%;
  border-top: solid 1px var(--textGray);
  padding: 0 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(
    ${(props) => props.height} +
      ${(props) => (props.thick ? props.height : "0px")}
  );
  /* min-width: calc(${(props) => props.minWidth}); */

  .propList {
    display: flex;
    /* min-width: calc(${(props) => props.minWidth}); */
  }
  .Nothing {
    width: 100px;
    border: solid red;
  }
`;
const SelectBoxContainerStyle = styled.div`
  margin-right: 20px;
`;
export default function ClassificationPropRow({
  id,
  parentId,
  buttonType,
  propsArray, // 전체 속성 array
  readOnly,
  propIndex,
  height = "50px",
  minWidth = "400px",
  name,
  titleCode,
  depth,
  classId,
  propertyArr, // 현재 분류가 가지고 있는 속성만 포함한 배열
  setisWriting,
  treeData,
  changeRightArray,
  changeTreeData,
  thick = false,
}) {
  const tokenAxios = useTokenAxios();
  const [propertyIdArr, setpropertyIdArr] = useState(
    propIndex && propIndex.map((item) => item.id)
  );

  const [optionIdArray, setoptionIdArray] = useState(
    propIndex?.map((item) => {
      const targetI = propertyArr.findIndex(
        (propObj) => propObj.id === item.id
      );

      if (targetI > -1) {
        return propertyArr[targetI].option;
      }
      return -1;
    }) || []
  );
  const afterButtonClick = () => {
    setisWriting && setisWriting(false);
    changeRightArray(treeData.treeId, "buttonType", "");
    // changeRightArray(treeData.treeId, "readOnly", true);
    changeRightArray(treeData.treeId, "thick", false);
    // 여기에 추가로 itemClassificationTree의 isInput, isTwoInput을 처리할 방법이 필요
  };
  //  post
  const post = async () => {
    const formData = new FormData();
    formData.append("parentId", parentId);
    formData.append("name", name);
    formData.append("stage", depth);
    formData.append("options", optionIdArray);
    formData.append("propertyIds", propertyIdArr);

    try {
      const response = await tokenAxios.post("item-classification", formData);
      const { data } = response.data.result;
      alert("done!");
      // window.location.reload();

      // changeTreeData(treeData.treeId, (curArr, lastId) => {
      //   const targetObj = curArr[lastId];
      //   const newObj = { ...targetObj };
      //   newObj.name = name;
      //   newObj.classification = `${data.id1}/${data.id2}/${data.id3}/${data.id4}/${data.id5}`;
      //   newObj.classificationId = data.id;
      //   newObj.value += `/${name}`;
      //   console.log(targetObj, curArr);
      //   targetObj?.children.push(newObj);
      // });
      afterButtonClick();
    } catch (error) {
      alert("error");
      window.location.reload();
    }

    //  리렌더 & 스크롤 유지
  };
  //  put
  const editTitle = async () => {
    if (!name) {
      alert(txt.admin.enterName);
      return;
    }
    if (thick && !titleCode) {
      alert(txt.admin.enterCode);
      return;
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("code", titleCode);
    formData.append("stage", depth);
    const codeFormData = new FormData();
    codeFormData.append("code", titleCode);
    codeFormData.append("stage", depth);
    try {
      const response = await tokenAxios.put(
        `item-classification/${id}`,
        formData
      );
      if (thick) {
        console.log(titleCode, thick);

        const codeResponse = await tokenAxios.put(`code/${id}`, codeFormData);
      }
      afterButtonClick();
    } catch (error) {
      alert("error");
      // window.location.reload();
    }
  };
  const put = async (newOptionIdArr) => {
    const formData = new FormData();
    formData.append("parentId", parentId);
    // formData.append("name", name);
    formData.append("stage", depth);
    formData.append("options", newOptionIdArr);
    formData.append("propertyIds", propertyIdArr);

    try {
      const response = await tokenAxios.put(
        `classification/${classId}`,
        formData
      );
      afterButtonClick();
    } catch (error) {
      alert("error");
      window.location.reload();
    }
  };

  const setTwoArrayState = (i) => {
    const func = async (val) => {
      if (!readOnly) {
        const copyArr = [...optionIdArray];
        copyArr[i] = val;
        await put(copyArr);

        const prevPropertyArr = treeData.property;
        let newPropertyArr = [];
        const targetPropId = propertyIdArr[i];
        if (val === "-1") {
          // 삭제
          newPropertyArr.splice((i, 1));
        } else if (
          // 추가
          prevPropertyArr.findIndex((obj) => obj.id === propertyIdArr[i]) < 0
        ) {
          const addingObj =
            propIndex[propIndex.findIndex((obj) => obj.id === targetPropId)];
          addingObj.option = val;
          newPropertyArr = [...prevPropertyArr, addingObj];
        } else {
          newPropertyArr = prevPropertyArr.map((obj) => {
            if (obj.id === propertyIdArr[i]) {
              obj.option = val;
            }
            return obj;
          });
        }

        changeRightArray(treeData.treeId, "property", newPropertyArr);
        changeTreeData(treeData.treeId, (curArr, lastId) => {
          const targetObj = curArr[lastId];
          targetObj.property = newPropertyArr;
        });

        setoptionIdArray((arr) => {
          const tempArr = [...arr];
          tempArr[i] = val;
          return tempArr;
        });
      }
    };
    return func;
  };
  //    selectBoxlist
  const selectBoxList =
    propIndex &&
    propIndex.map((propObj, i) => {
      const propTarget =
        propsArray && propsArray.find((obj) => obj.name === propObj.name);
      return (
        <SelectBoxContainerStyle key={i}>
          <SelectBoxForClassification
            fontWeight="600"
            color="var(--eciBlue)"
            width="100px"
            height={`${height} - 16px`}
            fontSize="13px"
            value={optionIdArray[i]}
            setState={setTwoArrayState(i)}
            backgroundColor={
              optionIdArray[i] * 1 === -1
                ? "var(--pink)"
                : optionIdArray[i] * 1 === 0
                ? "var(--skyBlue)"
                : "var(--todoGreen)"
            }
          >
            <option value="-1">no</option>
            <option value="0">required</option>
            <option value="1">option</option>
          </SelectBoxForClassification>
        </SelectBoxContainerStyle>

        // <PropSelectBox
        //   key={(propTarget && propTarget.treeId) || i}
        //   height={height}
        //   readOnly={readOnly}
        //   initialValue={propTarget ? propTarget.option : 0}
        //   name={propObj.name}
        //   classId={classId}
        // />
      );
    });

  useEffect(() => {
    setoptionIdArray(
      propIndex?.map((item) => {
        const targetI = propertyArr.findIndex(
          (propObj) => propObj.id === item.id
        );

        if (targetI > -1) {
          return propertyArr[targetI].option;
        }
        return -1;
      }) || []
    );
  }, []);
  return (
    <ClassificationPropRowStyle
      height={height}
      minWidth={minWidth}
      thick={thick}
      title={treeData.name}
    >
      <div className="propList">{selectBoxList}</div>
      {buttonType && (
        <GrayButton
          height={height}
          onClick={
            buttonType === "post"
              ? post
              : buttonType === "title"
              ? editTitle
              : put
          }
        >
          반영
        </GrayButton>
      )}
    </ClassificationPropRowStyle>
  );
}
