import React, { useEffect, useRef } from "react";
import LineTitle from "../../atom/LineTitle";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import AnimationInput from "../../molecule/AnimationInput";
import AnimationSearchInput from "../../molecule/AnimationSearchInput";
import Table from "../../molecule/Table";
import TextBox from "../../molecule/TextBox";

import { BlueBackGroundBox, PageStyle } from "../../Style";
import DeleteButton from "../../atom/DeleteButton";
import releaseStore from "../../store/releaseStore";
import StatusTag from "../../x-electronicPart/molecule/StatusTag";
import txt from "../../Text";

export default function ReleaseInformationSection({ readOnly }) {
  const store = releaseStore();
  const informationRef = useRef();

  useEffect(() => {
    store.setinformationRef(informationRef);
  }, []);
  return (
    <PageStyle ref={informationRef}>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        Release Information
      </LineTitle>{" "}
      <AnimationInput
        width="50%"
        height="40px"
        placeholder="Release Number"
        state={store.stateObj.releaseNumber}
        setState={store.setStateByKey("releaseNumber")}
        readOnly
        backgroundColor="var(--textGray)"
      />
      <AnimationSearchInput
        width="100%"
        height="40px"
        placeholder={txt.release.releaseProduct}
        state=""
        required
        onClick={() => {
          store.setisModal(true);
        }}
        backgroundColor="var(--eciBlue)"
        readOnly={readOnly}
      />
      <Table minHeight="120px">
        <TableIndexRow widthArray={[4, 5]} itemArray={txt.release.infoIndex} />
        {store.stateObj.product.map((productObj, i) => (
          <TableRow
            key={i}
            widthArray={[4, 4, 1]}
            itemArray={[
              productObj.number,
              <StatusTag status={productObj.status} />,
              !readOnly && (
                <DeleteButton
                  height="20px"
                  fontSize="px"
                  width="5px"
                  onDelete={() => store.deleteproduct(productObj)}
                />
              ),
            ]}
          />
        ))}
      </Table>
      <br /> <br />
      <BlueBackGroundBox>
        <AnimationInput
          width="50%"
          height="40px"
          placeholder={txt.release.infoTitle}
          state={store.stateObj.releaseTitle}
          setState={store.setStateByKey("releaseTitle")}
          required
          readOnly={readOnly}
        />
        <TextBox
          width="100%"
          height="100px"
          placeholder={txt.release.infoContent}
          required
          state={store.stateObj.releaseContent}
          setState={store.setStateByKey("releaseContent")}
          readOnly={readOnly}
        />
        <br />
      </BlueBackGroundBox>
      <br />
      <br />
    </PageStyle>
  );
}
