import React, { useState } from "react";
import styled from "styled-components";
import RoundImgBox from "../atom/RoundImgBox";
import commonStore, { userStore } from "../store/commonStore";
import logo1 from "../static/logo1.svg";
import { getImgSource } from "../utility/Utility";
import PasswordChangePopup from "../page/PasswordChangePopup";

const NavbarUserBoxStyle = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 8px;

  position: absolute;
  bottom: 48px;
  display: flex;
  color: white;
  overflow: hidden;
  cursor: pointer; /* 클릭 가능한 커서 스타일 추가 */

  .dataBox {
    width: 80%;
    font-family: "Noto Sans KR", sans-serif;
    padding-left: 14px;
    font-weight: 100;

    .name {
      font-size: 14px;
      font-weight: 600;
    }
    .department {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export default function NavBarUserBox({ isHover }) {
  const { userData } = userStore();
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <>
      <NavbarUserBoxStyle onClick={() => setIsPopupOpen(true)}>
        <RoundImgBox
          src={
            userData.profileImage ? getImgSource(userData.profileImage) : logo1
          }
        />
        {isHover ? (
          <div className="dataBox">
            <div className="name"> {userData.username}</div>

            <div className="department" title={userData.department}>
              {" "}
              {userData.department}
            </div>
          </div>
        ) : (
          ""
        )}
      </NavbarUserBoxStyle>
      {isPopupOpen && (
        <PasswordChangePopup onClose={() => setIsPopupOpen(false)} />
      )}
    </>
  );
}
