import React, { useState } from "react";
import styled from "styled-components";

import Input from "../../atom/Input";
import SelectBox from "../../atom/SelectBox";
import { GrayButton } from "../../Style";
import useTokenAxios from "../../utility/hooks/useTokenAxios";

const PropManageRowStyle = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: solid 1px var(--textGray);
  background-color: ${(props) => props.isCurrent && "var(--backgroundYellow)"};
  cursor: pointer;
  position: relative;
  .blue {
    position: absolute;
    width: 6px;
    height: 48px;
    background-color: var(--eciBlue);
    border-radius: 5px;
  }
  .name {
    width: 30%;
    min-width: 100px;
    color: var(--eciBlue);
    margin-left: 16px;
    font-weight: 600;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;
  }
  .inputContainer {
    width: 30%;
    min-width: 100px;
    margin-left: 16px;
    margin-right: 20px;

    height: 70%;
    background-color: var(--lightGray);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .spin {
    position: absolute;
    right: 0;
    transform: rotate(-90deg);
    font-size: 14px;
    color: var(--textDarkGray);
  }
  .buttonContainer {
    position: absolute;
    right: 0;
  }
  .gap {
    width: 40px;
  }
`;
export default function NewPropRow() {
  const tokenAxios = useTokenAxios();
  const [newNameState, setnewNameState] = useState("");
  const [newTypeState, setnewTypeState] = useState("new");
  const post = async () => {
    const formData = new FormData();
    formData.append("name", newNameState);
    formData.append("type", newTypeState);

    try {
      tokenAxios.post(`property`, formData);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  const typeColorObject = {
    DROP_DOWN: "var(--pink)",
    KEY_IN: "var(--skyBlue)",
    UNIT_KEY_IN: "var(--skyBlue)",
    DB: "var(--todoGreen)",
    RANGE_KEY_IN: "rgba(253, 191, 77, 0.2)",
    new: "#D9D9D9",
  };
  return (
    <PropManageRowStyle>
      <div className="inputContainer">
        <Input
          state={newNameState}
          setState={setnewNameState}
          placeholder="type here"
          fontSize="12px"
        />
      </div>
      <SelectBox
        fontWeight="600"
        color="var(--eciBlue)"
        width="100px"
        height="32px"
        fontSize="13px"
        readOnly={false}
        value={newTypeState}
        setState={setnewTypeState}
        backgroundColor={typeColorObject[newTypeState]}
      >
        <option value="DB">Table</option>
        <option value="KEY_IN">key-in</option>
        <option value="RANGE_KEY_IN">범위Key-in</option>
        <option value="DROP_DOWN">Drop Down</option>
        <option value="UNIT_KEY_IN">단위</option>
      </SelectBox>

      <div className="buttonContainer">
        <div className="gap" />
        <GrayButton
          height="40px"
          width="50px"
          onClick={(e) => {
            e.stopPropagation();

            post();
          }}
        >
          반영
        </GrayButton>
      </div>
    </PropManageRowStyle>
  );
}
