import React from "react";
import { Outlet } from "react-router-dom";
import ModuleTopNavigation from "../molecule/ModuleTopNavigation";
import { ModuleStyle } from "../Style";

export default function ReleaseModule() {
  return (
    <ModuleStyle>
      <ModuleTopNavigation
        title="Release"
        navList={[
          {
            name: "List",
            to: "/release",
          },
          {
            name: "Add New Release",
            to: "/release/page/add/new",
          },
        ]}
      />
      <Outlet />
    </ModuleStyle>
  );
}
