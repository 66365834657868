import commonStore from "../../store/commonStore";
import useTokenAxios from "./useTokenAxios";

export default function useTempSaveFunction(
  url,
  appendFormData,
  modulestore,
  isEdit,
  editId,
  callBack
) {
  const tokenAxios = useTokenAxios();

  // url, formData추가 함수(appendFormData), store객체, temp여부
  //  edit여부는 tempid의 존재로 확인
  //  save명령을 보내는 save함수를 반환
  const { setisLoading, setloadingBoxType } = commonStore();
  const { setisRouteActive, setrouteNumber } = modulestore;
  //  각 store에 해당 속성들 꼭 있도록 확인
  const save = async () => {
    setloadingBoxType("ARROW");
    setisLoading(true);
    try {
      if (editId && isEdit && editId !== "new") {
        const formData = appendFormData(true, modulestore);
        // appendAttachmentFormData(formData, true);

        const response = await tokenAxios.put(
          `/${url}-temp/${editId}`,
          formData
        );
        setrouteNumber(response.data.result.data.routeId || "");
      } else {
        const formData = appendFormData(false, modulestore);
        // appendAttachmentFormData(formData, false);

        const response = await tokenAxios.post(`/${url}-temp`, formData);
        //  여기서 url을 editD로 옮겨줘야해
        setrouteNumber(
          response.data.result.data.routeId
            ? response.data.result.data.routeId
            : ""
        );
        if (!isEdit) {
          //  temp이후 uri를 수정하기 위한 콜백.
          const location = window.location.href;
          window.location.href = location
            .replace("add", "editD")
            .replace("new", response.data.result.data.id);
        }
      }
      alert("Done!");

      setisLoading(false);
      setloadingBoxType("SPINNER");
    } catch (err) {
      setisRouteActive(false);
    }
  };
  return save;
}
