import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from "../atom/Button";
import LineTitle from "../atom/LineTitle";
import RouteCommentRow from "../molecule/RouteCommentRow";
import { userStore } from "../store/commonStore";
import { BlueButtonStyle } from "../Style";
import useTokenAxios from "../utility/hooks/useTokenAxios";
import txt from "../Text";

const RouteInitSectionStyle = styled.div`
  position: relative;
  display: ${(props) => (props.activate ? "" : "none")};
`;
const RouteContainerStyle = styled.div`
  width: 100%;
  min-height: 800px;
  margin-top: 20px;
`;

export default function RouteInitSection({
  routeType, // 타입에 따라라우트 경로를 다르게 받기
  itemId,
  typeId,
  activate,
  moduleStore,
  afterUrl,
}) {
  /// ---CONSt
  const tokenAxios = useTokenAxios();
  const navigate = useNavigate();
  /// ---MODEL
  const {
    setrouteRef,
    openMemberSearchBox,
    targetMember,
    settargetMember,
    members,
  } = moduleStore;
  const { userData } = userStore();
  const [newComment, setnewComment] = useState("");
  const routeRef = useRef();
  const [routeData, setrouteData] = useState([]);

  /// ---VIEW
  const routerList =
    routeData &&
    routeData.map((router, i) => {
      if (i === 0) {
        return (
          <RouteCommentRow
            init
            key={i}
            title={router}
            member={[
              {
                username: userData.username,
                department: userData.department,
                contact: userData.contact,
                email: userData.email,
              },
            ]}
            present={i === 0}
            inputState={newComment}
            setinputState={setnewComment}
            upperLine={i > 0}
          />
        );
      }

      return (
        <RouteCommentRow
          init
          key={i}
          title={router}
          member={members[i]}
          present={i === 0}
          inputState={newComment}
          setinputState={setnewComment}
          upperLine={i > 0}
          openMember={() => {
            settargetMember(i);
            openMemberSearchBox();
          }}
          highLight={i === targetMember}
          complete={router === "CO Execute"} // route가 필요 없는 겨웅 하드코딩
        />
      );
    });
  /// ---ACTION
  const createRoute = async () => {
    const formData = new FormData();
    formData.append("itemId", itemId);
    formData.append("requestComment", newComment);
    members?.forEach((item, i) => {
      // 첫 번째는 본인
      const eachIdList = Array.isArray(item) ? item?.map((it) => it.id) : [];

      if (i > 0) {
        if (members.length === 2) {
          formData.append(`memberIds[${0}]`, eachIdList);
        } else {
          formData.append(`memberIds[${i - 1}]`, eachIdList);
        }
      }
    });
    tokenAxios
      .post(`/route/${routeType}`, formData)
      .then((response) => {
        alert("Done !");

        if (afterUrl) {
          navigate(afterUrl);
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        // 에러 응답이 있고, 상태 코드가 401인 경우
        if (error.response && error.response.status === 401) {
          // error.response.data.message를 출력하거나, 해당 메시지가 없을 경우 기본 메시지 출력
          alert(
            `${
              error.response.data.message ||
              "Item Purchase Related Properties 단계에는 구매담당자만 배정할 수 있습니다."
            }`
          );
        } else {
          // 다른 모든 경우에 대한 에러 메시지
          alert(`Error: An unknown error occurred`);
        }
      });
  };
  /// --- BL

  const getRouteByClassification = async () => {
    // 아이템 분류별로 초기화할 라우트 목록을 받아온다.
    if (activate) {
      const response = await tokenAxios.get(
        `routeBy${routeType}${typeId ? `/${typeId}` : ""}`
      );
      setrouteData(response.data.result.data);
    }
  };

  useEffect(() => {
    setrouteRef(routeRef);
    if (routeType) {
      getRouteByClassification();
    }
  }, [routeType, typeId]);
  return (
    <RouteInitSectionStyle ref={routeRef} activate={activate}>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        Route Information
      </LineTitle>
      <BlueButtonStyle
        onClick={() => {
          settargetMember("ALL", routeData.length);
          openMemberSearchBox("members");
        }}
        height="36px"
      >
        {txt.batchMember} &gt;
      </BlueButtonStyle>
      <br />
      <RouteContainerStyle>
        {routerList}
        <RouteCommentRow
          title="Complete"
          upperLine
          isCommentBox={false}
          complete
          member={[]}
        />
        <Button
          backgroundColor="var(--eciBlue)"
          width="100%"
          height="30px"
          color="white"
          onClick={createRoute}
          condition={
            members?.length >= routeData.length || routeData.length < 2
          } // 멤버 가득차면
        >
          Save and Route
        </Button>
      </RouteContainerStyle>
    </RouteInitSectionStyle>
  );
}
