import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { PageStyle } from "../Style";

const BlankPageStyle = styled(PageStyle)`
  width: 90%;
`;
export default function BlankModule() {
  return (
    <BlankPageStyle>
      <Outlet />
    </BlankPageStyle>
  );
}
