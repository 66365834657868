import React, { useEffect } from "react";

import styled from "styled-components";
import AnimationInput from "./AnimationInput";
import UnitInput from "./UnitInput";

const SizeFixedInputStyle = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: flex;
  pointer-events: ${(props) => (props.readOnly ? "none" : "")};
  align-items: center;
  justify-content: space-between;

  .title {
    min-width: 120px;
    color: var(--eciBlue);
    max-width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    transform: translate(0, -10px);
  }
  .x {
    padding-left: 5%;
    color: var(--textDarkGray);
    width: 20%;
    font-weight: 800;
    font-size: 18px;
    /* transform: translate(0, -10px); */
  }
`;
// const UnitInputStyle = styled.div`
//   display: flex;
//   justify-content: space-between;
//   .sel {
//     color: var(--textDarkGray);
//     width: 20%;
//     font-weight: 600;
//     font-size: 15px;
//     transform: translate(0, +10px);
//   }
// `;
export default function SizeFixedInput({
  width,
  height,
  title,
  placeholder1,
  placeholder2,
  unit1 = "?",
  unit2 = "?",
  state,
  setState,
  state2,
  setState2,
  readOnly,
  required,
  requiredError,
  centerStr = "x",
}) {
  //  state : "1c ~ 5c" || undefined || 하나만 들어와
  // const state1 = state && state.split(` ${centerStr} `)[0].replace(unit1, "");
  // const state2 = ((state && state.split(` ${centerStr} `)[1]) || "").replace(
  //   unit2,
  //   ""
  // );
  // const setstate1 = (val) => {
  //   setState(
  //     `${val.replace(
  //       ` ${centerStr} `,
  //       ""
  //     )}${unit1} ${centerStr} ${state2}${unit2}`
  //   );
  // };
  // const setstate2 = (val) => {
  //   setState(
  //     `${state1}${unit1} ${centerStr} ${val.replace(
  //       ` ${centerStr} `,
  //       ""
  //     )}${unit2}`
  //   );
  // };
  // useEffect(() => {
  //   if (!state) {
  //     setState(`${unit1} ${centerStr} ${unit2}`);
  //   }
  // }, []);
  return (
    <SizeFixedInputStyle width={width} height={height}>
      <div className="title">{title}</div>
      <UnitInput
        readOnly={readOnly}
        width="70%"
        height={height}
        placeholder={placeholder1}
        state={state}
        setState={setState}
        backgroundColor="white"
        required={required}
        optionArray={[{ value: unit1, name: unit1 }]}
      />
      {/* <UnitInputStyle>
        <AnimationInput
          width="70%"
          height={height}
          placeholder={placeholder1}
          backgroundColor="white"
          state={state1}
          setState={setstate1}
          readOnly={readOnly}
          required={required}
          requiredError={requiredError}
        />
        <div className="sel">{unit1}</div>
      </UnitInputStyle> */}
      <div className="x"> {centerStr}</div>

      {/* <UnitInputStyle>
        <AnimationInput
          width="70%"
          height={height}
          placeholder={placeholder2}
          backgroundColor="white"
          state={state2}
          setState={setstate2}
          readOnly={readOnly}
          required={required}
          requiredError={requiredError}
        />
        <div className="sel">{unit2}</div>
      </UnitInputStyle> */}
      <UnitInput
        readOnly={readOnly}
        width="70%"
        height={height}
        placeholder={placeholder2}
        state={state2}
        setState={setState2}
        backgroundColor="white"
        required={required}
        optionArray={[{ value: unit2, name: unit2 }]}
        dark
      />
    </SizeFixedInputStyle>
  );
}
