import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import HeaderIconList from "../molecule/HeaderIconList";
import SearchBar from "../molecule/SearchBar";
import commonStore from "../store/commonStore";
import OptionModal from "../molecule/OptionModal";
import Language from "../static/Language.svg";

const HeaderStyle = styled.div`
  height: 80px;
  min-width: 1000px;
  width: 100%;
  padding-left: ${(props) => (props.isHover ? "200px" : "160px")};
  padding-bottom: 10px;
  transition: 0.4s;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  background-color: white;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  span {
    cursor: pointer;
    margin: 0 15px;
  }
  .rightContainer {
    display: flex;
    align-items: flex-start;
    .logout {
      color: var(--textDarkGray);
    }
    img {
      height: 24px;
      cursor: pointer;
    }
    .lang {
      position: relative;
      .langModal {
        position: absolute;
        top: 20px;
        left: -100px;
      }
      cursor: pointer;
    }
  }
`;

export default function Header({ isHover }) {
  const navigate = useNavigate();
  const { setIsLogged } = commonStore();
  const [isLangModal, setisLangModal] = useState(false);
  const [lang, setlang] = useState("eng");
  /*eslint-disable */
  const logOut = async () => {
    if (confirm("Log out?") === true) {
      setIsLogged(false);
      // const userId = JSON.parse(localStorage.getItem("undefined")).state
      //   .userData.id;
      // fetch(`${process.env.REACT_APP_BASE_URL}/logout/${userId}`, {
      //   method: "POST",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/x-www-form-urlencoded",
      //   },
      // });
      localStorage.removeItem("token");

      navigate("/");
    }
  };
  return (
    <HeaderStyle isHover={isHover}>
      {/* <SearchBar width="30vw" height="30px" placeholder="Search" /> */}
      <div />
      {/* <HeaderIconList /> */}
      <div className="rightContainer">
        <div
          className="lang"
          onClick={(e) => {
            e.stopPropagation();
            setisLangModal(!isLangModal);
          }}
        >
          <img className="lang" src={Language} alt="" />

          {isLangModal && (
            <div className="langModal">
              <OptionModal
                title="Choose Language"
                uiObject={{ language: { KOR: "kor", ENG: "eng", CHN: "chn" } }}
                setisModal={setisLangModal}
                width="140px"
                setStateList={[
                  (val) => {
                    localStorage.setItem("lang", val);
                    window.location.reload();
                  },
                ]}
              />
            </div>
          )}
        </div>
        <span className="material-icons logout" onClick={logOut}>
          logout
        </span>
      </div>
    </HeaderStyle>
  );
}
