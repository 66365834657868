import React, { useRef, useState } from "react";
import Table from "../../molecule/Table";
import TableIndexRow from "../../atom/TableIndexRow";

import TableRow from "../../atom/TableRow";
import PaginationTable from "../../molecule/PaginationTable";
import SearchBar from "../../molecule/SearchBar";
import ClassificationSelectTree from "../ClassificationSelectTree";
import { ButtonPart, ModalStyle, PropModalStyle } from "./TreeModalStyle";
import {
  useHandleClassificationTree,
  useHandleItemList,
  useModelAction,
} from "./TreeModalHook";
import ModalBox from "../ModalBox";
import DeleteButton from "../../atom/DeleteButton";

const saveTreeState = (state) => {
  localStorage.setItem("treeClassificationTreeState", JSON.stringify(state));
};

const loadTreeState = () => {
  const savedState = localStorage.getItem("treeClassificationTreeState");
  return savedState ? JSON.parse(savedState) : {};
};

export default function TreeModal({
  //   onModalCountInputChange,
  url,
  treeConfigure = (val) => val,
  checkOneOnly,
  applyFunction,
  closeModal = () => {},
  isActivate,
  setisActivate,
  widthArray = [2, 6, 9, 4],
  indexArray = ["", "Part Number", "부품명", "revision"],
  indexArray2 = ["Part Number", "부품명"],
  itemArray,
  itemArray2 = ["number", "name"],
}) {
  const [treeState, setTreeState] = useState(loadTreeState());
  const setIsOpen = (id, isOpen) => {
    setTreeState((prevState) => {
      const newState = { ...prevState, [id]: isOpen };
      saveTreeState(newState);
      return newState;
    });
  };
  //    ---CONST
  const modalContainerRef = useRef();

  //    ---MODEL
  const { model, action } = useModelAction();

  //    ---VIEW
  const getIsItemRowChecked = (itemObj) =>
    model.tempCheckedList.findIndex((item) => item.id === itemObj.id) > -1;
  const secondIndexWidthArray = [7, 7];
  const secondWidthArray = [7, 4, 3];

  for (let i = 0; i < indexArray2.length - 2; i += 1) {
    secondIndexWidthArray.splice(1, 0, 7);
    secondWidthArray.splice(1, 0, 7);
  }

  //    ---ACTION
  const onSelectClassificationTree = (val) => {
    const isItemLeaf = val.last === 1;
    if (isItemLeaf) {
      action.setClassificationObj(val);
      modalContainerRef.current.scrollTo({
        left: 250,
        behavior: "smooth",
      });
    }
  };
  const onClickSecondApplyButton = () => {
    modalContainerRef.current.scrollTo({
      left: 3000,
      behavior: "smooth",
    });
    action.applyTempToThird();
  };
  const onClickFinalApplyButton = () => {
    applyFunction(model.finalItemList);
    onCloseModal();
  };
  const onCheckItem = (val) => {
    action.addItemToTempChecked(val, checkOneOnly);
  };
  const onCountInputChange = () => {};
  const onDeleteFinalItem = (id) => {
    action.deleteFromFinal(id);
    action.deleteFromTemp(id);
  };
  const onCloseModal = () => {
    action.initModel();
    setisActivate(false);
    closeModal();
  };
  //    ---BUSINESS MODEL

  const {
    secondItemList,
    rowsPerPage,
    setrowsPerPage,
    totalPage,
    searchText,
    setsearchText,
    pageNum,
    setpageNum,
  } = useHandleItemList({
    url,
    selectedItemClassificationObj: model.selectedItemClassificationObj,
  });
  const { classificationTree } = useHandleClassificationTree(treeConfigure);

  return (
    <ModalBox isActivate={isActivate} setisActivate={setisActivate}>
      <ModalStyle ref={modalContainerRef}>
        <PropModalStyle width="240px" height="70vh" fontSize="20px">
          <div className="title">Choose Item Classification</div>
          <div className="treeScrollContainer">
            {classificationTree?.map((item, i) => {
              return (
                <ClassificationSelectTree
                  key={i}
                  child={item.children}
                  title={item.name}
                  last={item.last}
                  id={item.id}
                  data={item}
                  depth={0}
                  treeId={i}
                  backgroundColor="var(--eciBlue)"
                  boxWidth="100px"
                  onClick={onSelectClassificationTree}
                  currentState={model.selectedItemClassificationObj}
                  isOpen={treeState}
                  setIsOpen={setIsOpen}
                />
              );
            })}
          </div>
        </PropModalStyle>
        <div className="gap" />
        <PropModalStyle width="680px" height="70vh" fontSize="20px">
          <div className="title">Select the Item</div>
          <SearchBar
            state={searchText}
            setState={setsearchText}
            height="32px"
            cssStyle="margin:10px 16px; "
          />

          <div className="modal">
            <PaginationTable
              width="100%"
              minHeight="90%"
              totalPage={totalPage}
              rowsPerPageDefault={10}
              pageNum={pageNum}
              setRowsPerPage={setrowsPerPage}
              rowsPerPage={rowsPerPage}
              setpageNum={setpageNum}
              noshadow
            >
              <TableIndexRow widthArray={widthArray} itemArray={indexArray} />

              {secondItemList.map((itemObj, i) => (
                <TableRow
                  key={i}
                  widthArray={widthArray}
                  itemArray={[
                    <input
                      type="checkbox"
                      onChange={() => {
                        onCheckItem(itemObj);
                      }}
                      checked={getIsItemRowChecked(itemObj)}
                    />,
                    itemObj.number,
                    itemObj.name,
                    itemObj.revision || "-",
                    itemObj.phase || "",
                  ]}
                />
              ))}
            </PaginationTable>
          </div>

          <ButtonPart>
            <div className="actionButton cancel" onClick={onCloseModal}>
              CANCEL
            </div>
            <div
              className="actionButton apply"
              onClick={onClickSecondApplyButton}
            >
              APPLY
            </div>
          </ButtonPart>
        </PropModalStyle>

        <div className="gap" />

        <PropModalStyle width="500px" height="70vh" fontSize="20px">
          <div className="title">Added Items</div>

          <div className="modal">
            <Table width="100%" noshadow>
              <TableIndexRow
                widthArray={secondIndexWidthArray}
                itemArray={indexArray2}
              />
              {model.finalItemList.map((itemObj, i) => (
                <TableRow
                  key={i}
                  widthArray={secondWidthArray}
                  itemArray={[
                    ...itemArray2.map((key) => itemObj[key]),
                    <DeleteButton
                      onDelete={() => onDeleteFinalItem(itemObj.id)}
                    />,
                  ]}
                />
              ))}
            </Table>
          </div>
          <ButtonPart>
            <div className="actionButton cancel" onClick={onCloseModal}>
              CANCEL
            </div>
            <div
              className="actionButton apply"
              onClick={onClickFinalApplyButton}
            >
              APPLY
            </div>
          </ButtonPart>
        </PropModalStyle>
      </ModalStyle>
    </ModalBox>
  );
}
