import React from "react";
import styled from "styled-components";

const DoubleCheckInputStyle = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: ${(props) => (props.height ? props.height : "40px")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "inherit"};
  .checkTitle {
    font-size: 12px;
    font-weight: 600;
    color: var(--textDarkGray);
    margin-right: 20px;
    color: ${(props) =>
      props.titleColor ? props.titleColor : "var(--textDarkGray)"};
  }
  .key {
    display: flex;
    align-items: center;
    margin-right: 16px;
    label {
      font-size: 12px;
      color: var(--eciBlue);
    }
  }
  pointer-events: ${(props) => props.readOnly && "none"};
  margin-bottom: 20px;
`;
export default function DoubleCheckInput({
  state,
  setstate,
  title,
  optionArr,

  height,
  backgroundColor,
  width,

  readOnly,
  titleColor,
}) {
  const optionList =
    optionArr &&
    optionArr.map((name, i) => {
      return (
        <div className="key" key={i}>
          <input
            type="radio"
            id={name + i}
            name={name}
            value={i + 1}
            onChange={() => {
              setstate(i + 1);
            }}
            checked={state === i + 1}
          />
          <label htmlFor={name + i}>{name}</label>
        </div>
      );
    });
  return (
    <DoubleCheckInputStyle
      height={height}
      width={width}
      readOnly={readOnly}
      backgroundColor={backgroundColor}
      titleColor={titleColor}
    >
      <div className="checkTitle">{title}</div>
      {optionList}
    </DoubleCheckInputStyle>
  );
}
