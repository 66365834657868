import React, { useState } from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";
import ModuleTopNavigation from "../molecule/ModuleTopNavigation";
import { ModuleStyle } from "../Style";

const CrcoModuleNavigationPageStyle = styled.div`
  display: flex;
  .navBar {
    width: 10%;
    padding-top: 30px;
    > * {
      display: block;
    }
  }
`;
export default function CrcoModule() {
  return (
    <ModuleStyle>
      <ModuleTopNavigation
        title="CR / CO"
        navList={[
          {
            name: "Changing Request",
            to: "/crco/cr",
          },
          {
            name: "Change Order",
            to: "/crco/co",
          },
        ]}
      />
      <CrcoModuleNavigationPageStyle>
        <Outlet />
      </CrcoModuleNavigationPageStyle>
    </ModuleStyle>
  );
}
