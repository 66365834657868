import React, { useRef } from "react";
import styled from "styled-components";
import boardStore from "../../store/boardStore";
import { PropModalStyle, ScrollDefault } from "../../Style";
import Table from "../../molecule/Table";
import TableIndexRow from "../../atom/TableIndexRow";

import TableRow from "../../atom/TableRow";
import PaginationTable from "../../molecule/PaginationTable";
import SearchBar from "../../molecule/SearchBar";
import DeleteButton from "../../atom/DeleteButton";
import txt from "../../Text";

const ModalStyle = styled.div`
  display: flex;
  width: 80vw;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 1px solid var(--deemGray);
    width: 5px;
    background: white;
    border-radius: 8px;
  }
  .gap {
    width: 40px;
    flex-shrink: 0;
  }
  .treeScrollContainer {
    height: calc(100% - 65px);

    ${ScrollDefault}
    overflow-x: hidden;
  }
`;
export default function BoardModal({
  cancelFunc,
  applyLv2,
  applyLv3,
  onCheckState,
  onModalCountInputChange,
  onDeleteModalAddedItems,
  type,
}) {
  const modalContainerRef = useRef();
  const store = boardStore();
  return (
    <ModalStyle ref={modalContainerRef}>
      <PropModalStyle width="680px" height="70vh" fontSize="20px">
        <div className="title">Select the {store.modalType.toUpperCase}</div>
        <SearchBar
          state={store.searchText}
          setState={store.setsearchText}
          height="32px"
          cssStyle="margin:10px 16px; "
        />
        <div className="modal">
          <PaginationTable
            width="100%"
            minHeight="90%"
            totalPage={store.totalPage}
            rowsPerPageDefault={10}
            pageNum={store.pageNum}
            setRowsPerPage={store.setrowsPerPage}
            rowsPerPage={store.rowsPerPage}
            setpageNum={store.setpageNum}
            noshadow
          >
            <TableIndexRow
              widthArray={[2, 6, 9, 4, 4]}
              itemArray={txt.board.modalFirstIndex}
            />

            {store.modalItemList.map((itemObj, i) => (
              <TableRow
                key={i}
                widthArray={[2, 6, 9, 4, 4]}
                itemArray={[
                  <input
                    type="checkbox"
                    onChange={() => {
                      onCheckState(itemObj);
                    }}
                    checked={
                      store.modalTempCheckedList.findIndex(
                        (item) => item.id === itemObj.id
                      ) > -1
                    }
                  />,
                  itemObj.number,
                  itemObj.name,
                  itemObj.revision,
                  itemObj.phase,
                ]}
              />
            ))}
          </PaginationTable>
        </div>

        <div className="buttonPart">
          <div className="actionButton cancel" onClick={cancelFunc}>
            CANCEL
          </div>
          <div
            className="actionButton apply"
            onClick={() => {
              modalContainerRef.current.scrollTo({
                left: 3000,
                behavior: "smooth",
              });
              applyLv2();
            }}
          >
            APPLY
          </div>
        </div>
      </PropModalStyle>
      <div className="gap" />

      <PropModalStyle width="500px" height="70vh" fontSize="20px">
        <div className="title">Added Items</div>
        <div className="modal">
          <Table width="100%" noshadow>
            <TableIndexRow
              widthArray={[7, 7]}
              itemArray={txt.board.modalSecondIndex}
            />

            {store.modalCheckedItemList.map((itemObj, i) => (
              <TableRow
                key={i}
                widthArray={[7, 6, 1]}
                itemArray={[
                  itemObj.number,
                  itemObj.name,
                  <DeleteButton
                    onDelete={() => onDeleteModalAddedItems(itemObj.id)}
                  />,
                ]}
              />
            ))}
          </Table>
        </div>

        <div className="buttonPart">
          <div className="actionButton cancel" onClick={cancelFunc}>
            CANCEL
          </div>
          <div className="actionButton apply" onClick={applyLv3}>
            APPLY
          </div>
        </div>
      </PropModalStyle>
    </ModalStyle>
  );
}
