import create from "zustand";
import { defaultStoreSeed, stateObjSeed } from "./defaultStore";

const projectStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  ...stateObjSeed(set, get),
  stateObj: {
    projectTypeId: "", //  post시는 projectTypeId : 1,2
    number: "", // 문자열
    name: "", // 문자열
    allDoStartPeriod: "", // 날짜
    allDoOverPeriod: "", //  날짜
    protoStartPeriod: "", //  날짜
    protoOverPeriod: "", //  날짜
    p1StartPeriod: "", // 날짜
    p1OverPeriod: "", // 날짜
    p2StartPeriod: "", //  날짜
    p2OverPeriod: "", // 날짜
    mStartPeriod: "", // 날짜
    mOverPeriod: "", //  날짜
    productId: "", // 정수
    buyerOrganizationId: "",
    produceOrganizationId: "",
    carTypeId: "",
  },
  products: [],
  setproducts: (val) => {
    set(() => ({ products: val }));
  },
  onDeleteProduct: (delObj) => {
    const { products } = get();

    const tmpProducts = [...products];
    set(() => ({
      products: tmpProducts.filter((productObj) => productObj.id !== delObj.id),
    }));
  },

  period: false,
  setperiod: (val) => {
    set(() => ({ period: val }));
  },

  isModal: false,
  setisModal: (val) => {
    set(() => ({ isModal: val }));
  },

  // init
  initModuleStore: () => {
    set(() => ({
      stateObj: {
        projectTypeId: "", //  post시는 projectTypeId : 1,2
        period: "", // all도, 초도, proto, p1 => "",1,2,숫자로
        number: "", // 문자열
        name: "", // 문자열
        allDoStartPeriod: "", // 날짜
        allDoOverPeriod: "", //  날짜
        protoStartPeriod: "", //  날짜
        protoOverPeriod: "", //  날짜
        p1StartPeriod: "", // 날짜
        p1OverPeriod: "", // 날짜
        p2StartPeriod: "", //  날짜
        p2OverPeriod: "", // 날짜
        mStartPeriod: "", // 날짜
        mOverPeriod: "", //  날짜
        buyerOrganization: "",
        produceOrganization: "",
        carTypeId: "",
      },
      products: [],
      period: false,

      isModal: false,
    }));
  },

  getisConditionFullfill: () => {
    let condition = true;
    const { stateObj, products, isAttachmentValid } = get();
    condition =
      !!stateObj.name &&
      !!isAttachmentValid &&
      !!stateObj.projectTypeId &&
      !!products[0] &&
      !!stateObj.carTypeId;
    return condition;
  },
  attachmentTagOptionList: [
    { id: 2, name: "ETC" },
    { id: 1, name: "개발사양서", option: 1 },
    // gerber
    // pcb dr
  ],
}));
export default projectStore;
