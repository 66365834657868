import {
  appendAttachmentFormData,
  appendStateObj,
  deleteFormdataKey,
  setAttachmentArrays,
} from "../utility/Utility";
import useTokenAxios from "../utility/hooks/useTokenAxios";
import txt from "../Text";

export const appendProductForm = (edit, store) => {
  const formData = new FormData();
  const { stateObj } = store;
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, [
    "classificationId",
    "revision",
    "specification",
  ]);

  formData.append(
    "classificationId",
    stateObj.classificationId.id || stateObj.classificationId.classId
  );
  appendAttachmentFormData(formData, edit, store);

  return formData;
};
export const useGetProductData = (id, productstore) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/product/${id}`);
    const { data } = response.data.result;
    const { setStateObjWithData, setrouteNumber, setStateByKey } = productstore;
    // setstate
    setStateObjWithData(data);
    setStateByKey("partNumber")(data.number);
    setAttachmentArrays(data.projectAttachmentList, productstore);
    setrouteNumber(data.routeId);
  };
};
export const useGetProductBomData = (id, store) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const {
      setStateObjWithData,
      setStateByKey,
      setproductChildList,
      setpriceBomTree,
    } = store;
    const response = await tokenAxios.get(`/product/bom/${id}`);
    const { data } = response.data.result;
    // setstate
    const tmpArr = [...data.item];
    if (data.board[0]) {
      data.board.forEach((boardObj) => {
        tmpArr.unshift(boardObj);
      });
    }
    setproductChildList(
      (data.item?.length || 0) + (data.board?.length || 0) < 1 ? [1] : tmpArr
    );
    const priceBomTree = [...data.item];
    if (data.board[0]) priceBomTree.unshift(data.board[0]);
    setpriceBomTree(priceBomTree);
    setStateObjWithData(data);
    setStateByKey("partNumber")(data.number);
    store.setrouteNumber(data.routeId || "");
  };
};
export const useGetSRBomData = (id, store) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const {
      setStateObjWithData,
      setStateByKey,
      setproductChildList,
      setpriceBomTree,
    } = store;
    const response = await tokenAxios.get(`/product/sr/${id}`);
    const { data } = response.data.result;
    const bomTree = JSON.parse(data.srBom);
    // setstate
    setproductChildList(bomTree || [1]);
    setpriceBomTree(bomTree);
    setStateObjWithData(data);
    setStateByKey("partNumber")(data.number);

    store.setrouteNumber(data.routeId || "");
  };
};
export const useSaveProduct = (id, store, isTemp) => {
  const tokenAxios = useTokenAxios();
  const saveFunc = async () => {
    const { addedProductChildList, deletedProductChildList } = store;
    const formData = new FormData();
    //  추가
    formData.append(
      "items",
      addedProductChildList.map((item) => item.id || "")
    );
    formData.append(
      "deleted",
      deletedProductChildList.map((item) => item.id || "")
    );
    try {
      const response = await tokenAxios.put(`product-add/${id}`, formData);
      store.synchroPriceTreeBomTree();
      if (!isTemp) store.setisRouteActive(true);
    } catch {
      alert("err");
    }
  };
  return saveFunc;
};
export const useSaveSR = (id, store) => {
  const tokenAxios = useTokenAxios();

  const saveFunc = async () => {
    const { productChildList } = store;
    const formData = new FormData();
    //  type추가
    const addType = (itemObj) => {
      itemObj.type =
        itemObj.classId?.name.findIndex("2/5/629") > -1 ? "board" : "item";

      itemObj.children?.forEach((childObj) => {
        addType(childObj);
      });
    };
    productChildList.forEach((obj) => {
      addType(obj);
    });
    formData.append("srBom", JSON.stringify(productChildList));

    try {
      const response = await tokenAxios.put(`product/sr/${id}`, formData);
      // store.synchroPriceTreeBomTree();
      // store.setisRouteActive(true);
      alert("done");
    } catch {
      alert("err");
    }
  };
  return saveFunc;
};
export const productInputList = [
  {
    inputType: "DB",
    name: "Item Classification",
    requestName: "classificationId",
    option: 0,
    choiceFields: [],
    gray: true,
    readOnly: true,

    cell: 2,
  },
  {
    inputType: "KEY_IN",
    name: "Product Part Number",
    requestName: "partNumber",
    option: 0,
    choiceFields: [],

    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: "Product Name",
    requestName: "name",
    option: 0,
    choiceFields: [],
    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: "Product Specification",
    requestName: "specification",
    option: 1,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: "Product Revision",
    requestName: "revision",
    option: 1,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: "비고",
    requestName: "note",
    option: 1,
    choiceFields: [],
    gray: false,
    readOnly: false,
    cell: 2,
  },
];
