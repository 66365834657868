import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import LineTitle from "../../atom/LineTitle";
import InputGrid from "../../organism/InputGrid";
import boardStore from "../../store/boardStore";
import { MultiSearchInputStyle, PageStyle } from "../../Style";
import { boardInputList } from "../utility";

const FlexStyle = styled.div`
  display: flex;
  width: 100%;
  .halfFlex {
    width: 50%;
  }
`;
export default function BoardInformationSection({ readOnly }) {
  const boardstore = boardStore();
  const informationRef = useRef();

  useEffect(() => {
    boardstore.setinformationRef(informationRef);
  }, []);
  return (
    <PageStyle ref={informationRef}>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        Item Information
      </LineTitle>{" "}
      <InputGrid
        readOnly={readOnly}
        modulestore={boardstore}
        inPutList={boardInputList}
      />
      {/* {boardstore.stateObj.produceOrganizationId.map((item, i) => {
        return (
          <FlexStyle key={i}>
            <div className="halfFlex">
              <MultiSearchInputStyle key={i}>
                {!readOnly &&
                  (i ===
                  boardstore.stateObj.produceOrganizationId.length - 1 ? (
                    <span
                      className="plus"
                      onClick={() => {
                        if (
                          boardstore.stateObj.produceOrganizationId[
                            boardstore.stateObj.produceOrganizationId.length - 1
                          ].id !== ""
                        ) {
                          boardstore.setStateByKey("produceOrganizationId")([
                            ...boardstore.stateObj.produceOrganizationId,
                            { id: "", name: "" },
                          ]);
                        }
                      }}
                    >
                      +
                    </span>
                  ) : (
                    <span className="plus"> </span>
                  ))}
                <AnimationSearchInput
                  readOnly={readOnly}
                  width="100%"
                  height="40px"
                  placeholder="제조사"
                  state={item.code1 || item.code2 || item.name2}
                  onClick={() => {
                    boardstore.setsearchBoxType("produceOrganizationId");
                    boardstore.setsearchBoxTargetIndex(i);
                  }}
                  isNow={
                    boardstore.searchBoxType === "produceOrganizationId" &&
                    boardstore.searchBoxTargetIndex === i
                  }
                />
              </MultiSearchInputStyle>
            </div>
          </FlexStyle>
        );
      })} */}
    </PageStyle>
  );
}
