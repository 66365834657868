import styled, { css } from "styled-components";

// 모듈에 공통적으로 적용되는 스타일
export const ModuleStyle = styled.div`
  width: 100%;
`;

// 페이지에 공통적으로 적용되는 스타일
export const PageStyle = styled.div`
  padding-top: 20px;
`;
export const WidthHeightStyle = css`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
`;
export const ScrollDefault = css`
  overflow: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 15px solid transparent;
    width: 5px;
    background: rgba(4, 8, 15, 0.3);
    border-radius: 8px;
  }
`;
export const ListPageStyle = styled.div`
  //얘는 searchSection div를 넣어서 사용하자. 컴포넌트를 새로 파기에는 변동성이 크다.
  .searchSection {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const GridContainerStyle = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: repeat(${(props) => props.rows}, 60px);
  grid-column-gap: 15px;
  grid-row-gap: 10px;
`;
export const RowContainerStyle = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  /* grid-template-rows: repeat(${(props) => props.rows}, 60px); */
  grid-column-gap: 15px;
  grid-row-gap: 10px;
`;
export const GridItemContainerStyle = styled.div`
  grid-column: ${(props) => (props.size === 2 ? "1/3" : "")};
`;
export const GridItemFlexContainer = styled.div`
  width: calc(${(props) => props.width});
  display: flex;
  align-items: flex-start;
  gap: 15px;
`;
export const SectionGap = styled.div`
  width: 100px;
  height: 100px;
`;
//----------------------------------------------------------

export const WhitePlusButton = styled.div`
  border-radius: 5px;
  padding: 0 8px;
  border: solid var(--deemGray) 1px;
  color: var(--textDarkGray);

  width: calc(${(props) => (props.width ? props.width : "100px")});
  height: calc(${(props) => (props.height ? props.height : "30px")});
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .material-icons {
    color: var(--textDarkGray);
    font-size: 16px;
  }
`;
export const GrayButton = styled.div`
  height: calc(${(props) => props.height} - 16px);
  line-height: calc(${(props) => props.height} - 16px);
  text-align: center;
  width: ${(props) => (props.width ? props.width : "80px")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: var(--textGray);
  color: var(--eciBlue);
  border-radius: 10px;
  border: solid 1px var(--eciBlue);
  font-weight: 600;
  font-size: 12px;
  padding: 0 4px;
  cursor: pointer;
  flex-shrink: 0;
  ${(props) => props.styleCss}
`;
export const BlueButtonStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--eciBlue)"};
  color: ${(props) => (props.color ? props.color : "white")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: 600;
  div {
    color: ${(props) => (props.color ? props.color : "white")};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
    font-weight: 600;
  }
  span {
    color: ${(props) => (props.color ? props.color : "white")};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
    font-weight: 600;
  }
  cursor: pointer;
  ${(props) => props.css}
`;

export const BoldBlueTextStyle = styled.div`
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color || "var(--eciBlue)"};
  font-weight: 600;
  height: ${(props) => props.height};
  line-height: ${(props) => props.height};
  margin: ${(props) => props.margin};
`;

export const StripeBoxStyle = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 20px;
  border-top: ${(props) => props.top && "solid 1px var(--textGray)"};
  background-color: white;
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
`;

export const PropIndexStyle = styled.div`
  font-size: 14px;
  width: calc(${(props) => props.width});
  font-weight: 600;
  color: var(--textDarkGray);
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PropModalStyle = styled.div`
  min-width: 300px;
  height: calc(${(props) => (props.height ? props.height : "50vh + 30px")});
  background-color: var(--eciBlue);
  border-radius: 10px;
  position: relative;
  width: calc(${(props) => (props.width ? props.width : "40%")});
  flex-shrink: 0;
  > .title {
    color: white;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
    font-weight: 600;
    height: 10%;
    min-height: 40px;
    line-height: 40px;
    padding-left: 16px;
    border-radius: 10px 10px 0 0;
    background-color: var(--eciBlue);
  }

  > .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 81%;
    border-radius: 8px;
    padding: 16px;
    overflow-y: scroll;
    ${ScrollDefault}
  }
  > .buttonPart {
    position: absolute;
    bottom: 0px;
    left: 0px;
    border-top: solid var(--textGray) 1px;
    height: 10%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    .actionButton {
      cursor: pointer;
      padding: 4px 20px;
      font-weight: 600;
      color: var(--textDarkGray);
    }
    .cancel {
      border-right: 3px solid var(--textGray);
    }
    .apply {
      color: var(--eciBlue);
    }
  }
`;

export const LabelInputStyle = styled.div`
  display: flex;
  height: ${(props) => (props.height ? props.height : "40px")};
  align-items: center;
  justify-content: space-between;

  .title {
    width: 25%;
    font-size: 12px;
    font-weight: 600;
    color: var(--eciBlue);
    /* transform: translate(0, -10px); */
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export const LightGrayInputContainer = styled.div`
  width: ${(props) => (props.width ? props.width : "60%")};
  height: ${(props) => props.height && props.height};
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 10px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "var(--lightGray)"};
  padding: 0 8px;
  .material-icons {
    cursor: pointer;
    color: var(--textGray);
    margin-top: 4px;
    font-size: 20px;
  }
  ${(props) => props.styleCss}
`;
export const MultiSearchInputStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
  span {
    color: var(--textGray);
    font-weight: 900;
    cursor: pointer;
    font-size: 24px;
    width: 26px;
  }
`;

export const BlueBackGroundBox = styled.div`
  grid-column: 1/3;
  grid-row: 4/9;
  padding: 15px;
  background-color: var(--eciBlue);
  border-radius: 10px;
`;
export const RequiredDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 10px;
  margin-left: 3px;
  display: inline-block;
  background-color: var(--required);
  opacity: ${(props) => (!props.readOnly ? 1 : 0)};
`;
