import React, { useEffect, useRef } from "react";
import LineTitle from "../../atom/LineTitle";
import InputGrid from "../../organism/InputGrid";
import firmwareStore from "../../store/firmwareStore";
import { PageStyle } from "../../Style";
import { firmwareInputList } from "../utility";

export default function BoardInformationSection({ readOnly }) {
  const firmwarestore = firmwareStore();
  const informationRef = useRef();

  useEffect(() => {
    firmwarestore.setinformationRef(informationRef);
  }, []);
  return (
    <PageStyle ref={informationRef}>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        Item Information
      </LineTitle>{" "}
      <InputGrid
        readOnly={readOnly}
        modulestore={firmwarestore}
        inPutList={firmwareInputList(firmwarestore.phase)}
      />
    </PageStyle>
  );
}
