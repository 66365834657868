import React, { useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import LineTitle from "../../atom/LineTitle";
import InputGrid from "../../organism/InputGrid";
import electronicPartStore from "../../store/electronicPartStore";
import { BlueBackGroundBox, PageStyle } from "../../Style";
import {
  appendElectronicPartForm,
  electronicPartBottomInputList,
} from "../utility";
import txt from "../../Text";
import Button from "../../atom/Button";
import useSaveFunction from "../../utility/hooks/useSaveFunction";

const Style = styled(PageStyle)`
  .flex {
    display: flex;
    gap: 20px;
    align-items: center;
  }
`;
export default function ElectronicPartInformationSection({ readOnly }) {
  const electronicPartstore = electronicPartStore();
  const [isChanging, setisChanging] = useState(false);
  const isUserValidToChange = electronicPartstore.stateObj.button;
  const params = useParams();
  const saveFunc = useSaveFunction(
    "item",
    appendElectronicPartForm,
    electronicPartstore,
    true,
    params.id
  );
  return (
    <Style>
      <div className="flex">
        <LineTitle
          fontSize="16px"
          color="var(--eciBlue)"
          width="100%"
          height="60px"
        >
          {txt.elec.purchaseDetail}
        </LineTitle>
        {isUserValidToChange && (
          <Button
            width="80px"
            height="24px"
            color="white"
            backgroundColor="var(--eciBlue)"
            condition
            onClick={
              isChanging
                ? () => {
                    setisChanging(false);

                    saveFunc();
                  }
                : () => {
                    setisChanging(true);
                  }
            }
          >
            {isChanging ? txt.elec.save : txt.elec.change}
          </Button>
        )}
      </div>

      <InputGrid
        readOnly={readOnly && !isChanging}
        modulestore={electronicPartstore}
        inPutList={electronicPartBottomInputList}
      />
    </Style>
  );
}
