import React, { useState } from "react";
import styled from "styled-components";
import Input from "../../atom/Input";
import SelectBox from "../../atom/SelectBox";
import { GrayButton } from "../../Style";
import ContextMenuTitle from "../../molecule/ContextMenuTitle";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import NewPropRow from "./NewPropRow";

const PropManageRowStyle = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: solid 1px var(--textGray);
  background-color: ${(props) => props.isCurrent && "var(--backgroundYellow)"};
  cursor: pointer;
  position: relative;
  .blue {
    position: absolute;
    width: 6px;
    height: 48px;
    background-color: var(--eciBlue);
    border-radius: 5px;
  }
  .name {
    width: 30%;
    min-width: 100px;
    color: var(--eciBlue);
    margin-left: 16px;
    font-weight: 600;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 20px;
  }
  .inputContainer {
    width: 30%;
    min-width: 100px;
    margin-left: 16px;
    margin-right: 20px;

    height: 70%;
    background-color: var(--lightGray);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .spin {
    position: absolute;
    right: 0;
    transform: rotate(-90deg);
    font-size: 14px;
    color: var(--textDarkGray);
  }
  .buttonContainer {
    position: absolute;
    right: 0;
  }
  .gap {
    width: 40px;
  }
`;
export default function PropManageRow({
  id,
  name,
  type,
  isCurrent,
  setcurrentId,
  isContextMenuPossible,
  setisContextMenuPossible,
  onClick,
}) {
  const tokenAxios = useTokenAxios();

  const [nameState, setnameState] = useState(name);
  const [typeState, settypeState] = useState(type);
  const [propReadOnly, setpropReadOnly] = useState(true);
  const [isNew, setisNew] = useState(false);
  const [isButton, setisButton] = useState(false);
  const [isInput, setisInput] = useState(false);
  const [newNameState, setnewNameState] = useState("");
  const [newTypeState, setnewTypeState] = useState("new");
  const typeColorObject = {
    DROP_DOWN: "var(--pink)",
    KEY_IN: "var(--skyBlue)",
    UNIT_KEY_IN: "var(--skyBlue)",
    DB: "var(--todoGreen)",
    RANGE_KEY_IN: "rgba(253, 191, 77, 0.2)",
    new: "#D9D9D9",
  };
  //    ui function
  const addProp = () => {
    if (!isContextMenuPossible) {
      setisNew(true);
      setisContextMenuPossible(true);
    } else {
      alert("finish first");
    }
  };

  const editTitle = () => {
    if (!isContextMenuPossible) {
      setisInput(true);
      setisButton(true);
      setisContextMenuPossible(true);
    } else {
      alert("finish first");
    }
  };
  //    axios function
  const sendDelete = async () => {
    if (!isContextMenuPossible) {
      try {
        tokenAxios.delete(`property/${id}`);
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("finish first");
    }
  };

  const put = async () => {
    const formData = new FormData();
    formData.append("name", nameState);
    try {
      tokenAxios.put(`property/${id}`, formData);
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    // <PropManageRowStyle >
    <div onClick={() => onClick()}>
      <ContextMenuTitle
        title={
          <PropManageRowStyle
            onClick={() => setcurrentId(id)}
            isCurrent={isCurrent}
          >
            {isCurrent && <div className="blue" />}
            {isInput ? (
              <div className="inputContainer">
                <Input
                  state={nameState}
                  setState={setnameState}
                  fontSize="12px"
                />
              </div>
            ) : (
              <div className="name">{name}</div>
            )}
            <SelectBox
              fontWeight="600"
              color="var(--eciBlue)"
              width="100px"
              height="32px"
              fontSize="13px"
              readOnly={propReadOnly}
              state={typeState}
              setState={settypeState}
              backgroundColor={typeColorObject[typeState]}
            >
              <option value="DB">Table</option>
              <option value="KEY_IN">key-in</option>
              <option value="RANGE_KEY_IN">범위Key-in</option>
              <option value="DROP_DOWN">Drop Down</option>
              <option value="UNIT_KEY_IN">단위</option>
            </SelectBox>
            {isButton ? (
              <div className="buttonContainer">
                <GrayButton height="40px" width="50px" onClick={put}>
                  반영
                </GrayButton>
              </div>
            ) : (
              <div className="material-icons spin">expand_more</div>
            )}
          </PropManageRowStyle>
        }
      >
        <div onClick={addProp}>속성 추가</div>
        <div onClick={editTitle}>타이틀 편집</div>
        <div onClick={sendDelete}>삭제</div>
      </ContextMenuTitle>
      {isNew && <NewPropRow />}
    </div>
  );
}
