import React from "react";
import { Outlet } from "react-router-dom";
import ModuleTopNavigation from "../molecule/ModuleTopNavigation";
import { ModuleStyle } from "../Style";

export default function ItemModule() {
  return (
    <ModuleStyle>
      <ModuleTopNavigation
        title="Item"
        navList={[
          {
            name: "List",
            to: "/item",
          },
        ]}
      />
      <Outlet />
    </ModuleStyle>
  );
}
