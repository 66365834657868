import {
  appendAttachmentFormData,
  appendStateObj,
  deleteFormdataKey,
  setAttachmentArrays,
} from "../utility/Utility";
import useTokenAxios from "../utility/hooks/useTokenAxios";

export const appendFirmwareForm = (edit, firmwareStore) => {
  const { stateObj } = firmwareStore;
  const formData = new FormData();
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, ["classificationId"]);
  formData.append("classificationId", stateObj.classificationId.id);

  appendAttachmentFormData(formData, edit, firmwareStore);

  return formData;
};
export const useGetFirmwareData = (id, firmwarestore, callBack) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/firmware/${id}`);
    const { data } = response.data.result;
    const {
      setStateObjWithData,

      setrouteNumber,
    } = firmwarestore;
    // setstate
    setStateObjWithData(data);
    // custom

    //  attachment
    setAttachmentArrays(data.attachmentList, firmwarestore);

    setrouteNumber(data.routeId);
    if (callBack) {
      //  isRouteActivate, isReject...등등은 콜백으로 관리
      callBack(data);
    }
  };
};
export const firmwareInputList = (phase) => [
  {
    inputType: "DB",
    name: "Item Classification",
    requestName: "classificationId",
    option: 1,
    choiceFields: [],
    gray: true,
    readOnly: true,

    cell: 2,
  },
  {
    inputType: "KEY_IN",
    name: "Firmware Part Number",
    requestName: "partNumber",
    option: 0,
    choiceFields: [],

    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: "Firmware Name",
    requestName: "name",
    option: 0,
    choiceFields: [],
    gray: phase === "1",
    readOnly: phase === "1",
    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: "Firmware Revision",
    requestName: "revision",
    option: 0,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 1,
  },
];
