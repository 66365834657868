import React from "react";
import styled, { css } from "styled-components";

const SearchInputContainerStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});

  .fillIn {
    height: 15px;
    margin-top: 2px;
    font-size: 10px;
    color: red;
    margin-left: 10px;
  }
`;
const SearchInputStyle = styled.div`
  width: 100%;
  height: calc(${(props) => props.height});
  padding: 5px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  border-radius: 10px;
  border: ${(props) => (props.requiredError ? "solid red 1px" : ``)};

  font-size: calc((${(props) => props.height}) / 3);
  //  fontSize 나중에 확인

  font-family: "Roboto";
  color: ${(props) => (props.blue ? "white" : "var(--deemGray)")};
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  .placeholder {
    padding: 3px;
    color: var(--textDarkGray);
    /* font-weight: 700; */
    font-size: calc((${(props) => props.height}) / 3);
    //  fontSize 나중에 확인

    opacity: ${(props) => (props.state ? 0 : 1)};
  }

  button {
    border: none;
    background-color: transparent;
    outline: none;
    color: var(--textGray);
  }

  .required {
    width: 5px;
    height: 5px;
    border-radius: 10px;
    margin-left: 3px;
    display: inline-block;
    background-color: var(--required);
    opacity: ${(props) => (props.required && !props.readOnly ? 1 : 0)};
  }
`;
export default function PlainSearchInput({
  width,
  height,
  placeholder,
  onClick,
  state,
  requiredError,
  readOnly,
  required,
  backgroundColor,
  borderBottom,
}) {
  return (
    <SearchInputContainerStyle width={width} height={`${height} + 20px`}>
      <SearchInputStyle
        height={height}
        state={state}
        requiredError={requiredError}
        required={required}
        readOnly={readOnly}
        backgroundColor={backgroundColor}
      >
        <div className="placeholder">
          {placeholder}
          <div className="required" />
        </div>

        {state || ""}
        {readOnly ? (
          <div className="def" />
        ) : (
          <button type="button" onClick={onClick}>
            <span className="material-icons">search</span>{" "}
          </button>
        )}
      </SearchInputStyle>
      {requiredError && !state ? <div className="fillIn">fill in</div> : ""}
    </SearchInputContainerStyle>
  );
}
