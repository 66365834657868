import create from "zustand";
import produce from "immer";
import { defaultStoreSeed, stateObjSeed } from "./defaultStore";

const coStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  ...stateObjSeed(set, get),
  stateObj: {
    buyerOrganizationId: "", // 고객
    clientItemNumber: "", // 고객CO number
    clientPublishPeriod: "", // 고객Co발행일
    clientReceivedPeriod: "", // 접수일
    costDifferent: "", // bool
    costDifference: "", // 금액차
    reasonId: "", // CO이유
    stageId: "", // CO적용단계
    applyPeriod: "", // CO 적용시점
    effectId: "", // Co영향
    importance: "", // 중요도
    name: "", // 제목
    content: "", // 내용

    changeRequestsId: [], // 연관된 cr목록
    changeModuleIds: [], // 연관된 모듈 아이템 목록, {id, contents,feature포함}
    // changeContents: [], // 연관된 모듈 아이
    // changeFeatureIds: [],
  },
  isModal: false,
  setisModal: (val) => {
    set(() => ({ isModal: val }));
  },
  modalType: false,
  setmodalType: (val) => {
    set(() => ({ modalType: val }));
  },
  modalClassificationTree: [],
  setmodalClassificationTree: (val) => {
    set(() => ({ modalClassificationTree: val }));
  },
  modalSelectedItemClassification: {}, // classId
  setmodalSelectedItemClassification: (val) => {
    set(() => ({ modalSelectedItemClassification: val }));
  },
  modalItemList: [],
  setmodalItemList: (val) => {
    set(() => ({ modalItemList: val }));
  },
  totalPage: "10",
  settotalPage: (val) => {
    set(() => ({ totalPage: val }));
  },
  pageNum: 0,
  setpageNum: (val) => {
    set(() => ({ pageNum: val }));
  },
  rowsPerPage: 6,
  setrowsPerPage: (val) => {
    set(() => ({ rowsPerPage: val }));
  },
  modalTempCheckedList: [], // 추가로 그 아이템 정보

  modalCheckedItemList: [],

  getisConditionFullfill: () => {
    const { stateObj, isAttachmentValid } = get();

    const result =
      !!isAttachmentValid &&
      !!stateObj.buyerOrganizationId?.id &&
      !!stateObj.stageId &&
      !!stateObj.applyPeriod &&
      !!stateObj.effectId.length > 0 &&
      !!stateObj.importance &&
      !!stateObj.name &&
      !!stateObj.content &&
      !!(stateObj.changeModuleIds.length > 0);

    return result;
  },
  //  design attachment
  // attachment
  attachmentTagOptionList: [
    //  체크
    { id: 2, name: "ETC" },
  ], // add default
  setattachmentTagOptionList: (val) => {
    set(() => ({ attachmentTagOptionList: val }));
  },

  //    ACTION
  openModal: () => {
    set(() => ({ isModal: true }));
  },
  exitModalCallBack: () => {
    set(() => ({
      modalTempCheckedList: [],
      modalCheckedItemList: [],
      modalItemList: [],
      modalSelectedItemClassification: {},
      isModal: false,
    }));
  },
  onCheckModalItem: (val) => {
    const arr = get().modalTempCheckedList;
    const tmpArr = [...arr];
    const targetIndex = tmpArr.findIndex((itemObj) => itemObj.id === val.id);
    if (targetIndex > -1) {
      tmpArr.splice(targetIndex, 1);
    } else {
      tmpArr.push(val);
    }

    set(() => ({ modalTempCheckedList: tmpArr }));
  },
  cancelModal: () => {
    set(() => ({
      isModal: false,
      modalTempCheckedList: [],
      modalCheckedItemList: [],
    }));
  },
  applyModalLv2: () => {
    const { modalTempCheckedList, modalSelectedItemClassification } = get();
    const tmpArr = [
      ...modalTempCheckedList.map((obj) => ({
        ...obj,
        classificationName: modalSelectedItemClassification.name,
      })),
    ];
    set(() => ({ modalCheckedItemList: tmpArr }));
  },
  applyModalLv3: () => {
    const { modalCheckedItemList, setStateByKey, stateObj } = get();
    setStateByKey("changeModuleIds")([
      ...stateObj.changeModuleIds,
      ...modalCheckedItemList.map((item) => ({
        ...item,
        content: "",
        feature: "",
      })),
    ]);
    set(() => ({
      isModal: false,
      modalTempCheckedList: [],
      modalCheckedItemList: [],
    }));
  },
  onDeleteModalAddedItems: (id) => {
    set(() => ({
      modalTempCheckedList: get().modalTempCheckedList.filter(
        (itemObj) => itemObj.id !== id
      ),
      modalCheckedItemList: get().modalCheckedItemList.filter(
        (itemObj) => itemObj.id !== id
      ),
    }));
  },
  onClickCrModalRow: (val) => {
    const { stateObj, setStateByKey, exitModalCallBack } = get();
    const tmpCRList = [...stateObj.changeRequestsId];
    const tmpModuleList = [...stateObj.changeModuleIds];
    const idArr = tmpCRList.map((obj) => obj.crId);

    // 중복 제거 알고리즘 추가
    if (idArr.findIndex((item) => val?.itemId === item.id) < 0) {
      tmpCRList.push(val);
    }
    exitModalCallBack();
    setStateByKey("changeRequestsId")(tmpCRList);
    setStateByKey("changeModuleIds")([
      ...tmpModuleList,
      {
        ...val,
        number: val.itemNumber,
        revision: val.itemRevision,
        name: val.itemName,
        classification: val.itemClassification,
        status: val.itemStatus,
      },
    ]);
  },
  onDeleteModuleList: (id) => {
    const { stateObj, setStateByKey } = get();
    const tmpCrArr = structuredClone(stateObj.changeRequestsId);
    const targetIndex = tmpCrArr.findIndex(
      (obj) => obj.id === id || obj.itemId === id
    );
    if (targetIndex > -1) {
      tmpCrArr.splice(targetIndex, 1);
      setStateByKey("changeRequestsId")(tmpCrArr);
    }
    const tmpModuleArr = structuredClone(stateObj.changeModuleIds);
    const targetModuleIndex = tmpModuleArr.findIndex(
      (obj) => obj.id === id || obj.itemId === id
    );
    if (targetModuleIndex > -1) {
      tmpModuleArr.splice(targetModuleIndex, 1);
      setStateByKey("changeModuleIds")(tmpModuleArr);
    }
  },
  onChangeModuleContent: (i) => {
    const setstate = (val) => {
      const { stateObj, setStateByKey } = get();
      const tmpArr = structuredClone(stateObj.changeModuleIds);
      tmpArr[i].content = val;
      setStateByKey("changeModuleIds")(tmpArr);
    };
    return setstate;
  },
  onChangeModulefeature: (i) => {
    const setstate = () => {
      set(() => ({
        searchBoxType: "affectedItemFeatureId",
        searchBoxTargetIndex: i,
      }));
    };
    return setstate;
  },

  setsearchBoxPropertyModuleFeature: (blank, item) => {
    const { stateObj } = get();
    //  array에 추가하는 경우 대비
    const i = get().searchBoxTargetIndex;
    const tmpArr = structuredClone(stateObj.changeModuleIds);
    tmpArr[i].feature = item;

    set(() => ({
      stateObj: produce(stateObj, (draft) => {
        draft.changeModuleIds = tmpArr;
      }),
    }));
  },
  initModuleStore: () => {
    set(() => ({
      stateObj: {
        buyerOrganizationId: "", // 고객
        clientItemNumber: "", // 고객CO number
        clientPublishPeriod: "", // 고객Co발행일
        clientReceivedPeriod: "", // 접수일
        costDifferent: "", // bool
        costDifference: "", // 금액차
        reasonId: "", // CO이유
        stageId: "", // CO적용단계
        applyPeriod: "", // CO 적용시점
        effectId: "", // Co영향
        importance: "", // 중요도
        name: "", // 제목
        content: "", // 내용

        changeRequestsId: [], // 연관된 cr목록
        changeModuleIds: [], // 연관된 모듈 아이템 목록, {id, contents,feature포함}
      },
    }));
  },
}));
export default coStore;
