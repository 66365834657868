import {
  appendAttachmentFormData,
  appendStateObj,
  deleteFormdataKey,
  setAttachmentArrays,
} from "../utility/Utility";
import useTokenAxios from "../utility/hooks/useTokenAxios";
import txt from "../Text";

export const appendCrForm = (edit, crStore) => {
  const { stateObj } = crStore;
  const formData = new FormData();
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, [
    "number",
    "reasonId",
    "sourceId",
    "affectedModuleId",
    "classificationId",
  ]);
  if (stateObj.reasonId) {
    formData.append("reasonId", stateObj.reasonId.id);
  }
  if (stateObj.sourceId.id) {
    formData.append("sourceId", stateObj.sourceId.id);
  }
  if (stateObj.affectedModuleId.id) {
    formData.append("affectedModuleId", stateObj.affectedModuleId.id);
  }
  if (stateObj.classificationId.id) {
    formData.append("classificationId", stateObj.classificationId.id);
  }

  appendAttachmentFormData(formData, edit, crStore);

  return formData;
};
export const useGetcrData = (id, crstore, callBack) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/cr/${id}`);
    const { data } = response.data.result;
    const { setStateObjWithData, setrouteNumber, setStateByKey } = crstore;
    // setstate
    setStateObjWithData(data);
    // custom
    setStateByKey("importance")(data.importanceId);
    //  attachment
    setAttachmentArrays(data.attachments, crstore);

    setrouteNumber(data.routeId);
    if (callBack) {
      //  isRouteActivate, isReject...등등은 콜백으로 관리
      callBack(data);
    }
  };
};

export const appendCoForm = (edit, coStore) => {
  const { stateObj } = coStore;
  const formData = new FormData();
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, [
    //  custom
    "id",
    "buyerOrganizationId",
    "reasonId",
    // "effectId",
    "changeModuleIds",
    "changeRequestsId",
    "costDifferent",
  ]);
  if (stateObj.buyerOrganizationId.id) {
    formData.append("buyerOrganizationId", stateObj.buyerOrganizationId.id);
  }
  if (stateObj.costDifferent) {
    formData.append("costDifferent", stateObj.costDifferent === "1");
  }
  if (stateObj.reasonId.id) {
    formData.append("reasonId", stateObj.reasonId.id);
  }
  if (stateObj.changeRequestsId.length > 0) {
    formData.append(
      "changeRequestsId",
      stateObj.changeRequestsId.map((item) => item.crId)
    );
  }
  //   formData.append("effectId", stateObj.effectId);
  if (stateObj.changeModuleIds.length > 0) {
    formData.append(
      "changeModuleIds",
      stateObj.changeModuleIds.map((item) => item.id || item.itemId)
    );
    formData.append(
      "changeContents",
      stateObj.changeModuleIds.map((item) => item.content)
    );
    formData.append(
      "changeFeatureIds",
      stateObj.changeModuleIds.map((item) => item.feature?.id)
    );
  }

  appendAttachmentFormData(formData, edit, coStore);

  return formData;
};

export const useGetcoData = (id, crstore, callBack) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/co/${id}`);
    const { data } = response.data.result;
    const { setStateObjWithData, setrouteNumber, setStateByKey } = crstore;
    // setstate
    setStateObjWithData(data);
    // custom
    setStateByKey("costDifferent")(data.costDifferent ? "1" : "0");
    setStateByKey("stageId")(data.coStageId);
    setStateByKey("effectId")((data.coEffectId || []).map((item) => item.id));
    setStateByKey("reasonId")(data.coReasonId);
    setStateByKey("clientPublishedPeriod")(data.publishPeriod);
    setStateByKey("clientReceivedPeriod")(data.receivedPeriod);
    setStateByKey("changeModuleIds")(data.affectedItemList || []);
    setStateByKey("changeRequestsId")(data.relatedCr || []);

    //  attachment
    setAttachmentArrays(data.attachments, crstore);

    setrouteNumber(data.routeId);
    if (callBack) {
      //  isRouteActivate, isReject...등등은 콜백으로 관리
      callBack(data);
    }
  };
};

export const crTopInputList = [
  {
    inputType: "DB",
    name: "CR Number",
    requestName: "number",
    option: 0,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 1,
  },
  {
    inputType: "div",
    name: txt.crco.buyerOrganization,
    requestName: "b",
  },
  {
    inputType: "DB",
    name: `CR ${txt.crco.reason}`,
    requestName: "reasonId",
    option: 0,
    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: txt.crco.reasonDetail,
    requestName: "reason",
    option: 0,
    choiceFields: [],
  },

  {
    inputType: "DROP_DOWN",
    name: txt.crco.crImportance,
    requestName: "importance",
    option: 0,
    choiceFields: [
      { value: "A", name: "A" },
      { value: "B", name: "B" },
      { value: "C", name: "C" },
    ],
    cell: 1,
  },
  {
    inputType: "DB",
    name: txt.crco.crSource,
    requestName: "sourceId",
    option: 0,
    cell: 1,
  },
];
export const coTopInputList = [
  {
    inputType: "DB",
    name: "CO Number",
    requestName: "number",
    option: 0,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 1,
  },
  {
    inputType: "div",
    name: " ",
    requestName: "b",
  },
  {
    inputType: "DB",
    name: txt.crco.buyerOrganizationId,
    requestName: "buyerOrganizationId",
    option: 0,
    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: txt.crco.clientItemNumber,
    requestName: "clientItemNumber",
    option: 1,
    choiceFields: [],
    cell: 1,
  },
  {
    inputType: "date",
    name: txt.crco.clientPublishedPeriod,
    requestName: "clientPublishedPeriod",
    option: 1,
    choiceFields: [],
    cell: 1,
  },
  {
    inputType: "date",
    name: txt.crco.clientReceivedPeriod,
    requestName: "clientReceivedPeriod",
    option: 1,
    choiceFields: [],
    cell: 1,
  },
];
export const CoBottomInputList = [
  {
    inputType: "DB",
    name: txt.crco.reasonId,
    requestName: "reasonId",
    option: 1,
    cell: 1,
  },
  {
    inputType: "div",
    name: "",
    requestName: "b",
  },
  {
    inputType: "DROP_DOWN",
    name: txt.crco.stageId,
    requestName: "stageId",
    option: 0,
    choiceFields: [
      { value: "1", name: "1" },
      { value: "2", name: "2" },
      { value: "3", name: "3" },
    ],
    cell: 1,
  },
  {
    inputType: "date",
    name: txt.crco.applyPeriod,
    requestName: "applyPeriod",
    option: 0,
    choiceFields: [],
    cell: 1,
  },
];
