import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useGetDataByPeriod } from "../utility";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import ViewSelectbox from "../molecule/ViewSelectbox";
import BarGraph from "../molecule/BarGraph";
import StatisticCircleBox from "../molecule/StatisticCircleBox";
import txt from "../../Text";

const GraphProductStyle = styled.div`
  padding: 8px 20px;
  height: 50vh;
`;
const DoughnutGraphContainerStyle = styled.div`
  display: flex;
  gap: 20px;
`;
export default function GraphProduct() {
  const tokenAxios = useTokenAxios();
  const TYPELIST = txt.dashBoard.productGraphTypeList;
  const [type, settype] = useState(TYPELIST[0]);
  const PRODUCTTYPELIST = ["ICU", "PDC", "PDCex", "PCB block"];

  // ---MODEL
  const [productTypeData, setproductTypeData] = useState({
    list: [
      {
        name: "ICU",
        val: {
          working: 0,
          complete: 0,
          release: 0,
        },
      },
      {
        name: "PDC",
        val: {
          working: 0,
          complete: 0,
          release: 0,
        },
      },
      {
        name: "PDCex",
        val: {
          working: 0,
          complete: 0,
          release: 0,
        },
      },
      {
        name: "PCBblock",
        val: {
          working: 0,
          complete: 0,
          release: 0,
        },
      },
    ],
  });
  const [month, setmonth, year, setyear, data] =
    useGetDataByPeriod(`home-product-date`);

  // ---BL
  const getproductTypeData = async () => {
    const response = await tokenAxios.get("home-product-type");
    setproductTypeData(response.data.result.data);
  };
  useEffect(() => {
    getproductTypeData();
  }, []);
  return (
    <GraphProductStyle>
      <ViewSelectbox state={type} setstate={settype} optionArr={TYPELIST} />
      {type === TYPELIST[0] && (
        <DoughnutGraphContainerStyle>
          {PRODUCTTYPELIST.map((str, i) => (
            <StatisticCircleBox
              isBadge
              title={`Summary  of ${str}`}
              centerText={`${str} Total`}
              dataObj={productTypeData.list[i].val}
              colorArr={[
                "rgba(253, 191, 77, 1)",
                "rgba(169, 205, 145, 1)",
                "rgba(10, 0, 85, 1)",
              ]}
            />
          ))}
        </DoughnutGraphContainerStyle>
      )}
      {type === TYPELIST[1] && (
        <BarGraph
          borderIndex={13 - month}
          data={data.list}
          colorArr={["rgba(42, 46, 128, 1)", "#5F7BE8", "#53AEEA", "#9EECB0"]}
          periodObj={{ month, year, setmonth, setyear }}
          isLegend
          title={`${txt.dashBoard.gun}${data.total} ${txt.dashBoard.TotalTitle}`}
        />
      )}
    </GraphProductStyle>
  );
}
