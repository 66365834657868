import {
  appendAttachmentFormData,
  appendStateObj,
  deleteFormdataKey,
  setAttachmentArrays,
} from "../utility/Utility";
import useTokenAxios from "../utility/hooks/useTokenAxios";

export const appendPBAForm = (edit, PBAStore) => {
  const { stateObj } = PBAStore;
  const formData = new FormData();
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, [
    //  custom
    "mpba",
    "classificationId",
    "pbaProduceOrganizationId",
  ]);
  formData.append("masterPbaId", stateObj.mpba.id);
  formData.append("classificationId", stateObj.classificationId.id);
  formData.append(
    "pbaProduceOrganizationId",
    stateObj.pbaProduceOrganizationId.map((item) => item.id)
  );
  appendAttachmentFormData(formData, edit, PBAStore);

  return formData;
};
export const useGetPBAData = (id, pbastore, callBack) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/pba-module/${id}`);
    const { data } = response.data.result;
    const {
      setStateObjWithData,
      deleteStateByKey,
      setStateByKey,
      setrouteNumber,
    } = pbastore;
    // setstate
    setStateObjWithData(data);
    // custom

    //  design attachment
    setAttachmentArrays(data.attachmentList, pbastore);

    setrouteNumber(data.routeId);
    if (callBack) {
      //  isRouteActivate, isReject...등등은 콜백으로 관리
      callBack(data);
    }
  };
};

export const useGetPBABomData = (id, store) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const { setpbaRowList } = store;
    const response = await tokenAxios.get(`/pba/bom/${id}`);
    const { data } = response.data.result;
    // setstate
    setpbaRowList(data?.length < 1 ? [1] : data);
  };
};

export const PBAInputList = [
  {
    inputType: "DB",
    name: "Item Classification",
    requestName: "classificationId",
    option: 1,
    choiceFields: [],
    gray: true,
    readOnly: true,

    cell: 2,
  },
  {
    inputType: "KEY_IN",
    name: "PBA Part Number",
    requestName: "partNumber",
    option: 0,
    choiceFields: [],

    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: "PBA Name",
    requestName: "name",
    option: 0,
    choiceFields: [],
    gray: false,

    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: "비고",
    requestName: "note",
    option: 1,
    choiceFields: [],
    gray: false,
    readOnly: false,
    cell: 2,
  },
];
