import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import Input from "../../atom/Input";
import { StripeBoxStyle } from "../../Style";
import ContextMenuTitle from "../../molecule/ContextMenuTitle";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import txt from "../../Text";

const ItemClassificationTreeStyle = styled.div`
  background-color: var(--lightGray);
  .top {
    display: flex;
  }
  .bottom {
    display: flex;
  }
  .leftLong {
    background-color: white;
    border-left: solid white calc(${(props) => props.boxWidth});
    flex-grow: 0;
    flex-shrink: 0;
  }
  .childContainer {
    border-left: 20px solid var(--lightGray);
  }
  .titleBox {
    border-top: solid 1px var(--textGray);
    background-color: white;
    width: calc(${(props) => props.boxWidth});
    height: calc(${(props) => props.boxHeight});
    display: flex;
    padding-left: 20px;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
  }
  .noTop {
    border-top: none;
  }
  .inputContainer {
    width: 90%;
    height: 70%;
    background-color: var(--lightGray);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .titleSt {
    font-size: 14px;
    font-weight: 600;
    color: var(--eciBlue);
  }
`;
const SpinButton = styled.div`
  transform: rotate(${(props) => (props.isFold ? "-90deg" : "0")});
  cursor: pointer;
  margin-right: 2px;
  font-size: 20px;
`;
export default function ItemClassificationTree({
  child,
  title,
  code,
  last,
  depth,
  id,
  parentId,
  classificationId,
  isNew,
  classification,
  data,
  parentData,
  isWriting,
  setisWriting,
  titleState,
  settitleState,
  titleCode,
  settitleCode,
  parentIdState,
  setparentIdState,
  boxWidth = "180px",
  boxHeight = "50px",
  fullLength = 5,

  appendRightArray,
  changeRightArray,
  deleteRightArray,
  treeId,

  disableContextMenu,
}) {
  const tokenAxios = useTokenAxios();

  const [componentTitle, setcomponentTitle] = useState(title);
  const [componentCode, setcomponentCode] = useState(code);
  const [isFold, setisFold] = useState(true);
  const [isTwoInput, setisTwoInput] = useState(false);
  const [isInput, setisInput] = useState(isNew);
  //    iswriting, child변화에 대응하기 위해 usestate, usememo를 함께 사용
  const [childArr, setchildArr] = useState(child);
  const childList = useMemo(() => {
    return (
      childArr &&
      childArr.map((object, i) => {
        if (object.id === "new") {
          return (
            <ItemClassificationTree
              key={`${object.id}${depth}`}
              depth={depth + 1}
              disableContextMenu={disableContextMenu}
              child={[]}
              last={last}
              boxWidth={boxWidth}
              boxHeight={boxHeight}
              fullLength={fullLength}
              id={id}
              parentId={id}
              classificationId={classificationId}
              classification={object.classification}
              data={object}
              parentData={data}
              isNew
              //
              isWriting={isWriting}
              setisWriting={setisWriting}
              titleState={titleState}
              settitleState={settitleState}
              titleCode={titleCode}
              settitleCode={settitleCode}
              parentIdState={parentIdState}
              setparentIdState={setparentIdState}
              appendRightArray={appendRightArray}
              changeRightArray={changeRightArray}
              deleteRightArray={deleteRightArray}
              treeId="new"
            />
          );
        }
        return (
          <ItemClassificationTree
            disableContextMenu={disableContextMenu}
            key={`${object.id}${depth}`}
            depth={depth + 1}
            title={object.name}
            code={object.code}
            child={object.children}
            last={object.last}
            boxWidth={boxWidth}
            boxHeight={boxHeight}
            fullLength={fullLength}
            id={object.id}
            parentId={id}
            classificationId={object.classificationId}
            classification={object.classification}
            data={object}
            parentData={data}
            //
            isWriting={!!isWriting}
            setisWriting={setisWriting}
            titleState={titleState}
            settitleState={settitleState}
            titleCode={titleCode}
            settitleCode={settitleCode}
            parentIdState={parentIdState}
            setparentIdState={setparentIdState}
            appendRightArray={appendRightArray}
            changeRightArray={changeRightArray}
            deleteRightArray={deleteRightArray}
            treeId={`${treeId},${i}`}
          />
        );
      })
    );
  }, [isWriting, childArr]);
  const [isNewBrother, setisNewBrother] = useState(false);
  const isCode = classification?.indexOf("3/7") > -1;
  //  handleFold
  const handleFold = () => {
    if (child) {
      if (isFold) {
        appendRightArray(
          treeId,
          ...child.map((item, i) => ({
            ...item,
            readOnly: true,
            buttonType: false,
            treeId: `${treeId},${i}`,
            depth,
          }))
        );
      } else {
        if (child && Array.isArray(child) && isWriting) {
          setisWriting(false);

          setchildArr(child.filter((obj) => obj.id !== "new"));
        }

        // 자손의 자손들까지....
        const countArr = [];
        const countChild = (chilld, treeid) => {
          chilld.forEach((object, i) => {
            countArr.push(`${treeid},${i}`);

            countChild(object.children, `${treeid},${i}`);
          });
        };
        countChild(child, treeId);
        deleteRightArray([...countArr, "new"]);
      }
    }
    setisFold(!isFold);
  };
  //  appendChild
  const appendChild = () => {
    if (!isWriting) {
      setchildArr(
        Array.isArray(child) ? [{ id: "new" }, ...child] : [{ id: "new" }]
      );
      setisWriting(true);
      setparentIdState(id);

      if (isFold) {
        handleFold();
      }
      appendRightArray(treeId, {
        ...data,
        readOnly: false,
        buttonType: "post",
        treeId: "new",
        depth: depth * 1 + 1,
      });
      //  data에서 버튼이 뜨게 만들어
    } else {
      alert("finish your last work");
    }
  };
  //    appendBrother
  const appendBrother = () => {
    if (!isWriting) {
      setisNewBrother(true);
      setisWriting(true);
      setparentIdState(parentId);
      appendRightArray(
        !isFold && child && child[0] ? child[child.length - 1].treeId : treeId,
        {
          ...parentData,
          readOnly: false,
          buttonType: "post",
          treeId: "new",
          depth,
        }
      );
      //  data에서 버튼이 뜨게 만들어
    } else {
      alert("finish your last work");
    }
  };
  //    editTitle
  const editTitle = () => {
    if (!isWriting) {
      setisWriting(true);
      setisInput(true);
      setparentIdState(parentId);
      setisTwoInput(true);
      settitleCode(componentCode);
      settitleState(componentTitle);
      //    right관리
      changeRightArray(treeId, "buttonType", "title");
      changeRightArray(treeId, "depth", depth);
      if (isCode) {
        changeRightArray(treeId, "thick", true);
      }
    } else {
      alert("finish your last work");
    }
  };
  const editProp = () => {
    if (!isWriting) {
      setisWriting(true);
      setparentIdState(parentId);

      //    right관리
      changeRightArray(treeId, "buttonType", "put");
      changeRightArray(treeId, "readOnly", false);
      changeRightArray(treeId, "depth", depth);
    } else {
      alert("finish your last work");
    }
  };

  //    sendDelete
  const sendDelete = async () => {
    const formData = new FormData();
    formData.append("stage", depth);
    const response = await tokenAxios.delete(
      `item-classification/${id}`,
      { data: formData }
      // formData
    );
    alert("done!");
    window.location.reload();
  };
  //    오른쪽 파란 줄무늬
  const RowBoxList =
    fullLength - depth > 0
      ? new Array(fullLength - depth).fill(1).map((item, i) => {
          return (
            <StripeBoxStyle
              top
              key={i + boxWidth + id + depth}
              width={boxWidth}
              height={boxHeight}
            />
          );
        })
      : "";
  const CodeRowBoxList =
    fullLength - depth > 0
      ? new Array(fullLength - depth).fill(1).map((item, i) => {
          return (
            <StripeBoxStyle
              key={i + boxWidth + id + depth}
              width={boxWidth}
              height={boxHeight}
            />
          );
        })
      : "";
  useEffect(() => {
    if (!isWriting) {
      setisInput(false);
      setisTwoInput(false);
      if (isInput) {
        setcomponentTitle(titleState);
      }
      if (isTwoInput) {
        setcomponentTitle(titleState);
        setcomponentCode(titleCode);
      }
    }
  }, [isWriting]);
  return [
    <ItemClassificationTreeStyle
      key={id}
      boxWidth={boxWidth}
      boxHeight={boxHeight}
      isFold={isFold}
      isLast={last}
    >
      <div className="top">
        <ContextMenuTitle
          title={
            <div className="titleBox">
              {isInput ? (
                <div className="inputContainer">
                  <Input
                    state={titleState}
                    setState={settitleState}
                    placeholder="Classification Name"
                    fontSize="12px"
                  />
                </div>
              ) : (
                <>
                  {!last && (
                    <SpinButton
                      className="material-icons"
                      onClick={handleFold}
                      isFold={isFold}
                    >
                      expand_more
                    </SpinButton>
                  )}
                  <div className="titleSt">
                    {componentTitle} {isCode && `(${componentCode})`}
                  </div>
                </>
              )}
            </div>
          }
          readOnly={depth < 3 || disableContextMenu}
        >
          <div onClick={appendBrother}>
            {depth}
            {txt.admin.addClassification}
          </div>
          {depth < 5 && (
            <div onClick={appendChild}>{txt.admin.addChildClassification}</div>
          )}
          {/* <div onClick={editProp}>{txt.admin.editProp}</div> */}
          <div onClick={editTitle}>{txt.admin.editTitle}</div>
          <div onClick={sendDelete}>{txt.admin.delete}</div>
        </ContextMenuTitle>

        {RowBoxList}
      </div>

      {/* child  */}
      {isTwoInput && isCode && (
        <div className="top">
          <div className="titleBox noTop">
            <div className="inputContainer">
              <Input
                state={titleCode}
                setState={settitleCode}
                placeholder="Code Number"
                fontSize="12px"
              />
            </div>
          </div>

          {CodeRowBoxList}
        </div>
      )}
      {!isFold && (
        <div className="bottom">
          <div className="leftLong" />
          <div className="childContainer">{childList}</div>
        </div>
      )}
    </ItemClassificationTreeStyle>,
    isNewBrother && (
      <ItemClassificationTree
        key={`${id}${depth}`}
        depth={depth}
        child={[]}
        boxWidth={boxWidth}
        boxHeight={boxHeight}
        fullLength={fullLength}
        id={id}
        parentId={parentId}
        classificationId={classificationId}
        parentData={data}
        isNew
        //
        isWriting={isWriting}
        setisWriting={setisWriting}
        titleState={titleState}
        settitleState={settitleState}
        titleCode={titleCode}
        settitleCode={settitleCode}
        parentIdState={parentIdState}
        setparentIdState={setparentIdState}
        appendRightArray={appendRightArray}
        changeRightArray={changeRightArray}
        deleteRightArray={deleteRightArray}
        treeId="new"
      />
    ),
  ];
}
