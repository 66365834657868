import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ViewSelectbox from "../molecule/ViewSelectbox";
import BarGraph from "../molecule/BarGraph";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import LineGraph from "../molecule/LineGraph";
import { useGetDataByPeriod } from "../utility";
import SelectBox from "../../atom/SelectBox";
import txt from "../../Text";

const GraphProjectStyle = styled.div`
  padding: 8px 20px;
  height: 50vh;
`;
export default function GraphProject() {
  const tokenAxios = useTokenAxios();
  const TYPELIST = txt.dashBoard.projectGraphTypeList;
  const STEPObj = {
    "ALL-Do": 1,
    Proto: 2,
    P1: 3,
    P2: 4,
    M: 5,
  };
  const [type, settype] = useState(TYPELIST[0]);
  const [step, setstep] = useState(1);
  const [stepData, setstepData] = useState({
    list: [
      {
        name: "err",
        val: {
          err: 1,
        },
      },
    ],
  });
  const [month, setmonth, year, setyear, data] = useGetDataByPeriod(
    "home-project-date",
    `/${step}`
  );
  const getStepData = async () => {
    const response = await tokenAxios.get("home-project-stage");
    setstepData(response.data.result.data);
  };
  useEffect(() => {
    getStepData();
  }, []);

  return (
    <GraphProjectStyle>
      <ViewSelectbox state={type} setstate={settype} optionArr={TYPELIST}>
        {type === TYPELIST[1] && (
          <SelectBox
            height="30px"
            setState={setstep}
            backgroundColor="white"
            color="var(--textDarkGray)"
            fontSize="13px"
            state={step}
            defaultValue={step}
          >
            {Object.keys(STEPObj).map((key, i) => (
              <option key={i} value={STEPObj[key]}>
                {key}
              </option>
            ))}
          </SelectBox>
        )}
      </ViewSelectbox>
      {type === TYPELIST[0] && (
        <BarGraph data={stepData.list} colorArr={["rgba(253, 191, 77, 1)"]} />
      )}
      {type === TYPELIST[1] && (
        <LineGraph
          data={data.list} // data수정
          colorArr={["rgba(253, 191, 77, 1)", "#9EECB0"]}
          periodObj={{ month, year, setmonth, setyear }}
        />
      )}
    </GraphProjectStyle>
  );
}
