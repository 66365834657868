import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import styled from "styled-components";
import TableRow from "../../atom/TableRow";
import SelectBox from "../../atom/SelectBox";
import {
  priceContractCondition,
  priceTypeList,
  priceUnitList,
} from "../../utility/Source";
import productStore from "../../store/productStore";
import Input from "../../atom/Input";
import { LightGrayInputContainer } from "../../Style";
import unitCostStore from "../../store/unitCostStore";

const UnitCostTreeStyle = styled.div`
  div {
    div {
      span {
        > .fold {
          font-size: 20px;
          transform: rotate(${(props) => (props.isFold ? "-90deg" : 0)});
          cursor: pointer;
        }
      }
    }
  }

  > .child {
    opacity: ${(props) => (props.isFold ? 0 : 1)};
    height: ${(props) => (props.isFold ? 0 : "max-content")};
  }
`;
const SubSumStyle = styled.div`
  width: 60px;
  height: 30px;
  border-radius: 100px;
  background-color: var(--eciBlue);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UnitInputConatainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

function getValueByKey(obj, key) {
  const lowerCaseKey = key.toLowerCase();
  return obj[lowerCaseKey];
}

const TYPE_ROWSTAT_OBJ = {
  // price: false,
  // priceUnit: false,
  // additionalPrice: false,
  // additionalPriceUnit: false,
  // approvedPrice: false,
  // approvedPriceUnit: false,
  // priceCondition: false,
  // totalPrice: 0,
  // totalApprovedPrice: 0,
  product: {
    totalApprovedPrice: "sumSubApproved",
  },
  board: {
    totalApprovedPrice: "sumSubApproved",
  },
  pba: {
    totalApprovedPrice: "sumSubApproved",
  },

  firmware: {
    totalApprovedPrice: "sumSubApproved",
  },
  pcb: {
    price: true,
    priceUnit: true,
    priceReadOnly: true,
    priceUnitReadOnly: true,
    priceCondition: true,
    additionalPrice: "0.08",
    additionalPriceUnit: "priceUnit",
    approvedPrice: true,
    approvedPriceUnit: true,
    approvedPriceReadOnly: true,
    approvedPriceUnitReadOnly: true,
  },
  item: {
    price: true,
    priceUnit: true,
    priceReadOnly: true,
    priceUnitReadOnly: true,
    priceCondition: true,
    additionalPrice: "0.08",
    additionalPriceUnit: "priceUnit",
    approvedPrice: true,
    approvedPriceUnit: true,
    approvedPriceReadOnly: true,
    approvedPriceUnitReadOnly: true,
  },
  component: {
    price: true,
    priceUnit: true,
    priceReadOnly: true,
    priceUnitReadOnly: true,
    priceCondition: true,
    additionalPrice: "0.08",
    additionalPriceUnit: "priceUnit",
    approvedPrice: true,
    approvedPriceUnit: true,
    approvedPriceReadOnly: true,
    approvedPriceUnitReadOnly: true,
  },
  subsidiary: {
    price: true,
    priceUnit: true,
    additionalPrice: true,
    additionalPriceUnit: true,
    approvedPrice: true,
    approvedPriceUnit: true,
  },
  consumable: {
    price: true,
    priceUnit: true,
    additionalPrice: true,
    additionalPriceUnit: true,
    approvedPrice: true,
    approvedPriceUnit: true,
  },
  mechanical: {
    price: true,
    priceUnit: true,
    additionalPrice: true,
    additionalPriceUnit: true,
    approvedPrice: true,
    approvedPriceUnit: true,
  },
};
export default function UnitCostTree({
  widthArr = [2, 1.5, 3, 5, 6, 6, 4, 3, 6, 3, 5, 4, 5, 4],
  setchildPriceObjP,
  data,
  parentId,
  level,
}) {
  const store = unitCostStore();

  const getWonPrice = (val, unit = "₩") => {
    let normalVal = val === "-" || val === "" || val === " " || !val ? 0 : val;
    if (typeof normalVal === "string") {
      normalVal = normalVal.replace(/,/g, "-");
    }
    return normalVal * (store.exchangeRateObj[unit] || 1) * 1;
  };
  const getWonApprovedPrice = useCallback(
    (val, unit = "₩") => {
      let normalVal =
        val === "-" || val === "" || val === " " || !val ? 0 : val;
      if (typeof normalVal === "string") {
        normalVal = normalVal.replace(/,/g, "");
      }
      return normalVal * 1 * (store.approvedExchangeRateObj[unit] || 1);
    },
    [store.carTypeId]
  );

  // 자식 가격 계산기
  const getChildPriceSum = () => {
    const childPriceWonSum = Object.values(childPriceObj).reduce((acc, obj) => {
      // 환율 계산 -> 원으로 통일
      // 엔 환율은 100엔 기준
      return (
        acc +
        (obj.price ? getWonPrice(obj.price, obj.priceUnit || "₩") : 0) +
        (obj.additionalPrice
          ? getWonPrice(obj.additionalPrice, obj.additionalPriceUnit || "₩")
          : 0)
      );
    }, 0);
    const childPriceSum = childPriceWonSum / store.exchangeRateObj[priceUnit];
    return childPriceSum;
  };
  const getChildApprovedPriceSum = () => {
    const childPriceWonSum = Object.values(childPriceObj).reduce((acc, obj) => {
      // 환율 계산 -> 원으로 통일
      // 엔 환율은 100엔 기준
      return (
        acc +
        (obj.approvedPrice
          ? getWonApprovedPrice(obj.approvedPrice, obj.approvedPriceUnit || "₩")
          : 0)
      );
    }, 0);
    const childPriceSum =
      childPriceWonSum / store.exchangeRateObj[approvedPriceUnit];
    return childPriceSum;
  };

  // ---------Business Logic
  // component state
  const [isFold, setisFold] = useState(true);
  const [childPriceObj, setchildPriceObj] = useState({}); // {id:{price:1200,unit:"$"}}
  // price state
  const [price, setprice] = useState(0);
  const [priceUnit, setpriceUnit] = useState("₩");
  const [priceType, setpriceType] = useState("");
  const [priceCondition, setpriceCondition] = useState("");
  const [additionalPrice, setadditionalPrice] = useState(0);
  const [additionalPriceUnit, setadditionalPriceUnit] = useState("₩");
  const [approvedPrice, setapprovedPrice] = useState(0);
  const [approvedPriceUnit, setapprovedPriceUnit] = useState("₩");
  const newSetPrice = (val) => {
    if (level > 2) {
      setchildPriceObjP((obj) => {
        const tmpObj = structuredClone(obj);
        if (!tmpObj[data.id]) {
          tmpObj[data.id] = {};
        }
        tmpObj[data.id].price = val;

        return tmpObj;
      });
    }
    setprice(val);
  };
  const newSetUnit = (val, priceChange = true) => {
    if (level > 2) {
      setchildPriceObjP((obj) => {
        const tmpObj = structuredClone(obj);
        if (!tmpObj[data.id]) {
          tmpObj[data.id] = {};
        }
        tmpObj[data.id].priceUnit = val;
        return tmpObj;
      });
    }
    if (priceChange) {
      newSetPrice(getWonPrice(price, priceUnit) / store.exchangeRateObj[val]);
    }
    setpriceUnit(val);
  };
  const newSetAdditionalPrice = (val) => {
    if (level > 2) {
      setchildPriceObjP((obj) => {
        const tmpObj = structuredClone(obj);
        if (!tmpObj[data.id]) {
          tmpObj[data.id] = {};
        }
        tmpObj[data.id].additionalPrice = val;
        return tmpObj;
      });
    }

    setadditionalPrice(val);
  };
  const newSetAdditionalPriceUnit = (val, priceChange = true) => {
    if (level > 2) {
      setchildPriceObjP((obj) => {
        const tmpObj = structuredClone(obj);
        if (!tmpObj[data.id]) {
          tmpObj[data.id] = {};
        }
        tmpObj[data.id].additionalPriceUnit = val;
        return tmpObj;
      });
    }
    if (priceChange) {
      newSetAdditionalPrice(
        getWonPrice(additionalPrice, additionalPriceUnit) /
          store.exchangeRateObj[val]
      );
    }

    setadditionalPriceUnit(val);
  };
  const newSetApprovedPrice = (val) => {
    if (level > 2) {
      setchildPriceObjP((obj) => {
        const tmpObj = structuredClone(obj);
        if (!tmpObj[data.id]) {
          tmpObj[data.id] = {};
        }
        tmpObj[data.id].approvedPrice = val;

        return tmpObj;
      });
    }

    setapprovedPrice(val);
  };
  const newSetApprovedPriceUnit = (val, priceChange = true) => {
    if (level > 2) {
      setchildPriceObjP((obj) => {
        const tmpObj = structuredClone(obj);
        if (!tmpObj[data.id]) {
          tmpObj[data.id] = {};
        }
        tmpObj[data.id].approvedPriceUnit = val;
        return tmpObj;
      });
    }
    if (priceChange) {
      newSetApprovedPrice(
        getWonApprovedPrice(approvedPrice, approvedPriceUnit) /
          store.approvedExchangeRateObj[val]
      );
    }

    setapprovedPriceUnit(val);
  };
  const setStateObj = {
    price: newSetPrice,
    priceUnit: newSetUnit,
    priceType: setpriceType,
    priceCondition: setpriceCondition,
    additionalPrice: newSetAdditionalPrice,
    additionalPriceUnit: newSetAdditionalPriceUnit,
    approvedPrice: newSetApprovedPrice,
    approvedPriceUnit: newSetApprovedPriceUnit,
  };
  // state변화시 store의 변화obj에 추가
  const stateChangeListener = (state) => {
    return (val) => {
      setStateObj[state](val);
      if (data.id && val && store.changedPriceObj[data.id][state] !== val) {
        store.editChangedPriceObj({
          id: data.id,
          parentId,
          price: state === "price" ? val : price,
          priceUnit: state === "priceUnit" ? val : priceUnit,
          priceCondition: state === "priceCondition" ? val : priceCondition,
          priceType: state === "priceType" ? val : priceType,
          additionalPrice: state === "additionalPrice" ? val : additionalPrice,
          additionalPriceUnit:
            state === "additionalPriceUnit" ? val : additionalPriceUnit,
          approvedPrice: state === "approvedPrice" ? val : approvedPrice,
          approvedPriceUnit:
            state === "approvedPriceUnit" ? val : approvedPriceUnit,
        });
      }
    };
  };
  // -----------VIEW
  const childRowArray = (
    data?.children || [
      ...(data?.boardChildren || []),
      ...(data?.itemChildren || []),
    ]
  )?.map((item, i) => (
    <UnitCostTree
      key={`${item.id} ${i} ${parentId}`}
      widthArr={widthArr}
      setchildPriceObjP={setchildPriceObj}
      data={item}
      parentId={data.id}
      level={level + 1}
    />
  ));
  const totalPrice = (
    getWonPrice(price || 0, priceUnit) +
    getWonPrice(additionalPrice || 0, additionalPriceUnit)
  ).toFixed(2);
  const totalApprovedPrice = getWonApprovedPrice(
    approvedPrice || 0,
    approvedPriceUnit
  ).toFixed(2);
  // 하위 변화 감지
  useEffect(() => {
    const childPriceSum = getChildPriceSum();
    setprice(childPriceSum);
    stateChangeListener("price")(childPriceSum);
    const childApprovedPriceSum = getChildApprovedPriceSum();
    setapprovedPrice(childApprovedPriceSum);
    stateChangeListener("approvedPrice")(childApprovedPriceSum);
  }, [childPriceObj, store.carTypeId]);

  //  totalPrice 세팅
  useEffect(() => {
    if (level === 2) {
      store.changeSecondPriceList(
        data.id,
        getWonPrice(price || 0, priceUnit) +
          getWonPrice(additionalPrice || 0, additionalPriceUnit)
      );

      store.changeSecondApprovedPriceList(
        data.id,
        getWonApprovedPrice(approvedPrice || 0, approvedPriceUnit)
      );
    }
  }, [
    price,
    priceUnit,
    additionalPrice,
    additionalPriceUnit,
    approvedPrice,
    approvedPriceUnit,
  ]);

  // price관련 값들 init
  useEffect(() => {
    // 기본 state init
    const dataPrice = getChildPriceSum();
    const dataApprovedPrice = getChildApprovedPriceSum();
    const target = store.changedPriceObj[data.id];
    newSetUnit(target?.priceUnit || "₩");
    newSetPrice(target?.price || dataPrice);

    newSetApprovedPrice(target?.approvedPrice || dataApprovedPrice);
    newSetApprovedPriceUnit(target?.approvedPriceUnit || "₩", false);

    if (
      getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.additionalPrice === "0.08"
    ) {
      newSetAdditionalPriceUnit(priceUnit, false);
      if (priceCondition === "FCA" || priceCondition === "FDA") {
        newSetAdditionalPrice(0.08 * (price * 1 || 0));
      }

      if (priceCondition === "DDP") {
        newSetAdditionalPrice(0);
      }
    } else {
      newSetAdditionalPrice(target?.additionalPrice || 0);
      newSetAdditionalPriceUnit(target?.additionalPriceUnit || "₩", false);
    }

    setpriceType(target?.priceType || "");
    setpriceCondition(target?.priceCondition || "");
  }, [store.isChangedPriceObjUpdated, store.carTypeId]);
  // 부대경비 처리
  useEffect(() => {
    if (
      getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.additionalPrice === "0.08"
    ) {
      newSetAdditionalPriceUnit(priceUnit, false);
      if (priceCondition === "FCA" || priceCondition === "FDA") {
        newSetAdditionalPrice(0.08 * (price * 1 || 0));
      }

      if (priceCondition === "DDP") {
        newSetAdditionalPrice(0);
      }
    }
  }, [priceCondition]);
  // excel data생성
  useLayoutEffect(() => {
    store.changeExcelDataObj({
      id: data.id,
      level,
      type: data.type,
      itemNumber: data.number || data.cardNumber,
      specification: data.specification,
      buyerOrganization: data.buyerOrganizationId,
      priceType,
      price: `${price} ${priceUnit}`,
      priceCondition,
      additionalPrice: `${additionalPrice} ${additionalPriceUnit}`,
      totalPrice,
      approvedPrice: `${approvedPrice} ${approvedPriceUnit}`,
      totalApprovedPrice,
    });
  }, [store.changedPriceObj]);
  return (
    <UnitCostTreeStyle isFold={isFold}>
      <TableRow
        // backgroundColor={
        //   store.changedPriceObj[data?.id] && "rgba(253, 191, 77, 0.3)"
        // }
        widthArray={widthArr}
        itemArray={[
          data.children && data.children[0] ? (
            <span
              className="material-icons fold"
              onClick={() => setisFold((bool) => !bool)}
            >
              arrow_drop_down
            </span>
          ) : (
            ""
          ),
          level,
          data.type,
          data.number || data.cardNumber,
          data.name || data.cardName,
          data.specification,
          data.buyerOrganizationId || "-",
          <SelectBox
            height="30px"
            backgroundColor="var(--lightGray)"
            cssStyle="border-radius:8px"
            state={priceType || "-"}
            setState={stateChangeListener("priceType")}
            readOnly
          >
            {priceType || <option value="-">-</option>}
            {priceTypeList?.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </SelectBox>,
          <UnitInputConatainer>
            <LightGrayInputContainer height="30px">
              <Input
                width="100%"
                state={price || "-"}
                setState={stateChangeListener("price")}
                readOnly={
                  !getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.price ||
                  !!getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.priceReadOnly
                }
              />
            </LightGrayInputContainer>
            <SelectBox
              width="50%"
              height="30px"
              backgroundColor="var(--lightGray)"
              cssStyle="border-radius:8px"
              state={priceUnit || "₩"}
              setState={stateChangeListener("priceUnit")}
              readOnly={
                !getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.priceUnit ||
                !!getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.priceUnitReadOnly
              }
            >
              {priceUnitList.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </SelectBox>
            ,
          </UnitInputConatainer>,
          <SelectBox
            height="30px"
            backgroundColor="var(--lightGray)"
            cssStyle="border-radius:8px"
            state={priceCondition ? priceCondition || "FDA" : "-"}
            setState={stateChangeListener("priceCondition")}
            readOnly={
              !getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.priceCondition
            }
          >
            {[
              ...priceContractCondition.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              )),
              <option key={5} value="-" hidden>
                -
              </option>,
            ]}
          </SelectBox>,
          <UnitInputConatainer>
            <LightGrayInputContainer height="30px">
              {/* <Input
                width="100%"
                state={
                  getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.additionalPrice
                    ? getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.additionalPrice === "0.08"
                      ? priceCondition === "FCA"
                        ? `${0.08 * (price || 0)}`
                        : "0"
                      : additionalPrice
                    : "-"
                }
                setState={stateChangeListener("additionalPrice")}
                readOnly={
                  !getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.additionalPrice ||
                  !!(getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.additionalPrice === "0.08")
                }
              /> */}
              <Input
                width="100%"
                state={additionalPrice}
                setState={stateChangeListener("additionalPrice")}
                readOnly={
                  !getValueByKey(TYPE_ROWSTAT_OBJ, data.type)
                    ?.additionalPrice ||
                  getValueByKey(TYPE_ROWSTAT_OBJ, data.type)
                    ?.additionalPrice === "0.08"
                }
              />
            </LightGrayInputContainer>

            <SelectBox
              width="50%"
              height="30px"
              backgroundColor="var(--lightGray)"
              cssStyle="border-radius:8px"
              state={
                getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.additionalPriceUnit
                  ? additionalPriceUnit
                  : "₩"
              }
              setState={stateChangeListener("additionalPriceUnit")}
              readOnly={
                !getValueByKey(TYPE_ROWSTAT_OBJ, data.type)
                  ?.additionalPriceUnit ||
                !!(
                  getValueByKey(TYPE_ROWSTAT_OBJ, data.type)
                    ?.additionalPrice === "0.08"
                )
              }
            >
              {priceUnitList.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </SelectBox>
          </UnitInputConatainer>,

          <SubSumStyle>{totalPrice}</SubSumStyle>,
          // 인정가
          <UnitInputConatainer>
            <LightGrayInputContainer height="30px">
              <Input
                width="100%"
                state={approvedPrice || "-"}
                setState={stateChangeListener("approvedPrice")}
                readOnly={
                  !getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.approvedPrice ||
                  getValueByKey(TYPE_ROWSTAT_OBJ, data.type)
                    ?.approvedPriceReadOnly
                }
              />
            </LightGrayInputContainer>

            <SelectBox
              width="50%"
              height="30px"
              backgroundColor="var(--lightGray)"
              cssStyle="border-radius:8px"
              state={
                getValueByKey(TYPE_ROWSTAT_OBJ, data.type)?.approvedPriceUnit
                  ? approvedPriceUnit
                  : "₩"
              }
              setState={stateChangeListener("approvedPriceUnit")}
              readOnly={
                !getValueByKey(TYPE_ROWSTAT_OBJ, data.type)
                  ?.approvedPriceUnit ||
                getValueByKey(TYPE_ROWSTAT_OBJ, data.type)
                  ?.approvedPriceUnitReadOnly
              }
            >
              {priceUnitList.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </SelectBox>
          </UnitInputConatainer>,

          <SubSumStyle>{totalApprovedPrice}</SubSumStyle>,
        ]}
      />
      <div className="child">{childRowArray}</div>
    </UnitCostTreeStyle>
  );
}
