import React, { useEffect } from "react";
import styled from "styled-components";
import Input from "../atom/Input";
import TableIndexRow from "../atom/TableIndexRow";
import TableRow from "../atom/TableRow";
import { LightGrayInputContainer } from "../Style";

import { TableStyle } from "./Table";

const TableInputStyle = styled(TableStyle)`
  .add {
    color: var(--textDarkGray);
    font-size: 14px;
    cursor: pointer;
    transform: translate(-0, 5px);
  }
  .delete {
    color: var(--textGray);
    font-size: 20px;
    cursor: pointer;
    transform: translate(-5px, 7px);
  }
  overflow-x: hidden;
`;

export default function TableInput({
  stateArr,
  setstateArr,
  indexArr,
  deleteFunc,
  height = "100%",
}) {
  const rowList =
    stateArr &&
    stateArr.map((item, i) => {
      // 체크
      if (item.id === "") {
        return (
          <TableRow
            key={stateArr.length}
            widthArray={[1, 4, 6, 1]}
            itemArray={[
              "",
              <LightGrayInputContainer>
                <Input
                  height="30px"
                  state={
                    stateArr[stateArr.length - 1] &&
                    stateArr[stateArr.length - 1].code
                  }
                  placeholder="type here"
                  setState={(val) => {
                    const tmpArr = [...stateArr];
                    tmpArr[stateArr.length - 1].code = val;
                    setstateArr(tmpArr);
                  }}
                  fontSize="10px"
                  width="100%"
                />
              </LightGrayInputContainer>,
              <LightGrayInputContainer>
                <Input
                  height="30px"
                  state={
                    stateArr[stateArr.length - 1] &&
                    stateArr[stateArr.length - 1].name
                  }
                  placeholder="type here"
                  setState={(val) => {
                    const tmpArr = [...stateArr];
                    tmpArr[stateArr.length - 1].name = val;
                    setstateArr(tmpArr);
                  }}
                  fontSize="10px"
                  width="100%"
                />
              </LightGrayInputContainer>,
            ]}
          />
        );
      }
      return (
        <TableRow
          key={i}
          widthArray={[1, 4, 6, 1]}
          itemArray={[
            // i === stateArr.length - 1 ? (
            //   <div
            //     className="material-icons add"
            //     onClick={() => {
            //       const tmpArr = [...stateArr];
            //       tmpArr.push({ code: "", name: "" });
            //       setstateArr(tmpArr);
            //     }}
            //   >
            //     add
            //   </div>
            // ) : (
            //   ""
            // ),
            "",
            item.code || item.code2 || item.code1,
            item.name || item.name1 || item.name2,
            // item.isOk && (
            <div
              className="material-icons delete"
              onClick={
                //   () => {
                //   const tmparr = [...stateArr];
                //   tmparr.splice(i, 1);
                //   setstateArr(tmparr);
                // }
                () => {
                  deleteFunc(item);
                }
              }
            >
              delete
            </div>,
            // ),
          ]}
        />
      );
    });

  useEffect(() => {
    if (!stateArr[0] || stateArr[stateArr.length - 1].id !== "") {
      setstateArr((arr) => [...arr, { id: "", code: "", name: "" }]);
    }
  }, [stateArr]);
  return (
    <TableInputStyle height="100%" noshadow width="100%">
      <TableIndexRow
        widthArray={[1, 4, 7]}
        itemArray={["", ...indexArr]}
        fontSize="12px"
      />
      <div className="scrollYBox">{rowList}</div>
    </TableInputStyle>
  );
}
