import styled from "styled-components";
import { ScrollDefault } from "../../Style";

export const ModalStyle = styled.div`
  display: flex;
  width: 80vw;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 1px solid var(--deemGray);
    width: 5px;
    background: white;
    border-radius: 8px;
  }
  .gap {
    width: 40px;
    flex-shrink: 0;
  }

  .treeScrollContainer {
    border-radius: 12px 12px 0 0;
    padding-top: 40px;
    background-color: white;
    height: calc(100% - 59px);
    ${ScrollDefault}
  }
`;

export const PropModalStyle = styled.div`
  min-width: 300px;
  height: calc(${(props) => (props.height ? props.height : "50vh + 30px")});
  background-color: var(--eciBlue);
  border-radius: 10px;
  position: relative;
  width: calc(${(props) => (props.width ? props.width : "40%")});
  flex-shrink: 0;
  > .title {
    color: white;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
    font-weight: 600;
    height: 10%;
    min-height: 40px;
    line-height: 40px;
    padding-left: 16px;
    border-radius: 10px 10px 0 0;
    background-color: var(--eciBlue);
  }

  > .modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    height: 81%;
    border-radius: 8px;
    padding: 16px;
    overflow-y: scroll;
    ${ScrollDefault}
  }
`;

export const ButtonPart = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-top: solid var(--textGray) 1px;
  height: 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  .actionButton {
    cursor: pointer;
    padding: 4px 20px;
    font-weight: 600;
    color: var(--textDarkGray);
  }
  .cancel {
    border-right: 3px solid var(--textGray);
  }
  .apply {
    color: var(--eciBlue);
  }
`;
