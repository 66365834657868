import React from "react";
import { useNavigate } from "react-router-dom";

import { PageStyle, ListPageStyle, BlueButtonStyle } from "../../Style";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";

import PaginationTable from "../../molecule/PaginationTable";
import SearchBar from "../../molecule/SearchBar";
import usePageConditionList from "../../utility/hooks/usePageConditionList";
import txt from "../../Text";

export default function CrListPage() {
  const navigate = useNavigate();
  const [pageCondition, setpageCondition, coListData] = usePageConditionList(
    "co",
    "searchText"
  );
  const coRowList =
    coListData &&
    coListData.map((coObj, i) => {
      const itemArr = coObj.items;

      return (
        <>
          <TableRow
            width="1400px"
            key={i}
            onClick={() => {
              navigate(`page/detail/${coObj.crId}`);
            }}
            itemArray={[
              "",
              coObj.crNumber,
              coObj.crName,
              coObj.member?.username,
              coObj.itemNumber,
              coObj.itemName,
              coObj.itemClassification,
              coObj.itemRevision,
              coObj.itemStatus,
            ]}
            widthArray={[2, 6, 8, 4, 5, 7, 7, 5, 5]}
          />
          {itemArr.map((itemObj, j) => (
            <TableRow
              width="1400px"
              key={itemObj.itemId}
              onClick={() => {
                navigate(`page/detail/${coObj.crId}`);
              }}
              itemArray={[
                "",
                itemObj.crNumber,
                itemObj.crName,
                itemObj.member?.username,
                itemObj.itemNumber,
                itemObj.itemName,
                itemObj.itemClassification,
                itemObj.itemRevision,
                itemObj.itemStatus,
              ]}
              widthArray={[2, 6, 8, 4, 5, 7, 7, 5, 5]}
            />
          ))}
        </>
      );
    });

  return (
    <PageStyle>
      <ListPageStyle>
        <div className="searchSection">
          <SearchBar
            width="400px"
            height="30px"
            placeholder="Search Items"
            state={pageCondition.searchText}
            setState={(val) => {
              setpageCondition("searchText", val);
            }}
          />
          <BlueButtonStyle
            height="max-content"
            width="200px"
            css="padding:5px 10px"
            onClick={() => {
              navigate("page/add/new");
            }}
          >
            <span className="material-icons">add</span>
            Create New CO
          </BlueButtonStyle>
        </div>
        <PaginationTable
          width="100%"
          minHeight="300px"
          height="60vh"
          innerWidth="1400px"
          totalPage={pageCondition.totalPage}
          rowsPerPageDefault={10}
          pageNum={pageCondition.pageNum}
          setRowsPerPage={(val) => {
            setpageCondition("rowsPerPage", val);
          }}
          rowsPerPage={pageCondition.rowsPerPage}
          setpageNum={(val) => {
            setpageCondition("pageNum", val);
          }}
          noshadow
        >
          <>
            <TableIndexRow
              itemArray={["", "CO Info", "CO Item"]}
              widthArray={[2, 18, 29]}
              width="1400px"
            />
            <TableIndexRow
              itemArray={txt.crco.coListPageIndex}
              widthArray={[2, 6, 8, 4, 5, 7, 7, 5, 5]}
              width="1400px"
            />
          </>

          {coRowList}
        </PaginationTable>
      </ListPageStyle>
    </PageStyle>
  );
}
