import React from "react";
import styled, { css } from "styled-components";

const TableRowStyle = styled.div`
  min-width: ${(props) => (props.width ? props.width : "")};
  height: ${(props) => props.height};
  padding: 0 5px;
  border-bottom: ${(props) =>
    props.noBorder ? "" : "solid var(--textGray) 1px"};

  display: grid;
  grid-template-columns: ${(props) => props.widthRatio};
  grid-template-rows: 1;
  align-content: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ""};
  font-size: ${(props) => props.fontSize || "13px"};
  font-family: "Noto Sans KR", sans-serif;
  color: var(--deemGray);
  cursor: ${(props) => (props.onClick ? "pointer" : "")};
  .gridItsm {
    width: 100%;
    padding: 0 3px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  .gridItem {
    width: 100%;
    padding: 0 3px;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-decoration: none;
    line-height: 40px;
    font-size: ${(props) => props.fontSize};
  }
  ${(props) => props.cssStyle}
`;
export default function TableRow({
  widthArray = [],
  itemArray = [],
  noBorder,
  onClick,
  width,
  backgroundColor,
  fontSize,
  cssStyle,
  height = "40px",
}) {
  const row = itemArray.map((item, i) =>
    widthArray[i] > 0 ? (
      <div key={i} className="gridItsm">
        <span
          className="gridItem"
          title={
            item && (typeof item === "string" || typeof item === "number")
              ? item.toString()
              : " "
          }
        >
          {item}
        </span>
      </div>
    ) : (
      <div key={i} />
    )
  );
  const widthRatio = widthArray.map((num) => `${num}fr `);

  return (
    <TableRowStyle
      cssStyle={cssStyle}
      fontSize={fontSize}
      length={widthArray.length}
      noBorder={noBorder}
      onClick={onClick}
      widthRatio={widthRatio}
      width={width}
      backgroundColor={backgroundColor}
      height={height}
      className="tableRow"
    >
      {row}
    </TableRowStyle>
  );
}
