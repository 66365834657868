import React, { useState } from "react";
import styled from "styled-components";
import { NavLink, Outlet } from "react-router-dom";
import ModuleTopNavigation from "../molecule/ModuleTopNavigation";
import { ModuleStyle } from "../Style";

import { adminModuleNavigationListObj } from "../store/adminStore";

const AdminModuleNavigationPageStyle = styled.div`
  display: flex;
  .navBar {
    width: 10%;
    padding-top: 30px;
    > * {
      display: block;
    }
  }
`;
export default function AdminModule() {
  const [navigationType, setnavigationType] = useState("members");
  const navigationList = adminModuleNavigationListObj[navigationType].map(
    (item, i) => (
      <NavLink
        key={i}
        to={item.to}
        style={({ isActive }) => ({
          textDecoration: "none",
          height: "40px",
          lineHeight: "32px",
          fontSize: "13px",
          fontWeight: "600",
          paddingLeft: "8px",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          color: isActive ? "var(--eciBlue)" : "var(--textDarkGray)",
        })}
      >
        {item.name}
      </NavLink>
    )
  );
  return (
    <ModuleStyle>
      <ModuleTopNavigation
        title="Admin"
        navList={[
          {
            name: "Member",
            to: "/admin/member",
            onClick: () => setnavigationType("members"),
          },
          {
            name: "Item",
            to: "/admin/item",
            onClick: () => setnavigationType("item"),
          },
          {
            name: "CAD Library",
            to: "/admin/library",
            onClick: () => setnavigationType("library"),
          },
        ]}
      />
      <AdminModuleNavigationPageStyle>
        <div className="navBar">{navigationList}</div>
        <Outlet />
      </AdminModuleNavigationPageStyle>
    </ModuleStyle>
  );
}
