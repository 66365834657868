import { uriByClassificationObj } from "../utility/Source";
import {
  appendAttachmentFormData,
  appendStateObj,
  deleteFormdataKey,
  setAttachmentArrays,
} from "../utility/Utility";
import useTokenAxios from "../utility/hooks/useTokenAxios";
import txt from "../Text";

//  electronicpart(itemcomponent)
export const appendElectronicPartForm = (edit, electronicPartStore) => {
  const formData = new FormData();
  const { stateObj } = electronicPartStore;
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, [
    "property",
    "classificationId",
    "buyerOrganizationId",
    "produceOrganizationId",
    "itemNumber",
    "whereUsed",
    //  library

    "symbol",
    "footPrint",
  ]);
  formData.append(
    "classificationId",
    stateObj.classificationId.id || stateObj.classificationId.classId
  );
  if (stateObj.buyerOrganizationId && stateObj.buyerOrganizationId?.id > -1) {
    formData.append("buyerOrganizationId", stateObj.buyerOrganizationId.id);
  }
  formData.append(
    "produceOrganizationId",
    stateObj.produceOrganizationId.map((item) => item.id)
  );
  formData.append(
    "partNumber",
    stateObj.produceOrganizationId.map((item) => item.partNumber)
  );

  formData.append("property", JSON.stringify(stateObj.property));
  appendAttachmentFormData(formData, edit, electronicPartStore);
  if (electronicPartStore.isMech) {
    formData.append("itemNumber", stateObj.itemNumber);
  }
  return formData;
};
export const useGetElectronicPartData = (id, electronicPartstore, callBack) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/item/${id}`);
    const { data } = response.data.result;
    const {
      setStateObjWithData,
      deleteStateByKey,
      setStateByKey,
      setrouteNumber,
    } = electronicPartstore;
    // setstate

    //  design attachment
    setAttachmentArrays(data.attachmentList, electronicPartstore);
    setrouteNumber(data.routeId);
    setStateObjWithData(data);
    if (!data.produceOrganizationId[0]) {
      setStateByKey("produceOrganizationId")([{ id: "", name: "" }]);
    }
    [
      // custom
      "property",
    ].forEach((key) => {
      deleteStateByKey(key);
    });
    const jsonData = data.property !== " " ? JSON.parse(data.property) : {};
    //  임시 -> 정규 전환
    if (data.switching) {
      electronicPartstore.setisSwitchable(true);
    }
    // testpoint인 경우 하드코딩
    if (data.classificationId.classificationId === "3/13/2354") {
      electronicPartstore.setisTestPoint(true);
    }
    if (data.classificationId.value?.indexOf("Mechanical Part") > -1) {
      electronicPartstore.setisMech(true);
    }
    if (data.newNumber && data.newNumber !== " ") {
      setStateByKey("itemNumber")(data.newNumber);
    }
    if (!jsonData[0]) {
      callBack(data);
    } else {
      setStateByKey("property")(jsonData);
    }
  };
};
//  library
export const appendLibraryForm = (edit, electronicPartStore) => {
  const formData = new FormData();
  const { stateObj } = electronicPartStore;
  appendStateObj(formData, stateObj);
  deleteFormdataKey(formData, [
    "id",
    "property",
    "classificationId",
    "buyerOrganizationId",
    "produceOrganizationId",
    "partNumber",
    "type",
    "number",
    "specification",
    "moq",
    "leadTime",
    "grade",
    "price",
    "whereUsed",

    //  library

    "symbol",
    "footPrint",
  ]);

  formData.append("itemId", electronicPartStore.id);
  formData.append(
    "symbolId",
    stateObj.symbol[0]?.id || stateObj.symbol?.id || ""
  );
  formData.append(
    "footPrintId",
    stateObj.footPrint.map((item) => item.id)
  );

  return formData;
};
export const useGetLibraryData = (id, electronicPartstore) => {
  const tokenAxios = useTokenAxios();

  return async () => {
    const response = await tokenAxios.get(`/item/${id}`);
    const { data } = response.data.result;
    const {
      setStateObjWithData,
      deleteStateByKey,
      setStateByKey,
      setrouteNumber,
      setlibrary,
    } = electronicPartstore;
    setStateObjWithData(data.library);
    setStateByKey("symbol")(data.library?.symbol ? [data.library?.symbol] : []);
    setlibrary(data.library);
  };
};

export const electronicPartTopInputList = (
  isTmpNum,
  isSwitching,
  isTestPoint,
  isMech
) => [
  !(isTestPoint || isMech) && {
    inputType: "DROP_DOWN",
    name: txt.elec.tempFormal, // classification:testPoint면 숨기기
    requestName: "type",
    option: 0,
    choiceFields: [
      { value: "1", name: txt.elec.formal },
      { value: "0", name: txt.elec.temp },
    ],
    cell: 1,
  },
  {
    inputType: "DB", //  체크
    name: "Item Classification",
    requestName: "classificationId",
    option: 0,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 2,
    title: "Array Size",
  },
  isTmpNum && {
    inputType: "KEY_IN",
    name: "Item Name",
    requestName: "name",
    option: 0,
    choiceFields: [],
    cell: 1,
  },
  isTmpNum && { inputType: "div", cell: 1 },
  {
    inputType: "KEY_IN",
    name: `${isTmpNum ? "Temp " : ""}Item Number`,
    requestName: isTmpNum ? "tmpNumber" : "itemNumber",
    option: 0,
    choiceFields: [],
    gray: !isMech,
    readOnly: !isMech,
    cell: 1,
  },
  isTmpNum
    ? {
        inputType: "KEY_IN",
        name: "Regular Item Number",
        requestName: "itemNumber",
        option: 0,
        choiceFields: [],
        gray: true,
        backgroundColor: isSwitching && "rgba(253, 191, 77, 0.3)",
        readOnly: true,
        cell: 1,
      }
    : {
        inputType: "KEY_IN",
        name: "Item Name",
        requestName: "name",
        option: 0,
        choiceFields: [],
        cell: 1,
      },
  isTestPoint && {
    inputType: "UNIT_KEY_IN",
    name: "Diameter",
    requestName: "diameter",
    option: 1,
    choiceFields: [{ value: "mm", name: "mm" }],

    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: txt.elec.note,
    requestName: "note",
    option: 1,
    choiceFields: [],
    gray: false,
    readOnly: false,
    cell: 2,
  },
  {
    inputType: "KEY_IN",
    name: txt.elec.detail,
    requestName: "specification",
    option: 1,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 2,
  },
];
export const electronicPartTopInputNoRouteList = [
  {
    inputType: "DROP_DOWN",
    name: txt.elec.tempFormal,
    requestName: "type",
    option: 0,
    choiceFields: [
      { value: "1", name: txt.elec.formal },
      { value: "0", name: txt.elec.temp },
    ],
    cell: 1,
    readOnly: true,
    gray: true,
  },
  {
    inputType: "DB", //  체크
    name: "Item Classification",
    requestName: "classificationId",
    option: 0,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 2,
    title: "Array Size",
  },
  {
    inputType: "KEY_IN",
    name: "Item Number",
    requestName: "itemNumber",
    option: 0,
    choiceFields: [],
    cell: 1,
    gray: true,
    readOnly: true,
  },
  {
    inputType: "KEY_IN",
    name: "Item Name",
    requestName: "name",
    option: 0,
    choiceFields: [],
    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: txt.elec.detail,
    requestName: "specification",
    option: 1,
    choiceFields: [],
    gray: true,
    readOnly: true,
    cell: 2,
  },
];
export const electronicPartBottomInputList = [
  {
    inputType: "DB",
    name: "구매처",
    requestName: "buyerOrganizationId",
    option: 1,
    choiceFields: [],
    gray: false,
    cell: 1,
  },
  {
    inputType: "KEY_IN",
    name: "MOQ",
    requestName: "moq",
    option: 1,
    choiceFields: [{ value: "W", name: "W" }],
    cell: 1,
  },
  {
    inputType: "date", //  체크
    name: "Lead Time",
    requestName: "leadTime",
    option: 1,
    choiceFields: [],
    cell: 1,
    title: "Array Size",
  },
  {
    inputType: "DROP_DOWN",
    name: txt.elec.level,
    requestName: "grade",
    option: 1,
    choiceFields: [
      { value: "1", name: txt.elec.extinct },
      { value: "2", name: txt.elec.extinctExpected },
      { value: "3", name: txt.elec.changing },
    ],
    cell: 1,
  },
  {
    inputType: "UNIT_KEY_IN",
    name: txt.elec.truePrice,
    requestName: "price",
    option: 1,
    choiceFields: [
      { value: "₩", name: "₩" },
      { value: "￥", name: "￥" },
      { value: "$", name: "$" },
    ],

    cell: 1,
  },
  {
    inputType: "UNIT_KEY_IN",
    name: txt.elec.tentativePrice,
    requestName: "tentativePrice",
    option: 1,
    choiceFields: [
      { value: "₩", name: "₩" },
      { value: "￥", name: "￥" },
      { value: "$", name: "$" },
    ],

    cell: 1,
  },
  {
    inputType: "UNIT_KEY_IN",
    name: txt.elec.approvedPrice,
    requestName: "approvedPrice",
    option: 1,
    choiceFields: [
      { value: "₩", name: "₩" },
      { value: "￥", name: "￥" },
      { value: "$", name: "$" },
    ],

    cell: 1,
  },
];
