import React from "react";
import styled from "styled-components";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";

const CompareTableStyle = styled.div`
  border: solid 1px var(--eciBlue);
  background-color: white;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  .leftPart {
    width: 60%;
  }
  .rightPart {
    width: 40%;
    padding-top: 10px;
  }
  .BlueBoxContainer {
    display: flex;
    .BlueBox {
      height: 40px;
      line-height: 40px;
      width: 100%;
      border-top: solid 1px white;
      background-color: var(--eciBlue);
      text-align: center;
      color: white;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;
export default function CompareTable({ compareData, name, compareName }) {
  return (
    <CompareTableStyle>
      <div className="leftPart">
        <TableIndexRow
          widthArray={[1, 5, 5, 6, 5 /* 5,5 */]}
          itemArray={[
            "",
            "Item Number",
            "Item Name",
            "Classification",
            "Item Type",
          ]}
        />
        {compareData.map((itemObj) => (
          <TableRow
            key={itemObj.id}
            widthArray={[1, 5, 5, 6, 5 /* 5,5 */]}
            itemArray={[
              "",
              itemObj.cardNumber,
              itemObj.cardName,
              itemObj.classification,
              itemObj.cardType,
            ]}
          />
        ))}
      </div>
      <div className="rightPart">
        <div className="BlueBoxContainer">
          <div className="BlueBox">{name}</div>
          <div className="BlueBox">{compareName}</div>
        </div>
        {compareData.map((item) => (
          <div className="BlueBoxContainer">
            <div className="BlueBox">
              {item.different < 0 || item.different * 1 === 1 ? "O" : "X"}
            </div>
            <div className="BlueBox">
              {item.different < 0 || item.different * 1 === 2 ? "O" : "X"}
            </div>
          </div>
        ))}
      </div>
    </CompareTableStyle>
  );
}
