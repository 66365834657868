import create from "zustand";
import { defaultStoreSeed, stateObjSeed } from "./defaultStore";

const productStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  ...stateObjSeed(set, get),
  stateObj: {
    classificationId: "",
    partNumber: "",
    name: "",
    revision: "",
    specification: "",
  },
  productChildList: [],
  setproductChildList: (val) => {
    set(() => ({ productChildList: val }));
  },
  addedProductChildList: [],
  setaddedProductChildList: (val) => {
    set(() => ({ addedProductChildList: val }));
  },
  deletedProductChildList: [],
  setdeletedProductChildList: (val) => {
    set(() => ({ deletedProductChildList: val }));
  },
  deleteProductChild: (valObj) => {
    const { productChildList, addedProductChildList, deletedProductChildList } =
      get();
    const tmpDelList = [...deletedProductChildList];
    const tmpChildList = productChildList.filter((obj) => {
      if (tmpDelList.findIndex((delObj) => delObj.id === valObj.id) < 0) {
        tmpDelList.push(valObj);
      }
      return obj?.id !== valObj.id;
    });
    if (!tmpChildList[0]) {
      tmpChildList.push(1);
    }
    set(() => ({
      deletedProductChildList: tmpDelList,
      productChildList: tmpChildList,
      addedProductChildList: addedProductChildList.filter(
        (obj) => obj.id !== valObj.id
      ),
    }));
  },

  modalType: "",
  setmodalType: (val) => {
    set(() => ({ modalType: val }));
  },

  modalClassificationTree: [],
  setmodalClassificationTree: (val) => {
    set(() => ({ modalClassificationTree: val }));
  },
  modalSelectedItemClassification: {}, // classId
  setmodalSelectedItemClassification: (val) => {
    set(() => ({ modalSelectedItemClassification: val }));
  },
  modalItemList: [],
  setmodalItemList: (val) => {
    set(() => ({ modalItemList: val }));
  },
  totalPage: "",
  settotalPage: (val) => {
    set(() => ({ totalPage: val }));
  },
  pageNum: 0,
  setpageNum: (val) => {
    set(() => ({ pageNum: val }));
  },
  searchText: "",
  setsearchText: (val) => {
    set(() => ({ searchText: val }));
  },
  rowsPerPage: 6,
  setrowsPerPage: (val) => {
    set(() => ({ rowsPerPage: val }));
  },
  modalTempCheckedList: [], // 추가로 그 아이템 정보
  modalCheckedItemList: [],
  // 단가계산
  priceBomTree: [],
  setpriceBomTree: (val) => {
    set(() => ({ priceBomTree: val }));
  },
  synchroPriceTreeBomTree: () => {
    set((state) => ({ priceBomTree: state.productChildList }));
  },
  exchangeRateObj: {
    $: 1350,
    "¥": 9.5,
    "₩": 1,
  },
  initexchangeRateObj: (dataObj) => {
    let tmpObj = {
      $: 1350,
      "¥": 9.5,
      "₩": 1,
    };

    if (dataObj) {
      tmpObj = {
        $: dataObj.amc * 1,
        "¥": dataObj.jap * 1,
        "₩": 1,
      };
    }

    set(() => ({ exchangeRateObj: tmpObj }));
  },
  totalPrice: 0,
  settotalPrice: (val) => {
    set(() => ({ totalPrice: val }));
  },
  totalPriceUnit: "₩",
  settotalPriceUnit: (val) => {
    set(() => ({ totalPriceUnit: val }));
  },
  totalPriceUnitObj: {
    $: "USD",
    "¥": "JPY",
    "₩": "KRW",
  },
  addTotalPrice: (val) => {
    set((state) => ({ totalPrice: state.totalPrice + val }));
  },
  secondChildPriceObj: {},
  changeSecondPriceList: (id, wonPrice) => {
    const { secondChildPriceObj } = get();
    const tmpSecondChildPriceObj = { ...secondChildPriceObj };
    tmpSecondChildPriceObj[id] = wonPrice;
    const newTotal = Object.keys(tmpSecondChildPriceObj).reduce((acc, key) => {
      return acc + tmpSecondChildPriceObj[key];
    }, 0);
    set(() => ({
      totalPrice: newTotal,
      secondChildPriceObj: tmpSecondChildPriceObj,
    }));
  },

  changedPriceObj: {}, // 사용자가 건드린 item만 담긴 obj.
  initChangedPriceObj: (arr) => {
    const tmpObj = {};
    arr.forEach((dataObj) => {
      tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId] = dataObj;
      [
        tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId].price,
        tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId].priceUnit,
      ] = dataObj.price.split(" ");
      [
        tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId].additionalPrice,
        tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId]
          .additionalPriceUnit,
      ] = dataObj.additionalPrice.split(" ");
    });
    set(() => ({ changedPriceObj: tmpObj }));
  },
  editChangedPriceObj: (valObj) => {
    // id,parentId,price,priceUnit, priceType, priceCondition, additionalPrice, additionalPriceUnit
    const { changedPriceObj } = get();
    const tmpObj = { ...changedPriceObj };
    tmpObj[valObj.id] = valObj;
    set(() => ({ changedPriceObj: tmpObj }));
  },

  // attachment
  attachmentTagOptionList: [{ id: 2, name: "ETC" }],
  setattachmentTagOptionList: (val) => {
    set(() => ({ attachmentTagOptionList: val }));
  },

  getisConditionFullfill: () => {
    const { stateObj, isAttachmentValid } = get();
    return !!stateObj.name && !!isAttachmentValid && !!stateObj.partNumber;
  },

  //    ACTION
  openBOMModal: () => {
    set(() => ({ modalType: "BOM" }));
  },
  openSRModal: () => {
    set(() => ({ modalType: "SR" }));
  },
  openPriceModal: () => {
    set(() => ({ modalType: "price" }));
  },
  closeModal: () => {
    set(() => ({ modalType: "" }));
  },
  exitModalCallBack: () => {
    set(() => ({
      modalTempCheckedList: [],
      modalCheckedItemList: [],
      modalItemList: [],
      modalSelectedItemClassification: {},
    }));
  },
  onCheckModalItem: (val, uno) => {
    if (uno) {
      set(() => ({ modalTempCheckedList: [val] }));
      return;
    }
    const arr = get().modalTempCheckedList;
    const { modalType } = get();
    const tmpArr = [...arr];
    const targetIndex = tmpArr.findIndex((itemObj) => itemObj.id === val.id);
    if (targetIndex > -1) {
      tmpArr.splice(targetIndex, 1);
    } else {
      tmpArr.push(val);
    }

    set(() => ({ modalTempCheckedList: modalType === "SR" ? [val] : tmpArr }));
  },
  cancelModal: () => {
    set(() => ({
      modalType: "",
      modalTempCheckedList: [],
      modalCheckedItemList: [],
    }));
  },
  applyModalLv2: () => {
    const { modalTempCheckedList, modalSelectedItemClassification } = get();
    const tmpArr = [
      ...modalTempCheckedList.map((obj) => ({
        ...obj,
        classificationName: modalSelectedItemClassification.name,
      })),
    ];
    set(() => ({ modalCheckedItemList: tmpArr }));
  },
  applyModalLv3: () => {
    const {
      modalCheckedItemList,
      productChildList,
      modalType,
      addedProductChildList,
    } = get();
    if (modalType === "BOM") {
      const tmpSet = new Set([...productChildList, ...modalCheckedItemList]);
      const tmpArr = [...tmpSet];
      if (tmpArr.findIndex((item) => item === 1) > -1) {
        tmpArr.splice(
          tmpArr.findIndex((item) => item === 1),
          1
        );
      }
      const tmpAddedArr = [...addedProductChildList, ...modalCheckedItemList];
      set(() => ({
        productChildList: tmpArr,
        addedProductChildList: tmpAddedArr,
      }));
    }
    if (modalType === "SR") {
      const newSRBOM = [...modalCheckedItemList];

      set(() => ({
        productChildList: JSON.parse(newSRBOM[0].srBom) || [],
      }));
    }
    set(() => ({
      modalType: "",
      modalTempCheckedList: [],
      modalCheckedItemList: [],
    }));
  },
  // compare bom
  isModal: "",
  setisModal: (val) => {
    set(() => ({
      isModal: val,
    }));
  },
  compareTarget: "",
  setcompareTarget: (val) => {
    set(() => ({
      compareTarget: val,
    }));
  },
  compareType: "SR", // SR || DEV
  setcompareType: (val) => {
    set(() => ({
      compareType: val,
    }));
  },
  applyCompareModalLv3: () => {
    const { modalCheckedItemList } = get();

    set(() => ({
      compareTarget: modalCheckedItemList[0],
      modalType: "",
      modalTempCheckedList: [],
      modalCheckedItemList: [],
      isModal: false,
    }));
  },
  onDeleteModalAddedItems: (id) => {
    set(() => ({
      modalTempCheckedList: get().modalTempCheckedList.filter(
        (itemObj) => itemObj.id !== id
      ),
      modalCheckedItemList: get().modalCheckedItemList.filter(
        (itemObj) => itemObj.id !== id
      ),
    }));
  },
  initModuleStore: () => {
    set((state) => ({
      stateObj: {
        id: "",
        classificationId: "",
        partNumber: "",
        name: "",
        revision: "",
        specification: "",
      },
      productChildList: [1],
      addedProductChildList: [],
      deletedProductChildList: [],
      modalItemList: [],

      totalPrice: "",
      changedPriceObj: {}, // 사용자가 건드린 item만 담긴 obj.
      priceBomTree: [],
      secondChildPriceObj: {},
    }));
  },
}));
export default productStore;
