import React from "react";
import styled from "styled-components";
import TableRow from "../atom/TableRow";
import BomRow from "../atom/BomRow";

const BoardRowStyle = styled.div`
  display: flex;
  align-items: center;
  .icon {
    color: var(--eciBlue);
    font-size: 28px;
    transform: rotate(${(props) => (props.isFold ? "-90deg" : "0")});
    cursor: pointer;
    flex: none; // 이 부분 추가
    width: 30px; // 아이콘의 폭을 고정
  }
  .leftPart {
    flex-shrink: 1;
    background-color: var(--textGray);
    width: calc(100% - 55vw); // 아이콘의 폭을 고려하여 조정
  }
  .rightPart {
    height: 50px;
    width: 55vw;
    flex-shrink: 0;
    background-color: var(--textGray);
  }
`;
export default function BoardRow({
  setisFold,
  isFold,
  leftWidthArray = [7, 1],
  rightWidthArray = [6, 7, 7, 9],
  leftItemArray = [],
  rightItemArray = [],
}) {
  return (
    <BoardRowStyle isFold={isFold}>
      <div
        className="material-icons icon"
        onClick={() => {
          setisFold((state) => !state);
        }}
      >
        expand_more
      </div>
      <div className="leftPart">
        <BomRow leftColor="var(--eciBlue)" backgroundColor="var(--textGray)">
          <TableRow
            noBorder
            fontSize="13px"
            height="100%"
            itemArray={leftItemArray}
            widthArray={leftWidthArray}
          />
        </BomRow>
      </div>
      <div className="rightPart">
        <TableRow
          noBorder
          fontSize="13px"
          height="100%"
          itemArray={rightItemArray}
          widthArray={rightWidthArray}
        />
      </div>
    </BoardRowStyle>
  );
}
