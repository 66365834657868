import React from "react";
import styled from "styled-components";
import { WidthHeightStyle } from "../Style";

const DeleteButtonStyle = styled.button`
  ${WidthHeightStyle}
  background-color: inherit;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  .material-icons {
    color: ${(props) => props.color};
    font-size: ${(props) => props.fontSize};
  }
  display: flex;
  align-items: center;
`;

export default function DeleteButton({
  onDelete = () => {},
  color = "var(--textGray)",
  width = "100%",
  height,
  fontSize,
}) {
  return (
    <DeleteButtonStyle
      onClick={onDelete}
      type="button"
      color={color}
      width={width}
      height={height}
      fontSize={fontSize}
    >
      <span className="material-icons">delete</span>
    </DeleteButtonStyle>
  );
}
