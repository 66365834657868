import React, { useState } from "react";
import styled from "styled-components";

const TextBoxStyle = styled.div`
  pointer-events: ${(props) => props.readOnly && "none"};

  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  position: relative;
  .required {
    position: absolute;
    top: 16px;
    left: 90px;
    width: 5px;
    height: 5px;
    border-radius: 10px;
    margin-left: 3px;
    display: inline-block;
    background-color: var(--required);
    display: ${(props) => props.isFocused && "none"};
  }
`;
const TextAreaStyle = styled.textarea`
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  resize: none;
  border: none;
  color: var(--eciBlue);
  border-radius: 10px;
  padding: 10px;
  outline: var(--eciBlue) 1px solid;
  font-size: 14px;

  ::placeholder {
    font-size: 14px;
    color: var(--deemGray);
  }
`;

export default function TextBox({
  width,
  height,
  state,
  setState,
  requiredError,
  readOnly,
  required,
  placeholder,
  backgroundColor,
}) {
  const [isFocused, setisFocused] = useState(false);
  const handleText = (e) => {
    e.preventDefault();
    if (setState) {
      setState(e.target.value);
    }
  };
  return (
    <TextBoxStyle
      width={width}
      height={height}
      readOnly={readOnly}
      isFocused={isFocused || state}
    >
      {required && !readOnly && <div className="required" />}
      <TextAreaStyle
        onChange={handleText}
        placeholder={placeholder}
        value={state}
        backgroundColor={backgroundColor}
        onFocus={() => setisFocused(true)}
        onBlur={() => setisFocused(false)}
      />
      {requiredError ? <div className="fillIn">fill in</div> : ""}
    </TextBoxStyle>
  );
}
