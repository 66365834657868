import create from "zustand";
import { defaultStoreSeed, stateObjSeed } from "./defaultStore";

const unitCostStore = create((set, get) => ({
  ...defaultStoreSeed(set, get),
  partNumber: "",
  name: "",
  setProductData: (name, number) => {
    set(() => ({
      partNumber: number,
      name,
    }));
  },
  bomTreeList: [],
  setbomTreeList: (val) => {
    set(() => ({
      bomTreeList: val,
    }));
  },
  setbomData: (val) => {
    set(() => ({
      bomTreeList: val,
      // totalPrice
      // partNumber,name
    }));
  },
  excelDataObj: {},
  changeExcelDataObj: ({
    id,
    level,
    type,
    itemNumber,
    specification,
    buyerOrganization,
    priceType,
    price,
    priceCondition,
    additionalPrice,
    totalPrice,
    approvedPrice,
    totalApprovedPrice,
  }) => {
    const { excelDataObj } = get();
    const tmpObj = { ...excelDataObj };
    tmpObj[id] = {
      level,
      type,
      itemNumber,
      specification,
      buyerOrganization,
      priceType,
      price,
      priceCondition,
      additionalPrice,
      totalPrice,
      approvedPrice,
      totalApprovedPrice,
    };
    set(() => ({ excelDataObj: tmpObj }));
  },
  totalUnitCost: 0,
  totalRecognizedPrice: 0,

  unitCostDollarE: 1100,
  unitCostYuanE: 10.61,
  recognizedCostDollarE: 1100,
  recognizedCostYuanE: 10.61,

  carTypeId: { id: "", name: "", yuan: 10.61, dollar: 1100 },
  setcarTypeId: (_, val) => {
    set(() => ({
      isModal: false,
      carTypeId: val,
      recognizedCostDollarE: val.dollar.replace(/,/g, "") * 1,
      recognizedCostYuanE: val.yen.replace(/,/g, "-") * 1,
      approvedExchangeRateObj: {
        "￥": parseFloat(val.yen.replace(/,/g, "")),
        $: parseFloat(val.dollar.replace(/,/g, "")),
        "₩": 1,
      },
    }));
  },

  // productStore c

  approvedExchangeRateObj: { $: 1350, "￥": 9.5, "₩": 1 },

  exchangeRateObj: {
    $: 1350,
    "￥": 9.5,
    "₩": 1,
  },
  initexchangeRateObj: (dataObj) => {
    let tmpObj = {
      $: 1100,
      "￥": 10.61,
      "₩": 1,
    };

    if (dataObj) {
      tmpObj = {
        $: dataObj.amc * 1,
        "￥": dataObj.jap * 1,
        "₩": 1,
      };
    }

    set(() => ({
      exchangeRateObj: tmpObj,
      unitCostDollarE: tmpObj.$,
      unitCostYuanE: tmpObj["￥"],

      recognizedCostDollarE: tmpObj.$,
      recognizedCostYuanE: tmpObj["￥"],
      approvedExchangeRateObj: tmpObj,
    }));
  },

  totalPrice: 0,
  secondChildPriceObj: {},
  changeSecondPriceList: (id, wonPrice) => {
    const { secondChildPriceObj } = get();
    const tmpSecondChildPriceObj = { ...secondChildPriceObj };
    // console.log(id, wonPrice);
    tmpSecondChildPriceObj[id] = wonPrice;
    const newTotal = Object.keys(tmpSecondChildPriceObj).reduce((acc, key) => {
      return (
        acc +
        (typeof tmpSecondChildPriceObj[key] === "number"
          ? tmpSecondChildPriceObj[key]
          : 0)
      );
    }, 0);
    set(() => ({
      totalPrice: newTotal.toFixed(2),
      secondChildPriceObj: tmpSecondChildPriceObj,
    }));
  },

  totalApprovedPrice: 0,
  secondChildApprovedPriceObj: {},
  changeSecondApprovedPriceList: (id, wonPrice) => {
    const { secondChildApprovedPriceObj } = get();
    const tmpSecondChildApprovedPriceObj = { ...secondChildApprovedPriceObj };
    tmpSecondChildApprovedPriceObj[id] = wonPrice;
    const newTotal = Object.keys(tmpSecondChildApprovedPriceObj).reduce(
      (acc, key) => {
        return acc + tmpSecondChildApprovedPriceObj[key];
      },
      0
    );
    set(() => ({
      totalApprovedPrice: newTotal.toFixed(2),
      secondChildApprovedPriceObj: tmpSecondChildApprovedPriceObj,
    }));
  },

  changedPriceObj: {}, // 사용자가 건드린 item만 담긴 obj.
  isChangedPriceObjUpdated: false,
  initChangedPriceObj: (arr) => {
    const tmpObj = {};
    arr.forEach((dataObj) => {
      tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId] = dataObj;
      [
        tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId].price,
        tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId].priceUnit,
      ] = (dataObj.price || "").split(" ");
      [
        tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId].additionalPrice,
        tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId]
          .additionalPriceUnit,
      ] = (dataObj.additionalPrice || "").split(" ");
      [
        tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId].approvedPrice,
        tmpObj[dataObj.id || dataObj.itemId || dataObj.cardId]
          .approvedPriceUnit,
      ] = (dataObj.approvedPrice || "").split(" ");
    });
    set(() => ({ changedPriceObj: tmpObj, isChangedPriceObjUpdated: true }));
  },
  editChangedPriceObj: (valObj) => {
    // id,parentId,price,priceUnit, priceType, priceCondition, additionalPrice, additionalPriceUnit, approved...
    const { changedPriceObj } = get();
    const tmpObj = { ...changedPriceObj };
    tmpObj[valObj.id] = valObj;
    set(() => ({ changedPriceObj: tmpObj }));
  },

  isModal: false,
  setisModal: (val) => {
    set(() => ({
      isModal: val,
    }));
  },

  initModuleStore: () => {
    set(() => ({
      bomTreeList: [],
      totalUnitCost: 0,
      totalRecognizedPrice: 0,
      totalPrice: 0,
      totalApprovedPrice: 0,
      isChangedPriceObjUpdated: false,

      secondChildPriceObj: {},
      secondChildApprovedPriceObj: {},

      unitCostDollarE: 1100,
      unitCostYuanE: 10.61,
      recognizedCostDollarE: 1100,
      changedPriceObj: {}, // 사용자가 건드린 item만 담긴 obj.
      excelDataObj: {},

      recognizedCostYuanE: 10.61,
      exchangeRateObj: {
        $: 1350,
        "￥": 9.5,
        "₩": 1,
      },
      approvedExchangeRateObj: { $: 1350, "￥": 9.5, "₩": 1 },

      carTypeId: { id: "", name: "", yuan: 10.61, dollar: 1100 },
      isModal: "",
    }));
  },
}));
export default unitCostStore;
