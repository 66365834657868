import React, { useRef, useState } from "react";
import styled from "styled-components";
import { PropModalStyle, ScrollDefault } from "../../Style";
import Table from "../../molecule/Table";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";
import PaginationTable from "../../molecule/PaginationTable";

import productStore from "../../store/productStore";
import ClassificationSelectTree from "../../organism/ClassificationSelectTree";
import SearchBar from "../../molecule/SearchBar";
import DeleteButton from "../../atom/DeleteButton";
import txt from "../../Text";

const ModalStyle = styled.div`
  display: flex;
  width: 80vw;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 5px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 1px solid var(--deemGray);
    width: 5px;
    background: white;
    border-radius: 8px;
  }
  .gap {
    width: 40px;
    flex-shrink: 0;
  }
  .treeScrollContainer {
    border-radius: 12px 12px 0 0;

    padding-top: 40px;
    background-color: white;
    height: calc(100% - 59px);
    ${ScrollDefault}
  }
`;
const saveTreeState = (state) => {
  localStorage.setItem(
    "compareModalClassificationTreeState",
    JSON.stringify(state)
  );
};

const loadTreeState = () => {
  const savedState = localStorage.getItem(
    "compareModalClassificationTreeState"
  );
  return savedState ? JSON.parse(savedState) : {};
};
export default function CompareModal({
  cancelFunc,
  applyLv2,
  applyLv3,
  onCheckState,
  onDeleteModalAddedItems,
}) {
  const [treeState, setTreeState] = useState(loadTreeState());
  const setIsOpen = (id, isOpen) => {
    setTreeState((prevState) => {
      const newState = { ...prevState, [id]: isOpen };
      saveTreeState(newState);
      return newState;
    });
  };
  const modalContainerRef = useRef();
  const store = productStore();
  return (
    <ModalStyle ref={modalContainerRef}>
      <PropModalStyle width="240px" height="70vh" fontSize="20px">
        <div className="title">Choose Item Classification</div>
        <div className="treeScrollContainer">
          {store.modalClassificationTree?.map((item, i) => {
            return (
              <ClassificationSelectTree
                key={i}
                child={item.children}
                title={item.name}
                last={item.last}
                id={item.id}
                data={item}
                depth={0}
                treeId={i}
                backgroundColor="var(--eciBlue)"
                boxWidth="100px"
                onClick={(val) => {
                  if (val.last === 1) {
                    store.setmodalSelectedItemClassification(val);
                    modalContainerRef.current.scrollTo({
                      left: 250,
                      behavior: "smooth",
                    });
                  }
                }}
                currentState={store.modalSelectedItemClassification}
                isOpen={treeState}
                setIsOpen={setIsOpen}
              />
            );
          })}
        </div>
      </PropModalStyle>
      <div className="gap" />
      <PropModalStyle width="680px" height="70vh" fontSize="20px">
        <div className="title">Select the item</div>
        <SearchBar
          state={store.searchText}
          setState={store.setsearchText}
          height="32px"
          cssStyle="margin:10px 16px; "
        />
        <div className="modal">
          <PaginationTable
            width="100%"
            minHeight="90%"
            totalPage={store.totalPage}
            rowsPerPageDefault={10}
            pageNum={store.pageNum}
            setRowsPerPage={store.setRowsPerPage}
            rowsPerPage={store.rowsPerPage}
            setpageNum={store.setpageNum}
            noshadow
          >
            <TableIndexRow
              widthArray={[2, 6, 9, 4, 4]}
              itemArray={txt.product.productModalIndex1}
            />

            {store.modalItemList.map((itemObj, i) => (
              <TableRow
                key={i}
                widthArray={[2, 6, 9, 4, 4]}
                itemArray={[
                  <input
                    type="checkbox"
                    onChange={() => {
                      onCheckState(itemObj);
                    }}
                    checked={
                      store.modalTempCheckedList.findIndex(
                        (item) => item.id === itemObj.id
                      ) > -1
                    }
                  />,
                  itemObj.number,
                  itemObj.name,
                  itemObj.revision,
                ]}
              />
            ))}
          </PaginationTable>
        </div>

        <div className="buttonPart">
          <div className="actionButton cancel" onClick={cancelFunc}>
            CANCEL
          </div>
          <div
            className="actionButton apply"
            onClick={() => {
              modalContainerRef.current.scrollTo({
                left: 3000,
                behavior: "smooth",
              });
              applyLv2();
            }}
          >
            APPLY
          </div>
        </div>
      </PropModalStyle>
      <div className="gap" />

      <PropModalStyle width="500px" height="70vh" fontSize="20px">
        <div className="title">Added Items</div>
        <div className="modal">
          <Table width="100%" noshadow>
            <TableIndexRow
              widthArray={[7, 7]}
              itemArray={txt.product.productModalIndex2}
            />

            {store.modalCheckedItemList.map((itemObj, i) => (
              <TableRow
                key={i}
                widthArray={[7, 6, 1]}
                itemArray={[
                  itemObj.number,
                  itemObj.name,
                  <DeleteButton
                    onDelete={() => onDeleteModalAddedItems(itemObj.id)}
                  />,
                ]}
              />
            ))}
          </Table>
        </div>

        <div className="buttonPart">
          <div className="actionButton cancel" onClick={cancelFunc}>
            CANCEL
          </div>
          <div className="actionButton apply" onClick={applyLv3}>
            APPLY
          </div>
        </div>
      </PropModalStyle>
    </ModalStyle>
  );
}
