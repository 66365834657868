import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TableRow from "../../atom/TableRow";
import SelectBox from "../../atom/SelectBox";
import {
  priceContractCondition,
  priceTypeList,
  priceUnitList,
} from "../../utility/Source";
import productStore from "../../store/productStore";
import Input from "../../atom/Input";
import { LightGrayInputContainer } from "../../Style";

const PriceRowTreeStyle = styled.div`
  div {
    div {
      span {
        > .fold {
          font-size: 20px;
          transform: rotate(${(props) => (props.isFold ? "-90deg" : 0)});
          cursor: pointer;
        }
      }
    }
  }

  > .child {
    opacity: ${(props) => (props.isFold ? 0 : 1)};
    height: ${(props) => (props.isFold ? 0 : "max-content")};
  }
`;
const SubSumStyle = styled.div`
  width: 60px;
  height: 30px;
  border-radius: 100px;
  background-color: var(--eciBlue);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UnitInputConatainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export default function PriceRowTree({
  widthArr,
  setchildPriceObjP,
  data,
  parentId,
  level,
}) {
  // 자식 가격 계산기
  const getWonPrice = (val, unit = "₩") => {
    return val * 1 * (store.exchangeRateObj[unit] || 1);
  };
  const getChildPriceSum = () => {
    const childPriceWonSum = Object.values(childPriceObj).reduce((acc, obj) => {
      // 환율 계산 -> 원으로 통일
      // 엔 환율은 100엔 기준
      return (
        acc +
        (obj.price ? getWonPrice(obj.price, obj.priceUnit || "₩") : 0) +
        (obj.additionalPrice
          ? getWonPrice(obj.additionalPrice, obj.additionalPriceUnit || "₩")
          : 0)
      );
    }, 0);
    const childPriceSum = childPriceWonSum / store.exchangeRateObj[priceUnit];
    return childPriceSum;
  };
  // ---------Business Logic
  // component state
  const store = productStore();
  const [isFold, setisFold] = useState(true);
  const [childPriceObj, setchildPriceObj] = useState({}); // {id:{price:1200,unit:"$"}}
  // price state
  const [price, setprice] = useState(0);
  const [priceUnit, setpriceUnit] = useState("₩");
  const [priceType, setpriceType] = useState("");
  const [priceCondition, setpriceCondition] = useState("");
  const [additionalPrice, setadditionalPrice] = useState(0);
  const [additionalPriceUnit, setadditionalPriceUnit] = useState("₩");
  const newSetPrice = (val) => {
    if (level > 2) {
      setchildPriceObjP((obj) => {
        const tmpObj = structuredClone(obj);
        if (!tmpObj[data.id]) {
          tmpObj[data.id] = {};
        }
        tmpObj[data.id].price = val;

        return tmpObj;
      });
    }

    setprice(val);
  };
  const newSetUnit = (val) => {
    if (level > 2) {
      setchildPriceObjP((obj) => {
        const tmpObj = structuredClone(obj);
        if (!tmpObj[data.id]) {
          tmpObj[data.id] = {};
        }
        tmpObj[data.id].priceUnit = val;
        return tmpObj;
      });
    }
    newSetPrice(getWonPrice(price, priceUnit) / store.exchangeRateObj[val]);
    setpriceUnit(val);
  };
  const newSetAdditionalPrice = (val) => {
    if (level > 2) {
      setchildPriceObjP((obj) => {
        const tmpObj = structuredClone(obj);
        if (!tmpObj[data.id]) {
          tmpObj[data.id] = {};
        }
        tmpObj[data.id].additionalPrice = val;
        return tmpObj;
      });
    }
    setadditionalPrice(val);
  };
  const newSetAdditionalPriceUnit = (val) => {
    if (level > 2) {
      setchildPriceObjP((obj) => {
        const tmpObj = structuredClone(obj);
        if (!tmpObj[data.id]) {
          tmpObj[data.id] = {};
        }
        tmpObj[data.id].additionalPriceUnit = val;
        return tmpObj;
      });
    }
    newSetAdditionalPrice(
      getWonPrice(additionalPrice, additionalPriceUnit) /
        store.exchangeRateObj[val]
    );
    setadditionalPriceUnit(val);
  };
  const setStateObj = {
    price: newSetPrice,
    priceUnit: newSetUnit,
    priceType: setpriceType,
    priceCondition: setpriceCondition,
    additionalPrice: newSetAdditionalPrice,
    additionalPriceUnit: newSetAdditionalPriceUnit,
  };
  // state변화시 store의 변화obj에 추가
  const stateChangeListener = (state) => {
    return (val) => {
      setStateObj[state](val);
      if (data.id && val) {
        store.editChangedPriceObj({
          id: data.id,
          parentId,
          price: state === "price" ? val : price,
          priceUnit: state === "priceUnit" ? val : priceUnit,
          priceCondition: state === "priceCondition" ? val : priceCondition,
          priceType: state === "priceType" ? val : priceType,
          additionalPrice: state === "additionalPrice" ? val : additionalPrice,
          additionalPriceUnit:
            state === "additionalPriceUnit" ? val : additionalPriceUnit,
        });
      }
    };
  };
  // -----------VIEW
  const childRowArray = (
    data?.children || [
      ...(data?.boardChildren || []),
      ...(data?.itemChildren || []),
    ]
  )?.map((item, i) => (
    <PriceRowTree
      key={item.id + i + item.id}
      widthArr={widthArr}
      setchildPriceObjP={setchildPriceObj}
      data={item}
      parentId={data.id}
      level={level + 1}
    />
  ));
  // 하위 변화 감지
  useEffect(() => {
    const childPriceSum = getChildPriceSum();
    // setprice(childPriceSum);
    stateChangeListener("price")(childPriceSum);
  }, [childPriceObj]);
  //  totalPrice 세팅
  useEffect(() => {
    if (level === 2) {
      store.changeSecondPriceList(
        data.id,
        getWonPrice(price || 0, priceUnit) +
          getWonPrice(additionalPrice || 0, additionalPriceUnit)
      );
    }
  }, [price, priceUnit, additionalPrice, additionalPriceUnit]);
  // price관련 값들 init

  useEffect(() => {
    // 기본 state init
    const dataPrice = getChildPriceSum();

    newSetUnit(store.changedPriceObj[data.id]?.priceUnit || "₩");
    newSetPrice(store.changedPriceObj[data.id]?.price || dataPrice);
    setadditionalPrice(store.changedPriceObj[data.id]?.additionalPrice || 0);
    setadditionalPriceUnit(
      store.changedPriceObj[data.id]?.additionalPriceUnit || "₩"
    );
    setpriceType(store.changedPriceObj[data.id]?.priceType || "");
    setpriceCondition(store.changedPriceObj[data.id]?.priceCondition || "");
    if (setchildPriceObjP) {
      setchildPriceObjP((obj) => {
        const tmpObj = structuredClone(obj);
        if (!tmpObj[data.id]) {
          tmpObj[data.id] = {};
        }
        tmpObj[data.id].price = store.changedPriceObj[data.id]?.price;
        tmpObj[data.id].priceUnit = store.changedPriceObj[data.id]?.priceUnit;
        tmpObj[data.id].additionalPrice =
          store.changedPriceObj[data.id]?.additionalPrice;
        tmpObj[data.id].additionalPriceUnit =
          store.changedPriceObj[data.id]?.additionalPriceUnit;
        return tmpObj;
      });
    }

    // totalPrice init
    if (level === 2) {
      store.changeSecondPriceList(
        data.id,
        getWonPrice(price || 0, priceUnit) +
          getWonPrice(additionalPrice || 0, additionalPriceUnit)
      );
    }
  }, [store.changedPriceObj.length]);
  return (
    <PriceRowTreeStyle isFold={isFold}>
      <TableRow
        backgroundColor={
          store.changedPriceObj[data?.id] && "rgba(253, 191, 77, 0.3)"
        }
        widthArray={widthArr}
        itemArray={[
          data.children && data.children[0] ? (
            <span
              className="material-icons fold"
              onClick={() => setisFold((bool) => !bool)}
            >
              arrow_drop_down
            </span>
          ) : (
            ""
          ),
          level,
          data.type,
          data.number || data.cardNumber,
          data.name || data.cardName,
          data.specification,
          data.supplierOrganizationId,
          <SelectBox
            height="30px"
            backgroundColor="var(--lightGray)"
            cssStyle="border-radius:8px"
            state={priceType}
            setState={stateChangeListener("priceType")}
          >
            {priceTypeList?.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </SelectBox>,
          <UnitInputConatainer>
            <LightGrayInputContainer height="30px">
              <Input
                width="100%"
                state={(price * 1).toFixed(2)}
                setState={stateChangeListener("price")}
              />
            </LightGrayInputContainer>
            <SelectBox
              width="50%"
              height="30px"
              backgroundColor="var(--lightGray)"
              cssStyle="border-radius:8px"
              state={priceUnit}
              setState={stateChangeListener("priceUnit")}
            >
              {priceUnitList?.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </SelectBox>
            ,
          </UnitInputConatainer>,
          <SelectBox
            height="30px"
            backgroundColor="var(--lightGray)"
            cssStyle="border-radius:8px"
            state={priceCondition}
            setState={stateChangeListener("priceCondition")}
          >
            {priceContractCondition?.map((item, i) => (
              <option key={i} value={item}>
                {item}
              </option>
            ))}
          </SelectBox>,
          <UnitInputConatainer>
            <LightGrayInputContainer height="30px">
              <Input
                width="100%"
                state={additionalPrice}
                setState={stateChangeListener("additionalPrice")}
              />
            </LightGrayInputContainer>

            <SelectBox
              width="50%"
              height="30px"
              backgroundColor="var(--lightGray)"
              cssStyle="border-radius:8px"
              state={additionalPriceUnit}
              setState={stateChangeListener("additionalPriceUnit")}
            >
              {priceUnitList?.map((item, i) => (
                <option key={i} value={item}>
                  {item}
                </option>
              ))}
            </SelectBox>
          </UnitInputConatainer>,
          level === 2 && (
            <SubSumStyle>
              {(
                (getWonPrice(price || 0, priceUnit) +
                  getWonPrice(additionalPrice || 0, additionalPriceUnit)) /
                store.exchangeRateObj[priceUnit]
              ).toFixed(2)}
            </SubSumStyle>
          ),
        ]}
      />
      <div className="child">{childRowArray}</div>
    </PriceRowTreeStyle>
  );
}
