import React from "react";
import styled from "styled-components";
import { ModuleStyle } from "../Style";

import DashboardPage from "../x-dashboard/page/DashboardPage";

export default function Home() {
  return (
    <ModuleStyle>
      <DashboardPage />
    </ModuleStyle>
  );
}
