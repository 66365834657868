import React from "react";
import styled from "styled-components";
import { WidthHeightStyle } from "../../Style";

const PBAColumnStyle = styled.div`
  ${WidthHeightStyle}
  flex-shrink: 0;
  border: ${(props) => props.isHighLight && "solid 2px var(--eciBlue)"};
`;
const OneRowStyle = styled.div`
  ${WidthHeightStyle}
  width:100%;
  display: flex;
  align-items: center;
  font-size: 11px;
  color: var(--textDarkGray);
  height: ${(props) => props.boxHeight};
  border-bottom: solid var(--textGray) 1px;
  background-color: ${(props) => props.isYellow && "var(--backgroundYellow)"};
  justify-content: center;
`;
export default function PBAColumn({
  isHighLight,
  childrenArray,
  width = "120px",

  boxHeight = "40px",
  yellowIndexArray,
}) {
  return (
    <PBAColumnStyle width={width} isHighLight={isHighLight}>
      {childrenArray.map((item, i) => {
        if (item === "4") {
          return (
            <OneRowStyle
              key={i}
              height="160px"
              width={width}
              isYellow={
                yellowIndexArray &&
                yellowIndexArray.findIndex((val) => val === i) > -1
              }
            >
              {item}
            </OneRowStyle>
          );
        }
        return (
          <OneRowStyle
            key={i}
            height={boxHeight}
            width={width}
            isYellow={
              yellowIndexArray &&
              yellowIndexArray.findIndex((val) => val === i) > -1
            }
          >
            {item}
          </OneRowStyle>
        );
      })}
    </PBAColumnStyle>
  );
}
