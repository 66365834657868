import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ContextMenu from "./ContextMenu";

const ContextMenuTitleStyle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: var(--eciBlue);
  position: relative;
`;
export default function ContextMenuTitle({ title, readOnly, children }) {
  const modalRef = useRef(null);
  const [top, settop] = useState(0);
  const [left, setleft] = useState(0);
  const [isContextMenu, setisContextMenu] = useState(false);
  const handleContextMenu = (e) => {
    e.preventDefault();

    if (!readOnly) {
      setisContextMenu(true);
      // console.log(e.target.getBoundingClientRect().right);
      settop(e.pageY);
      setleft(e.pageX);
    }
  };
  const handleClickOutside = (e) => {
    e.stopPropagation();

    if (!modalRef.current.contains(e.target)) {
      setisContextMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("contextmenu", handleClickOutside);
    window.addEventListener("scroll", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("contextmenu", handleClickOutside);
      window.removeEventListener("scroll", handleClickOutside);
    };
  }, []);
  return (
    <ContextMenuTitleStyle
      ref={modalRef}
      onContextMenu={handleContextMenu}
      isActive={isContextMenu}
    >
      {title}{" "}
      {isContextMenu && (
        <ContextMenu setisActive={setisContextMenu} top={top} left={left}>
          {children}
        </ContextMenu>
      )}
    </ContextMenuTitleStyle>
  );
}
