import React from "react";
import styled from "styled-components";
import { Outlet, useNavigate } from "react-router-dom";
import ModuleTopNavigation from "../molecule/ModuleTopNavigation";
import { ModuleStyle } from "../Style";

import boardStore from "../store/boardStore";
import PBAStore from "../store/PBAStore";
import BoldText from "../atom/BoldText";

const BomStyle = styled(ModuleStyle)`
  .con {
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .grayBox {
    font-size: 14px;
    border-radius: 50px;
    height: 32px;
    line-height: 32px;
    padding: 0 18px;
    background-color: rgb(10, 0, 85, 0.3);
    color: white;
    font-weight: 600;
  }
  .blueText {
    color: var(--eciBlue);
    font-size: 14px;
    font-weight: 600;
  }
`;
export default function PBABOMModule() {
  const store = PBAStore();
  const navigate = useNavigate();
  return (
    <BomStyle>
      <ModuleTopNavigation title="PBA BOM" navList={[]}>
        <div className="con">
          <div className="grayBox">{store.stateObj.partNumber}</div>
          <BoldText
            onClick={() => {
              navigate(`/item/pba/detail/${store.stateObj.id}`);
            }}
            fontSize="15px"
            cssStyle="display:flex;align-items:center;cursor:pointer;margin-right:60px;"
            color="var(--eciBlue)" // mpba 권한 조건 추가
          >
            {store.stateObj.name}
          </BoldText>
        </div>
      </ModuleTopNavigation>
      <Outlet />
    </BomStyle>
  );
}
