import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ScrollContainer from "../../organism/ScrollContainer";
import AttachmentSection from "../../organism/AttachmentSection";
import Button from "../../atom/Button";
import RouteInitSection from "../../organism/RouteInitSection";

import RouteSection from "../../organism/RouteSection";

import { BoldBlueTextStyle, PageStyle, SectionGap } from "../../Style";
import { conditionByTypeObject } from "../../utility/Source";

import boardStore from "../../store/boardStore";
import BoardInformationSection from "../organism/BoardInformation";
import { appendBoardForm, useGetBoardData } from "../utility";
import TreeChartContainer from "../../organism/TreeChartContainer";
import useGetReviseData from "../../utility/hooks/useGetReviseData";
import useSaveFunction from "../../utility/hooks/useSaveFunction";
import useTempSaveFunction from "../../utility/hooks/useTempSaveFunction";
import SearchBoxGroup from "../../organism/SearchBoxGroup";

export default function BoardPage() {
  const params = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const navigate = useNavigate();
  //    ---MODEL
  const store = boardStore();
  const phase = searchParam.get("phase");
  const { type } = params;
  const reviseId = searchParam.get("reviseId") || "";

  //    ---VIEW

  //    ---ACTION
  const saveFunc = useSaveFunction(
    "board",
    appendBoardForm,
    store,
    conditionByTypeObject[type].saveFuncType === "put" || phase === "2",
    params.id,
    reviseId
  );
  const saveTempFunc = useTempSaveFunction(
    "board",
    appendBoardForm,
    store,
    conditionByTypeObject[type].saveFuncType === "put",
    params.id
  );
  //  ---BUSINESS LOGIC

  const getData = useGetBoardData(params.id, store);
  const [treeData] = useGetReviseData(
    `board-tree/${params.id}`,
    reviseId,
    store
  );
  useEffect(() => {
    store.setisRouteActive(conditionByTypeObject[type].isRouteActive);
    if (params.id === "new" && searchParam.get("classId")) {
      store.setStateByKey("classificationId")({
        classId: searchParam.get("classId"),
        name: searchParam.get("classificationName"),
      });
    }
    if (params.id !== "new") {
      getData();
    }
    if (phase === "2") {
      navigate(`/board-bom/bom/${type}/${params.id}`);
    }

    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  return (
    <PageStyle>
      <ScrollContainer
        isWideScroll={conditionByTypeObject[type].isScrollWide}
        scrollRefList={[
          [store.informationRef, "Item Information"],
          [store.attachmentRef, "Item Attachment"],
          [store.routeRef, "Route Information"],
        ]}
        searchBoxComponent={<SearchBoxGroup store={store} />}
        tempButtonTitle={
          params.type !== "detail" && !reviseId ? "save as draft" : undefined
        }
        tempButtonOnclick={saveTempFunc}
        additionalPartTop={
          reviseId && <TreeChartContainer treeData={treeData} />
        }
      >
        <BoardInformationSection readOnly={store.isRouteActive} />
        <SectionGap />
        {phase >= 3 && (
          <>
            <BoldBlueTextStyle
              fontSize="20px"
              onClick={() => {
                navigate(`/board-bom/bom/detail/${params.id}`);
              }}
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              Board BOM <span className="material-icons">open_in_new</span>
            </BoldBlueTextStyle>
            <SectionGap />
          </>
        )}
        <AttachmentSection
          editMode={conditionByTypeObject[type].isAttachmentEdit}
          title="Item Attachment"
          readOnly={store.isRouteActive}
          moduleStore={store}
        />
        <SectionGap />
        {!store.isRouteActive && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveFunc}
            condition={store.getisConditionFullfill()}
          >
            Save and Route
          </Button>
        )}
        {store.isRouteActive &&
          (conditionByTypeObject[type].routeSectionType === "init" &&
          phase < 2 ? (
            <RouteInitSection
              activate
              routeType="Board"
              itemId={store.id} // check
              moduleStore={store}
              afterUrl="/item"
            />
          ) : (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={store}
            />
          ))}
      </ScrollContainer>
    </PageStyle>
  );
}
