import React from "react";
import styled from "styled-components";

const StatusTagStyle = styled.div`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  height: 30px;
  line-height: 30px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
`;
export default function StatusTag({ status = "WORKING" }) {
  const statusColorObj = {
    WORKING: {
      color: "rgba(253, 191, 77, 1)",
      backgroundColor: "rgba(255, 247, 231, 1)",
    },
    COMPLETE: {
      color: "rgba(99, 194, 160, 1)",
      backgroundColor: "rgba(235, 249, 244, 1)",
    },
    RELEASE: {
      color: "rgba(10, 0, 85, 1)",
      backgroundColor: "rgba(242, 244, 248, 1)",
    },
  };
  return (
    <StatusTagStyle
      color={statusColorObj[status]?.color}
      backgroundColor={statusColorObj[status]?.backgroundColor}
    >
      {status}
    </StatusTagStyle>
  );
}
