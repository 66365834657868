import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import LineTitle from "../../atom/LineTitle";
import { PageStyle } from "../../Style";
import AnimationSearchInput from "../../molecule/AnimationSearchInput";
import {
  projectInputList,
  projectInputList2,
  projectMiddleInputList,
} from "../utility";
import PeriodBox from "../../molecule/PeriodBox";
import Table from "../../molecule/Table";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";

import Input from "../../atom/Input";
import InputGrid from "../../organism/InputGrid";
import DeleteButton from "../../atom/DeleteButton";
import projectStore from "../../store/projectStore";
import txt from "../../Text";

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  .required {
    width: 5px;
    height: 5px;
    border-radius: 10px;
    margin-left: 3px;
    display: inline-block;
    background-color: var(--required);
    opacity: ${(props) => (props.required && !props.readOnly ? 1 : 0)};
  }
`;
export default function ProjectInformationSection({ readOnly }) {
  const store = projectStore();

  // scroll을 위한 ref관리
  const informationRef = useRef();
  useEffect(() => {
    store.setinformationRef(informationRef);
  }, []);
  // period에 따라 달라지는 ui 정리
  const periodTitleArr = ["Proto", "P1", "P2", "M"];
  const periodStateArr = [
    "protoStartPeriod",
    "protoOverPeriod",
    "p1StartPeriod",
    "p1OverPeriod",
    "p2StartPeriod",
    "p2OverPeriod",
    "mStartPeriod",
    "mOverPeriod",
  ];
  const periodPhase = Math.floor(store.period / 2) + (store.period % 2) - 1;

  const lengthArr = periodPhase > 0 ? new Array(periodPhase).fill(1) : [];
  const periodUI = lengthArr.map((a, i) => {
    return (
      <PeriodBox
        key={i}
        title={periodTitleArr[i]}
        required
        startState={store.stateObj[periodStateArr[2 * i]]}
        startSetState={store.setStateByKey(`${periodStateArr[2 * i]}`)}
        overState={store.stateObj[periodStateArr[2 * i + 1]]}
        overSetState={store.setStateByKey(`${periodStateArr[2 * i + 1]}`)}
        readOnly={readOnly || periodPhase > i + 1}
      />
    );
  });
  return (
    <PageStyle ref={informationRef}>
      {/* <InputContainer required={!readOnly}> */}
      {/*  <div className="required" /> */}
      {/*  <Input */}
      {/*    width="100%" */}
      {/*    state={store.stateObj.name} */}
      {/*    setState={(val) => { */}
      {/*      store.setStateByKey("name")(val); */}
      {/*      console.log(val); */}
      {/*    }} */}
      {/*    fontSize="16px" */}
      {/*    placeholder="Type Project Name" */}
      {/*    readOnly={readOnly || store.period > 2} */}
      {/*  /> */}
      {/* </InputContainer> */}

      {/* <br /> */}
      {/* <br /> */}
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        Project Information
      </LineTitle>
      <InputGrid
        readOnly={readOnly}
        modulestore={store}
        inPutList={projectInputList}
      />
      {store.stateObj.projectTypeId === "1" ? (
        //   양산개발일 경우
        <PeriodBox
          leftText="Project Period"
          title={txt.project.allDo}
          required
          readOnly={readOnly || periodPhase > 0}
          startState={store.stateObj.allDoStartPeriod}
          startSetState={store.setStateByKey("allDoStartPeriod")}
          overState={store.stateObj.allDoOverPeriod}
          overSetState={store.setStateByKey("allDoOverPeriod")}
        />
      ) : (
        //   선행개발일 경우
        <PeriodBox
          leftText="Project Period"
          title={txt.project.proto}
          required
          readOnly={readOnly || periodPhase > 0}
          startState={store.stateObj.allDoStartPeriod}
          startSetState={store.setStateByKey("allDoStartPeriod")}
          overState={store.stateObj.allDoOverPeriod}
          overSetState={store.setStateByKey("allDoOverPeriod")}
        />
      )}
      {store.stateObj.projectTypeId === "1" && periodUI}
      {store.stateObj.projectTypeId === "1" && (
        <InputGrid
          readOnly={readOnly || store.period > 2}
          modulestore={store}
          inPutList={projectInputList2}
        />
      )}
      <InputGrid
        readOnly={readOnly || store.period > 2}
        modulestore={store}
        inPutList={projectMiddleInputList}
      />
      <br />
      <div>
        <AnimationSearchInput
          required
          width="100%"
          height="40px"
          placeholder="Product"
          onClick={() => {
            store.setisModal(true);
          }}
          backgroundColor="var(--eciBlue)"
          readOnly={readOnly}
        />
        <Table width="100%" minHeight="120px">
          <TableIndexRow
            widthArray={[3, 3, 6]}
            itemArray={txt.project.projectInfoIndex}
          />
          {store.products &&
            store.products.map((item, i) => {
              return (
                <TableRow
                  key={i}
                  widthArray={[3, 3, 5, 1]}
                  itemArray={[
                    item.number,
                    item.name,
                    item.classificationId.value,
                    !readOnly && (
                      <DeleteButton
                        onDelete={() => {
                          store.onDeleteProduct(item);
                        }}
                      />
                    ),
                  ]}
                />
              );
            })}
        </Table>
      </div>
    </PageStyle>
  );
}
