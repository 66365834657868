import React, { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import RouteSection from "../../organism/RouteSection";
import { LightGrayInputContainer, PageStyle, SectionGap } from "../../Style";
import Button from "../../atom/Button";
import ScrollContainer from "../../organism/ScrollContainer";
import ModalBox from "../../organism/ModalBox";
import unitCostStore from "../../store/unitCostStore";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import SearchBox from "../../organism/SearchBox";
import ExportExcelButton from "../../organism/ExportExcelButton";
import txt from "../../Text";
import TotalCostBox from "../molecule/TotalCostBox";
import Table from "../../molecule/Table";
import TableIndexRow from "../../atom/TableIndexRow";
import UnitCostTree from "../molecule/UnitCostTree";
import { useGetProductData } from "../utility";

const UnitCostPageStyle = styled(PageStyle)`
  .searchSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 60px;
    gap: 16px;
  }
  .top {
    display: flex;
    justify-content: space-between;
  }
  .group {
    display: flex;
    gap: 16px;
    .text {
      color: var(--textDarkGray);
      font-size: 12px;
    }
    .bold {
      font-weight: 600;
    }
  }
  .leftGap {
    padding-left: 160px;
  }
`;
export default function UnitCostPage() {
  const tokenAxios = useTokenAxios();
  const params = useParams();
  const { type, id } = params;
  // MODEL
  const store = unitCostStore();
  // VIEW
  const informationRef = useRef(null);
  const widthArray = [1, 6, 7, 7, 7, 4, 4, 6, 4, 6, 5, 6, 4];
  // BL

  const getProductBomData = async () => {
    const response = await tokenAxios.get(`/product/bom/${id}`);
    const { data } = response.data.result;

    const priceBomTree = [...data.item];
    if (data.board[0]) priceBomTree.unshift(data.board[0]);
    store.setbomTreeList(priceBomTree);
    store.setrouteNumber(data.routeId);
    store.setProductData(data.name, data.number);
    store.setcarTypeId("", data.carTypeId);
  };

  const getPriceData = async () => {
    const response = await tokenAxios.get(`productCalculation/${params.id}`);
    store.initChangedPriceObj(response?.data || []);
  };
  const getExhchangeRateObj = async () => {
    const response = await tokenAxios.get("exchange-rate");
    store.initexchangeRateObj(response.data.result.data);
  };
  useEffect(() => {
    getProductBomData();
    getPriceData();
    getExhchangeRateObj();
  }, []);
  // ACTION

  const saveFunc = async () => {
    const formData = new FormData();
    const formDataKeyObj = {
      price: [],
      priceType: [],
      priceCondition: [],
      additionalPrice: [],
      approvedPrice: [],
      ids: [],
    };
    Object.values(store.changedPriceObj)
      .filter((obj) => !!obj.id)
      .forEach((obj) => {
        formDataKeyObj.price.push(`${obj.price} ${obj.priceUnit}`);
        formDataKeyObj.priceType.push(obj.priceType);
        formDataKeyObj.priceCondition.push(obj.priceCondition);
        formDataKeyObj.additionalPrice.push(
          `${obj.additionalPrice} ${obj.additionalPriceUnit}`
        );
        formDataKeyObj.approvedPrice.push(
          `${obj.approvedPrice} ${obj.approvedPriceUnit}`
        );
        formDataKeyObj.ids.push(obj.id);
      });
    Object.keys(formDataKeyObj).forEach((key) => {
      formData.append(key, formDataKeyObj[key]);
    });
    formData.append("carTypeId", store.carTypeId?.id);

    await tokenAxios.post(`productCalculation/${id}`, formData);
    alert("done!");
    store.setisRouteActive(true);
  };
  const saveTempFunc = () => {};
  useEffect(() => {
    return () => {
      store.initModuleStore();
    };
  }, []);
  const sortedBomTreeList = useMemo(() => {
    return [...store.bomTreeList].sort((a, b) => a.id - b.id);
  }, [store.bomTreeList]);
  const excelData = (() => {
    const data = [];
    const st = [...store.bomTreeList.reverse()];
    while (st[0]) {
      const curObj = st.pop();
      data.push(store.excelDataObj[curObj.id]);
      if (curObj.children[0]) {
        const reversedChildren = [...curObj.children].reverse();
        st.push(...reversedChildren);
      }
    }
    return data;
  })();
  return (
    <UnitCostPageStyle>
      <ModalBox isActivate={store.isModal} setisActivate={store.setisModal}>
        <SearchBox
          width="500px"
          height="100vh - 218px"
          type="carTypeRate"
          setproperty={store.setcarTypeId}
          isActive
          indexArray={txt.product.unitCostCarModalIndex}
          widthArrayProp={[3, 5, 5]}
        />
      </ModalBox>
      <ScrollContainer
        isWideScroll
        scrollRefList={[
          [informationRef, "Unit Costs"],
          [store.routeRef, "Route Information"],
        ]}
        tempButtonTitle="Save as Draft"
        tempButtonOnclick={saveTempFunc}
        formSectionCss="padding-right:120px; padding-bottom:40px;width:100%;height:max-content;"
      >
        <div className="searchSection">
          {type !== "detail" && (
            <LightGrayInputContainer
              height="30px"
              width="100px"
              styleCss="cursor:pointer;border: solid 1px var(--deemGray);background-color: white; "
              onClick={() => {
                store.setisModal(true);
              }}
            >
              <div className="material-icons">search</div>
              {txt.product.selectCarType}
            </LightGrayInputContainer>
          )}
          <ExportExcelButton
            data={excelData}
            title={`${store.name}-excel.xlsx`}
          />
        </div>
        <div className="top" ref={informationRef}>
          <div className="group">
            <TotalCostBox
              title="Total Unit Price Of"
              name="BR-123A"
              currency="KRW"
              price={store.totalPrice}
            />
            <div className="text">
              <span className="bold">{txt.product.unitCostE}</span>
              <br />
              <span>
                {txt.product.wonDollarE}: {store.unitCostDollarE}
              </span>
              <br />
              <span>
                {txt.product.wonYuanE}: {store.unitCostYuanE}
              </span>
            </div>
          </div>
          <div className="group">
            <div className="text">
              <span className="bold">{txt.product.recognizedCostE}</span>
              <br />
              <span>
                {txt.product.wonDollarE}: {store.recognizedCostDollarE}
              </span>
              <br />
              <span>
                {txt.product.wonYuanE}: {store.recognizedCostYuanE}
              </span>
              <br />
              <span>
                {txt.product.carType}:{" "}
                {store.carTypeId.name || txt.product.unSelected}
              </span>
            </div>
            <TotalCostBox
              title="Total Unit Price Of"
              name="BR-123A"
              currency="KRW"
              price={store.totalApprovedPrice}
            />
          </div>
        </div>
        <br />
        <br />
        <br />
        <Table minHeight="300px" noshadow width="130%">
          <TableIndexRow
            itemArray={txt.product.unitCostIndexList}
            widthArray={widthArray}
          />
          {sortedBomTreeList.map((itemObj) => (
            <UnitCostTree
              key={itemObj?.id}
              data={itemObj}
              level={2}
              parentId={id}
            />
          ))}
        </Table>
      </ScrollContainer>
      <div className="leftGap">
        <SectionGap />
        {!store.isRouteActive && type !== "detail" && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="40%"
            height="30px"
            color="white"
            onClick={saveFunc}
            condition={!!store.carTypeId.id}
          >
            Save and Route
          </Button>
        )}
        {(store.isRouteActive || type === "detail") && (
          <RouteSection activate moduleStore={store} />
        )}
        <SectionGap />
      </div>
    </UnitCostPageStyle>
  );
}
