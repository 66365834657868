import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import logo1 from "../static/logo1.svg";

const ImagePopupSymbolStyle = styled.div`
  span {
    font-size: 20px;
    color: var(--textDarkGray);
  }
  cursor: pointer;
`;
const PopupBoxStyle = styled.div`
  border: solid;
  position: fixed;
  left: 0;
  top: 0;
  transform: translate(${(props) => props.left}px, ${(props) => props.top}px);
  height: max-content;
  line-height: normal;
  width: max-content;
  padding: 8px;
  background-color: white;
  border-radius: 0 10px 10px 10px;
`;
const ImgStyle = styled.img`
  border-radius: 10px;
  height: ${(p) => p.width};
  width: ${(p) => p.height};
`;
export default function ImagePopupSymbol({
  imgSourceArray = [],
  width = "44px",
  height = "44px",
}) {
  const [isPopup, setisPopup] = useState(false);
  const [top, settop] = useState(0);
  const [left, setleft] = useState(0);
  const buttonRef = useRef();
  const popupRef = useRef();
  const handleClickOutside = (e) => {
    e.stopPropagation();

    if (!buttonRef.current.contains(e.target)) {
      setisPopup(false);
    }
  };
  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    window.addEventListener("contextmenu", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
      window.removeEventListener("contextmenu", handleClickOutside);
    };
  }, []);
  //   console.log(popupRef.current.getBoundingClientRect());
  // useEffect(() => {
  //   if (
  //     popupRef.current &&
  //     popupRef.current.getBoundingClientRect().right <
  //       popupRef.current.offsetWidth
  //   ) {
  //     setpopupX(-1 * popupRef.current.offsetWidth);
  //   }
  // }, [isPopup]);

  return (
    <ImagePopupSymbolStyle
      ref={buttonRef}
      onClick={(e) => {
        // e.stopPropagation();
      }}
    >
      {isPopup && (
        <PopupBoxStyle ref={popupRef} top={top} left={left}>
          {imgSourceArray?.map((src, i) => (
            <ImgStyle
              src={src}
              alt="popup"
              onError={(e) => {
                e.target.src = logo1;
              }}
              width={width}
              height={height}
            />
          ))}
        </PopupBoxStyle>
      )}
      <span
        className="material-icons "
        onClick={(e) => {
          e.preventDefault();

          setisPopup(true);
          settop(e.pageY);
          setleft(e.pageX);
        }}
      >
        file_present
      </span>
    </ImagePopupSymbolStyle>
  );
}
