import React from "react";
import styled from "styled-components";
import BoldText from "../../atom/BoldText";
import { ScrollDefault, WidthHeightStyle } from "../../Style";

const ScrollColumnStyle = styled.div`
  ${WidthHeightStyle}
  flex-shrink: 0;
  height: 100%;
  padding-right: 20px;
  min-width: 300px;
  margin-right: 20px;
  .count {
    font-size: 12px;
    color: var(--textDarkGray);
    margin-left: 4px;
  }
  .childContainer {
    margin-top: 12px;
    height: calc(100% - 40px);
    ${ScrollDefault}
  }
`;
export default function ScrollColumn({
  title,
  count,
  children,
  width = "22%",
}) {
  return (
    <ScrollColumnStyle>
      <BoldText fontSize="16px" color="var(--eciBlue)">
        {title}
      </BoldText>
      <span className="count">({count})</span>
      <div className="childContainer">{children}</div>
    </ScrollColumnStyle>
  );
}
