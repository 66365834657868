import React from "react";
import styled from "styled-components";

const ClassificationSelectTreeStyle = styled.div`
  > .titlepb {
    display: flex;
    align-items: center;
    height: ${(props) => props.height};
    line-height: ${(props) => props.height};
    color: ${(props) => props.color};
    background-color: ${(props) => props.selected && "var(--skyBlue)"};
    padding-left: 12px;
    text-decoration: ${(props) => props.selected && "underline"};
    font-size: 12px;
    font-weight: ${(props) => props.weight};

    > .title {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    cursor: pointer;
    > .arrow {
      width: 30px;
      height: 30px;
      transform: ${(props) =>
        props.isFold
          ? "rotate(-90deg) translate(3px, 0px);"
          : "translate(0px, 2px)"};
      /* transition: 0.1s all; */
    }
  }

  border-bottom: ${(props) => props.border} 2px var(--eciBlue);

  > .childContainer {
    border-top: solid 1px var(--textGray);
    padding-left: 24px;
  }
  .blank {
    width: 18px;
  }
`;

export default function ClassificationSelectTree({
  child,
  title,
  last,
  depth,
  id,
  data,
  treeId,
  onClick,
  currentState,
  isOpen,
  setIsOpen,
}) {
  const toggleOpen = () => {
    setIsOpen(treeId, !isOpen[treeId]);
  };

  const childList =
    child &&
    child.map((object, i) => {
      return (
        <ClassificationSelectTree
          key={`${object.id}${depth}`}
          depth={depth + 1}
          title={object.name}
          child={object.children}
          last={object.last}
          id={object.id}
          data={object}
          treeId={`${treeId}-${object.id}`}
          onClick={onClick}
          currentState={currentState}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      );
    });

  return (
    <ClassificationSelectTreeStyle
      isFold={!isOpen[treeId]}
      selected={
        currentState && currentState?.classification === data.classification
      }
      height={depth > 0 ? "32px" : "44px"}
      weight={depth > 0 ? 100 : 600}
      border={depth > 0 ? "" : "solid"}
      color={
        last && currentState && currentState?.name !== data.name
          ? "var(--textDarkGray)"
          : "var(--eciBlue)"
      }
    >
      <div
        className="titlepb"
        onClick={() => {
          toggleOpen();
          onClick({ ...data, depth });
        }}
        title={title}
      >
        {!last ? (
          <div className="arrow material-icons">expand_more</div>
        ) : (
          <div className="blank" />
        )}
        <div className="title">{title}</div>
      </div>
      {isOpen[treeId] && <div className="childContainer">{childList}</div>}
    </ClassificationSelectTreeStyle>
  );
}
