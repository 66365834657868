import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ScrollContainer from "../../organism/ScrollContainer";
import AttachmentSection from "../../organism/AttachmentSection";
import Button from "../../atom/Button";
import RouteInitSection from "../../organism/RouteInitSection";

import RouteSection from "../../organism/RouteSection";

import { PageStyle, SectionGap } from "../../Style";
import { conditionByTypeObject } from "../../utility/Source";

import crStore from "../../store/crStore";
import CrInformationSection from "../organism/CrInformationSection";
import { appendCrForm, useGetcrData } from "../utility";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import useSaveFunction from "../../utility/hooks/useSaveFunction";
import useTempSaveFunction from "../../utility/hooks/useTempSaveFunction";
import TreeModal from "../../organism/TreeModal/TreeModal";
import SearchBoxGroup from "../../organism/SearchBoxGroup";

export default function CrPage() {
  const tokenAxios = useTokenAxios();

  const params = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const phase = searchParam.get("phase") * 1 || 1;

  const navigate = useNavigate();
  //    ---MODEL
  const store = crStore();
  const { type } = params;
  //    ---VIEW
  const classificationConfigureFunc = (data) => {
    const tmpTree = data.filter(
      (obj) =>
        obj.name === "Product" || obj.name === "Assembly" || obj.name === "Part"
    );
    // elec assembly에서 mpba제거
    const assemblyTree =
      tmpTree[tmpTree.findIndex((obj) => obj.name === "Assembly")];
    const electronicAssemblyIndex = assemblyTree.children.findIndex(
      (obj) => obj.name === "Electronic Assembly"
    );
    const electronicAssemblyTree =
      assemblyTree.children[electronicAssemblyIndex];

    electronicAssemblyTree.children = electronicAssemblyTree.children.filter(
      (obj) => obj.name !== "Master PBA"
    );
    const partTree = tmpTree[tmpTree.findIndex((obj) => obj.name === "Part")];
    partTree.children = partTree.children.filter(
      (obj) => obj.name === "Software Part"
    );
    return tmpTree;
  };
  //    ---ACTION
  const saveFunc = useSaveFunction(
    "cr",
    appendCrForm,
    store,
    conditionByTypeObject[type].saveFuncType === "put",
    params.id
  );

  const saveTempFunc = useTempSaveFunction(
    "cr",
    appendCrForm,
    store,
    conditionByTypeObject[type].saveFuncType === "put",
    params.id
  );

  //  ---BUSINESS LOGIC

  const getCrData = useGetcrData(params.id, store);

  useEffect(() => {
    store.setisRouteActive(
      phase > 1 || conditionByTypeObject[type].isRouteActive
    );

    store.setid(params.id);

    if (params.id !== "new") {
      getCrData();
    }

    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);

  return (
    <PageStyle>
      <TreeModal
        url="cr/item-classification"
        treeConfigure={classificationConfigureFunc}
        isActivate={store.isModal}
        setisActivate={store.setisModal}
        applyFunction={(finalItemList) => {
          store.setStateByKey("affectedModuleId")(finalItemList[0]);
          store.setStateByKey("classificationId")(finalItemList[0]);
        }}
        checkOneOnly
        indexArray={["", "Item Number", "Item Name", "Revision"]}
        indexArray2={["Item Number", "Item Name", "Revision"]}
        itemArray2={["number", "name", "revision"]}
      />
      <ScrollContainer
        isWideScroll={conditionByTypeObject[type].isScrollWide}
        scrollRefList={[
          [store.informationRef, "CR  Information"],
          [store.attachmentRef, "CR  Attachment"],
          [store.routeRef, "Route Information"],
        ]}
        searchBoxComponent={<SearchBoxGroup store={store} />}
      >
        <CrInformationSection readOnly={store.isRouteActive} />
        <SectionGap />

        <AttachmentSection
          editMode={conditionByTypeObject[type].isAttachmentEdit}
          title="Change Request Attachment"
          readOnly={store.isRouteActive}
          moduleStore={store}
        />
        <SectionGap />
        {!store.isRouteActive && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveFunc}
            condition={store.getisConditionFullfill()}
          >
            Save and Route
          </Button>
        )}
        {store.isRouteActive &&
          (conditionByTypeObject[type].routeSectionType === "init" &&
          phase <= 1 ? (
            <RouteInitSection
              activate
              routeType="Cr"
              itemId={store.id}
              moduleStore={store}
              afterUrl="/"
            />
          ) : (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={store}
            />
          ))}
        <SectionGap />
      </ScrollContainer>
    </PageStyle>
  );
}
