import React, { useState, useEffect } from "react";

import styled from "styled-components";

const TeamTreeStyle = styled.div`
  width: 600px;
  position: relative;
  transition: 0.3s;
  span {
    cursor: pointer;
  }
  .numberRow {
    display: flex;
    align-items: top;
  }
  .indentBox {
    margin-left: ${(props) => props.indent};
  }
  .blank {
    width: 25px;
  }
`;
const TeamRowStyle = styled.div`
  color: ${(props) => (props.blue ? "var(--eciBlue)" : "var(--textDarkGray)")};
  font-weight: 600;
  transform: translate(0, -5px);
  cursor: pointer;
`;
export default function TeamTree({
  data,
  indent,
  indexArray,
  state,
  setstate,
  deleteunfold,
  lastChild,
  allFold,
  unfoldArray,
  setunfold,
}) {
  const children = [
    data.c2SelectDtos,
    data.c3SelectDtos,
    data.c4SelectDtos,
  ].filter((item) => item && item[0]);
  const childTree =
    children[0] &&
    children[0].map((item, i) => {
      return (
        <TeamTree
          key={i}
          data={item}
          indent={indent}
          indexArray={[...indexArray, i]}
          state={state}
          setstate={setstate}
          unfoldArray={unfoldArray}
          setunfold={setunfold}
          deleteunfold={deleteunfold}
          lastChild={i + 1 === children[0].length && i >= 0}
          allFold
        />
      );
    });
  const [isToggle, setisToggle] = useState(allFold);

  const fold = () => {
    setisToggle(!isToggle);
    if (isToggle === true) {
      setunfold(indexArray);
    } else {
      deleteunfold(indexArray);
    }
  };
  useEffect(() => {
    const unfolded =
      unfoldArray && unfoldArray.map((item) => JSON.stringify(item));
    if (unfolded && unfolded.indexOf(JSON.stringify(indexArray)) > 0) {
      setisToggle(false);
    }
  }, [unfoldArray]);
  return (
    <TeamTreeStyle indent={indent} isToggle={isToggle}>
      {indexArray.length !== 1 && <div className="horizon" />}
      {!isToggle && !!children[0] && !(data.last === 1) && (
        <div className="vertical" />
      )}
      {lastChild && <div className="eraseVertical" />}

      <div className="numberRow">
        <button
          style={{ opacity: data.last === 1 ? 0 : 1 }}
          type="button"
          className="foldButton"
          onClick={data.last ? () => {} : fold}
        >
          {isToggle ? (
            <span className="material-icons box foldSpan">add_box</span>
          ) : (
            <span className="material-icons box minus foldSpan">
              indeterminate_check_box
            </span>
          )}
        </button>

        <TeamRowStyle
          blue={state && state.value === data.value}
          onClick={() => {
            // if (data.last === 1) {
            setstate({
              value: data.value,
              classification: data.classification,
            });
            // }
          }}
          color={data.name === state ? "var(--eciBlue)" : "#04080F80"}
        >
          {data.name}
        </TeamRowStyle>
      </div>
      {children[0] && !isToggle ? (
        <div className="indentBox"> {childTree} </div>
      ) : (
        ""
      )}
    </TeamTreeStyle>
  );
}
