import React, { useEffect, useRef } from "react";
import LineTitle from "../../atom/LineTitle";
import AnimationDateInput from "../../molecule/AnimationDateInput";
import InputGrid from "../../organism/InputGrid";
import PCBStore from "../../store/PCBStore";
import {
  BoldBlueTextStyle,
  GridItemFlexContainer,
  PageStyle,
} from "../../Style";
import txt from "../../Text";

export default function PCBInformationSection2({ readOnly }) {
  const { stateObj, setStateByKey, setPCBInformationRef } = PCBStore();

  const PCBstore = PCBStore();
  const PCBInformationRef = useRef();

  useEffect(() => {
    setPCBInformationRef(PCBInformationRef);
  }, []);
  return (
    <PageStyle ref={PCBInformationRef}>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        PCB Information
      </LineTitle>
      <GridItemFlexContainer width="50%">
        <BoldBlueTextStyle fontSize="13px" height="40px" margin="0 8px 0 0">
          {txt.mpba.completeDate}
        </BoldBlueTextStyle>

        <AnimationDateInput
          width="40%"
          height="40px"
          placeholder=" "
          state={stateObj.startDate ? stateObj.startDate : ""}
          setState={setStateByKey("startDate")}
          readOnly={readOnly}
        />
      </GridItemFlexContainer>
    </PageStyle>
  );
}
