import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ScrollDefault } from "../../Style";

const TodoListContainerStyle = styled.div`
  @keyframes initialScaleChange {
    0% {
      opacity: 0;
      transform: scaleY(90%);
    }
    25% {
      opacity: 1;
    }

    100% {
      transform: scaleY(100%);
    }
  }
  border: solid 2px var(--eciBlue);
  border-radius: 8px;
  height: ${(props) => props.height}px;
  width: 100%;
  ${ScrollDefault}
  overflow-y: hidden;
  display: flex;
  padding: 12px;
  padding-top: 20px;
  animation-name: initialScaleChange;
  animation-duration: 0.8s;
  cursor: default;
`;
const Border = styled.div`
  padding-bottom: 100px;
  cursor: pointer;
`;
export default function TodoListContainer({ children }) {
  const ref = useRef(null);
  const [height, setheight] = useState(260);
  const [mouseMoveFlag, setmouseMoveFlag] = useState(false);
  const [top, settop] = useState(0);
  const onMouseDown = (e) => {
    setmouseMoveFlag(true);
  };
  const onMouseUp = (e) => {
    setmouseMoveFlag(false);
  };

  const onMouseMove = (e) => {
    setheight(e.pageY - top > 200 ? e.pageY - top : 200);
  };
  const onMouseLeave = (e) => {
    setmouseMoveFlag(false);
  };
  useLayoutEffect(() => {
    settop(ref.current?.getBoundingClientRect().top);
  }, []);
  return (
    <Border
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={mouseMoveFlag ? onMouseMove : () => {}}
      onMouseLeave={onMouseLeave}
      ref={ref}
    >
      <TodoListContainerStyle
        initialAnimation
        height={height}
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </TodoListContainerStyle>
    </Border>
  );
}
