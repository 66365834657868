import { useEffect, useState } from "react";
import useTokenAxios from "../../utility/hooks/useTokenAxios";

export const useHandleItemList = ({ url, selectedItemClassificationObj }) => {
  const tokenAxios = useTokenAxios();

  const [rowsPerPage, setrowsPerPage] = useState(6);
  const [pageNum, setpageNum] = useState(0);
  const [searchText, setsearchText] = useState("");
  const [totalPage, settotalPage] = useState(0);
  const [secondItemList, setsecondItemList] = useState([]);

  const getModalList = async (id) => {
    const response = await tokenAxios.get(
      //   `release/product-page/${selectedItemClassification.classificationId}?size=${rowsPerPage}&page=${pageNum}&name=${searchText}`
      `${url}/${id}?size=${rowsPerPage}&page=${pageNum}&name=${searchText}`
    );
    console.log(response);
    setsecondItemList(
      response.data?.content || response.data.result?.data.content || []
    );
    settotalPage(response?.data?.totalPages || 1);
  };

  useEffect(() => {
    if (selectedItemClassificationObj.id) {
      getModalList(selectedItemClassificationObj.classificationId);
    }
  }, [selectedItemClassificationObj, pageNum, rowsPerPage, searchText]);

  return {
    secondItemList,
    rowsPerPage,
    setrowsPerPage,
    totalPage,
    searchText,
    setsearchText,
    pageNum,
    setpageNum,
  };
};
export const useHandleClassificationTree = (treeConfigure) => {
  const tokenAxios = useTokenAxios();
  const [classificationTree, setclassificationTree] = useState([]);

  const getClassificationTree = async () => {
    const response = await tokenAxios.get("classification-tree");
    let treeUrl = response.data.result?.data;
    if (treeConfigure) {
      treeUrl = treeConfigure(treeUrl);
    }
    setclassificationTree(treeUrl);
  };

  useEffect(() => {
    getClassificationTree();
  }, []);
  return { classificationTree };
};

export const useModelAction = () => {
  const [tempCheckedList, settempCheckedList] = useState([]);
  const [finalItemList, setfinalItemList] = useState([]);
  const [selectedItemClassificationObj, setselectedItemClassificationObj] =
    useState({});

  //  ---ACTION
  const setClassificationObj = setselectedItemClassificationObj;

  const addItemToTempChecked = (val, checkOneOnly) => {
    if (checkOneOnly) {
      settempCheckedList([val]);
    } else {
      const tmpArr = [...tempCheckedList];
      const targetIndex = tmpArr.findIndex((itemObj) => itemObj.id === val.id);
      if (targetIndex > -1) {
        tmpArr.splice(targetIndex, 1);
      } else {
        tmpArr.push(val);
      }
      settempCheckedList(tmpArr);
    }
  };

  const applyTempToThird = () => {
    setfinalItemList(
      tempCheckedList.map((obj) => ({
        ...obj,
        classificationName: selectedItemClassificationObj.name,
      }))
    );
  };
  const deleteFromTemp = (id) => {
    settempCheckedList((state) => state.filter((itemObj) => itemObj.id !== id));
  };
  const deleteFromFinal = (id) => {
    setfinalItemList((state) => state.filter((itemObj) => itemObj.id !== id));
  };

  const initModel = () => {
    setselectedItemClassificationObj({});
    settempCheckedList([]);
    setfinalItemList([]);
  };

  useEffect(() => {
    return initModel;
  }, []);

  return {
    model: { tempCheckedList, finalItemList, selectedItemClassificationObj },
    action: {
      addItemToTempChecked,
      applyTempToThird,
      setClassificationObj,
      initModel,
      deleteFromTemp,
      deleteFromFinal,
    },
  };
};
