import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { flushSync } from "react-dom";
import styled from "styled-components";
import ItemClassificationTree from "../molecule/ItemClassificationTree";
import { PageStyle, PropIndexStyle } from "../../Style";

import ClassificationPropRow from "../molecule/ClassificationPropRow";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import txt from "../../Text";

const ItemClassificationPageStyle = styled(PageStyle)`
  width: 100%;
  .indexContainer {
    display: flex;

    .index {
      display: flex;
      overflow: hidden;
      height: 30px;
      ::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    .leftIndex {
      width: 35%;
      margin: 0 10px;
    }
    .rightIndex {
      width: 65%;
    }
    .container {
      display: flex;
    }
  }

  .columnScroll {
    display: flex;
    border-top: var(--eciBlue) solid 1px;
    max-height: 50vh;
    overflow: scroll;
    position: relative;
    ::-webkit-scrollbar {
      width: 5px;
      height: 0px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 15px solid transparent;
      width: 5px;
      background: rgba(4, 8, 15, 0.3);
      border-radius: 8px;
    }
  }
  .scrollBarBottom {
    display: flex;
  }
  .scrollBarContainer {
    width: 100%;
    overflow-x: scroll;
    padding-bottom: 5px;
    border: 0px !important;
    ::-webkit-scrollbar {
      width: 5px;
      height: 10px;
      padding: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 15px solid transparent;
      width: 5px;
      background: rgba(4, 8, 15, 0.3);
      border-radius: 8px;
    }
  }
  .left {
    margin-right: 10px;
    width: 35%;
    border-right: solid var(--textGray) 3px;
    height: max-content;
  }

  .leftBackGround {
    width: max-content;
  }
  .rightBackGround {
    background-color: white;
    width: max-content;
  }
  .rowScroll {
    overflow: hidden;
    height: max-content;
    padding-bottom: 5px;
    ::-webkit-scrollbar {
      height: 0px;
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 15px solid transparent;
      width: 5px;
      background: rgba(4, 8, 15, 0.3);
      border-radius: 8px;
    }
  }
  .right {
    width: 65%;
  }
`;
export default function AdminItemClassificationPage() {
  const tokenAxios = useTokenAxios();

  const [isWriting, setisWriting] = useState(false);
  const [treeData, settreeData] = useState([]);
  const [titleState, settitleState] = useState("");
  const [titleCode, settitleCode] = useState("");
  const [parentId, setparentId] = useState("");
  const [propIndex, setpropIndex] = useState([]);
  const propIndexList = [
    ...(propIndex &&
      propIndex
        .map((item) => item.name)
        .map((item) => {
          return (
            <PropIndexStyle key={item} width="120px" title={item}>
              {item}
            </PropIndexStyle>
          );
        })),
    <PropIndexStyle key="qwerty" width="120px">
      {" "}
    </PropIndexStyle>,
  ];

  //    right array & ui
  const [rightArray, setrightArray] = useState(
    treeData &&
      treeData.map((object, i) => {
        const newObj = { ...object };
        delete newObj.children;
        newObj.readOnly = true;
        newObj.buttonType = false;
        newObj.treeId = `${i}`;

        return newObj;
      })
  );

  const appendRightArray = (id, ...val) => {
    const changeFunc = (rightarray) => {
      const newArr = [...rightarray];

      const index = newArr.findIndex((obj) => obj.treeId === id);

      newArr.splice(index + 1, 0, ...val);
      return newArr;
    };
    flushSync(() => {
      setrightArray(changeFunc);
    });
  };
  const deleteRightArray = (idArr, i = 1) => {
    // [1,2,3] => id가 1,2,3인 애들 삭제
    // [1],3 => id 1에서부터 3개 삭제
    const deleteFunc = (rightarray) => {
      const newArr = [...rightarray];
      if (i > 1) {
        newArr.splice(
          newArr.findIndex(
            (obj) => obj.treeId * 1 === idArr[0] * 1 || obj.treeId === idArr[0]
          ) + 1,
          i
        );
      } else {
        idArr.forEach((num) => {
          if (newArr.findIndex((obj) => obj.treeId === num) > 0) {
            newArr.splice(
              newArr.findIndex((obj) => obj.treeId === num),
              i
            );
          }
        });
      }

      return newArr;
    };
    setrightArray(deleteFunc);
  };

  const changeRightArray = (id, target, val) => {
    const changeFunc = (array) => {
      const newArr = [...array];
      newArr[array.findIndex((obj) => obj.treeId === id)][target] = val;
      return newArr;
    };

    setrightArray(changeFunc);
  };
  const changeTreeData = (treeId, callBack) => {
    const changeFunc = (array) => {
      const newTreeArr = [...array];
      const indexArr = treeId?.split(",");
      const lastIndex = indexArr.pop();
      let curArr = newTreeArr[0].children;
      indexArr.forEach((id, i) => {
        if (i > 0) {
          curArr = curArr[id]?.children;
        }
      });
      const newTree = callBack(curArr, lastIndex);
      return newTreeArr;
    };

    settreeData(changeFunc);
  };
  const rightTree = rightArray.map((item, j) => {
    // rightArray로 useMemo
    return (
      <ClassificationPropRow
        key={item.id}
        id={item.id}
        parentId={parentId}
        readOnly={isWriting}
        propsArray={item.property}
        buttonType={item.buttonType}
        propIndex={propIndex}
        name={titleState}
        titleCode={titleCode}
        depth={item.depth}
        classId={item.classificationId}
        propertyArr={item.property}
        treeData={item}
        setisWriting={setisWriting}
        changeRightArray={changeRightArray}
        changeTreeData={changeTreeData}
        thick={item.thick}
      />
    );
  });
  //    left tree
  const leftTree =
    treeData &&
    treeData.map((item, i) => {
      return (
        <ItemClassificationTree
          key={item.id}
          data={item}
          child={item.children}
          title={item.name}
          code={item.code}
          last={item.last}
          id={item.id}
          classificationId={item.classificationId}
          classification={item.classification}
          depth={1}
          isWriting={isWriting}
          setisWriting={setisWriting}
          titleState={titleState}
          settitleState={settitleState}
          titleCode={titleCode}
          settitleCode={settitleCode}
          parentIdState={parentId}
          setparentIdState={setparentId}
          appendRightArray={appendRightArray}
          changeRightArray={changeRightArray}
          deleteRightArray={deleteRightArray}
          treeId={`${i}`}
        />
      );
    });
  const getData = async () => {
    const response = await tokenAxios.get("classification-tree");
    const tmp = response.data.result.data.filter((obj) => obj.name === "Part");
    tmp[0].children = tmp[0].children.filter(
      (obj) => obj.name !== "Software Part"
    );
    settreeData(tmp);
    setrightArray(
      response.data.result.data &&
        response.data.result.data
          .filter((obj) => obj.name === "Part")
          .map((object, i) => {
            const newObj = { ...object };
            delete newObj.children;
            newObj.readOnly = true;
            newObj.buttonType = false;
            newObj.treeId = `${i}`;

            return newObj;
          })
    );
  };
  const getpropIndex = async () => {
    // propIndexFunc(treeData)
    try {
      const response = await tokenAxios.get("properties");
      setpropIndex(response.data.result.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getpropIndex();
  }, []);
  //    scroll일치
  const [leftScrollWidth, setleftScrollWidth] = useState(0);
  const [rightScrollWidth, setrightScrollWidth] = useState(0);
  const leftBackgroundRef = useRef(null);
  const rightBackgroundRef = useRef(null);

  const leftRef = useRef(null);
  const leftIndexRef = useRef(null);
  const rightRef = useRef(null);
  const rightIndexRef = useRef(null);

  const leftScrollBarRef = useRef(null);
  const rightScrollBarRef = useRef(null);

  useLayoutEffect(() => {
    setleftScrollWidth(leftBackgroundRef.current.offsetWidth);
    setrightScrollWidth(rightBackgroundRef.current.offsetWidth);
  });
  return (
    <ItemClassificationPageStyle>
      <div className="indexContainer">
        <div
          className="leftIndex index"
          ref={leftIndexRef}
          onScroll={(e) => {
            e.preventDefault();
          }}
        >
          <div className="container">
            <PropIndexStyle width="200px">1{txt.admin.step}</PropIndexStyle>
            <PropIndexStyle width="200px">2{txt.admin.step}</PropIndexStyle>
            <PropIndexStyle width="200px">3{txt.admin.step}</PropIndexStyle>
            <PropIndexStyle width="200px">4{txt.admin.step}</PropIndexStyle>
            <PropIndexStyle width="200px">5{txt.admin.step}</PropIndexStyle>
          </div>
        </div>
        <div
          className="rightIndex index"
          ref={rightIndexRef}
          onScroll={(e) => {
            e.preventDefault();
          }}
        >
          {propIndexList}
        </div>
      </div>
      <div className="columnScroll">
        <div
          className="left rowScroll"
          ref={leftRef}
          onScroll={(e) => {
            e.preventDefault();
          }}
          onWheel={(e) => e.preventDefault()}
        >
          <div className="leftScrollGetter" />
          <div className="leftBackGround" ref={leftBackgroundRef}>
            {leftTree}
          </div>
        </div>

        <div
          className="right rowScroll"
          ref={rightRef}
          onScroll={(e) => {
            e.preventDefault();
          }}
        >
          <div className="rightBackGround" ref={rightBackgroundRef}>
            {rightTree}
          </div>
        </div>
      </div>
      <div className="scrollBarBottom">
        <div
          className="left scrollBarContainer"
          ref={leftScrollBarRef}
          onScroll={(e) => {
            leftIndexRef.current.scroll({
              left: e.target.scrollLeft,
            });
            leftRef.current.scroll({
              left: e.target.scrollLeft,
            });
          }}
        >
          <div
            className="scrollBarBackground"
            style={{ width: `${leftScrollWidth}px` }}
          />
        </div>
        <div
          className="right scrollBarContainer"
          ref={rightScrollBarRef}
          onScroll={(e) => {
            rightIndexRef.current.scroll({
              left: e.target.scrollLeft,
            });
            rightRef.current.scroll({
              left: e.target.scrollLeft,
            });
          }}
        >
          <div
            className="scrollBarBackground "
            style={{ width: `${rightScrollWidth}px` }}
          />
        </div>
      </div>
    </ItemClassificationPageStyle>
  );
}
