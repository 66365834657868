import React from "react";
import styled from "styled-components";

const LockButtonStyle = styled.div`
  border-radius: 4px;
  border: solid var(--eciBlue) 1px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.backgroundColor};
  div {
    color: ${(p) => p.color};
  }
  cursor: pointer;
`;
export default function LockButton({ onClick, isLock }) {
  return (
    <LockButtonStyle
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      backgroundColor={isLock ? "var(--eciBlue)" : "var(--lightGray)"}
      color={isLock ? "white" : "var(--eciBlue)"}
    >
      <div className="material-icons">{isLock ? "lock" : "lock_open"}</div>
    </LockButtonStyle>
  );
}
