import React from "react";
import styled from "styled-components";

const CheckBoxStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  color: var(--eciBlue);
  pointer-events: ${(props) => props.readOnly && "none"};
  .title {
    font-size: 12px;
  }

  .check {
    display: flex;
    width: 50%;
    height: 35px;
    line-height: 35px;
    div {
      font-size: 12px;
    }
  }

  .checkListContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .required {
    width: 5px;
    height: 5px;
    border-radius: 10px;
    margin-left: 3px;
    display: inline-block;
    background-color: var(--required);
  }
`;
export default function CheckBox({
  width,
  height,
  state,
  setstate,
  required,
  title,
  checkList,
  readOnly,
}) {
  // state를 array로 받고, 그 array에다가 선택된 애들 value를 넣어주자
  // [{id,name},{id,name]]
  const onCheckedElement = (checked, item) => {
    if (checked) {
      setstate([...state, item]);
    } else if (!checked) {
      setstate(state.filter((el) => el !== item));
    }
  };
  const checkBoxList =
    checkList &&
    checkList.map((item, index) => {
      return (
        <div className="check" key={index}>
          {/* <label htmlFor={`cb${index}`}> */}
          <input
            checked={state && state.indexOf(item.id) !== -1}
            type="checkbox"
            id={`cb${index}`}
            value={item.id}
            onChange={(e) => {
              onCheckedElement(e.target.checked, item.id);
            }}
          />
          {/* </label> */}
          <div>{item.name}</div>
        </div>
      );
    });
  return (
    <CheckBoxStyle width={width} height={height} readOnly={readOnly}>
      <div className="title">
        {title} {required && <div className="required" />}
      </div>
      <br />
      <div className="checkListContainer">{checkBoxList}</div>
    </CheckBoxStyle>
  );
}
