import React from "react";
import styled from "styled-components";
import SelectBox from "../../atom/SelectBox";

const ViewSelectboxStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .view {
    color: var(--textDarkGray);
    font-size: 13px;
  }
`;
export default function ViewSelectbox({
  state,
  setstate,
  optionArr = [],
  title = "View",
  children,
}) {
  return (
    <ViewSelectboxStyle>
      <div className="view">{title}</div>
      <SelectBox
        height="30px"
        setState={setstate}
        backgroundColor="white"
        color="var(--textDarkGray)"
        fontSize="13px"
        state={state}
        defaultValue={optionArr[0]}
      >
        {optionArr.map((str, i) => (
          <option key={i} value={str}>
            {str}
          </option>
        ))}
      </SelectBox>
      {children}
    </ViewSelectboxStyle>
  );
}
