import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ScrollContainer from "../../organism/ScrollContainer";
import AttachmentSection from "../../organism/AttachmentSection";
import Button from "../../atom/Button";
import RouteInitSection from "../../organism/RouteInitSection";

import RouteSection from "../../organism/RouteSection";
import InputGrid from "../../organism/InputGrid";

import {
  appendElectronicPartForm,
  appendLibraryForm,
  useGetElectronicPartData,
  useGetLibraryData,
} from "../utility";
import { conditionByTypeObject } from "../../utility/Source";
import { PageStyle, SectionGap } from "../../Style";

import electronicPartStore from "../../store/electronicPartStore";

import ElectronicPartInformationSection from "../organism/ElectronicPartInformation";
import ElectronicPartInformation2 from "../organism/ElectronicPartInformation2";
import LibraryInformation from "../organism/LibraryInformation";
import SymbolFootprintSearchBox from "../organism/SymbolFootprintSearchBox";
import WhereUseSection from "../organism/WhereUseSection";
import PopupImgBox from "../molecule/PopupImgBox";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import useSaveFunction from "../../utility/hooks/useSaveFunction";
import useTempSaveFunction from "../../utility/hooks/useTempSaveFunction";
import SearchBoxGroup from "../../organism/SearchBoxGroup";
import txt from "../../Text";
import { getFootprintSource, getSymbolSource } from "../../utility/Utility";
import { userStore } from "../../store/commonStore";

export default function ElectronicPartPage() {
  // ---CONST
  const tokenAxios = useTokenAxios();
  const params = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const phase = searchParam.get("phase") * 1 || 1;
  const noRoute = searchParam.get("noRoute") || false;
  const { type } = params;
  const navigate = useNavigate();

  // ---MODEL
  const {
    setid,
    isRouteActive,
    setisRouteActive,
    dataSearchBoxType,
    searchBoxType,
    categorySearchBoxType,

    stateObj,
    setStateByKey,

    // ref
    informationRef,
    attachmentRef,
    routeRef,
  } = electronicPartStore();
  const electronicPartstore = electronicPartStore();
  // ---VIEW
  const isInfoSectionReadOnly = (phase > 1 || isRouteActive) && !noRoute;
  const isInfoSection2Active = // 구매속성 파트
    phase > 1 &&
    electronicPartstore.stateObj.type * 1 === 1 &&
    !electronicPartstore.isTestPoint &&
    phase < 5; // Request ready...는 구매속성 아직 안적힌 상태
  //  조건 추가 : test-point면 구매속성 없음.
  const isInfoSection2ReadOnly = (isRouteActive || phase > 2) && !noRoute;
  const isAttachmentReadOnly = (isRouteActive || phase >= 3) && !noRoute;
  const isLibrarySectionActive = phase > 2 && !electronicPartstore.isTestPoint;
  const isLibrarySectionReadOnly =
    ((isRouteActive || phase >= 4) && !noRoute) ||
    electronicPartstore.isSwitching;
  const isWhereUseSectionActive = phase >= 4 && !noRoute && type === "detail";
  const isAttachmentEdit =
    conditionByTypeObject[type].isAttachmentEdit || phase > 1 || noRoute;
  const isSaveButtonActive = electronicPartstore.getisConditionFullfill(phase);
  const [switchRouteList, setswitchRouteList] = useState([
    "Request replacement with normal part",
    "Item Purchase Related Properties",
  ]);

  // --- ACTION

  const appendNewNumber = (formData) => {
    formData.append("itemId", params.id);

    return formData;
  };
  const saveFunc = useSaveFunction(
    phase >= 3 ? "library" : "item",
    phase >= 3 ? appendLibraryForm : appendElectronicPartForm,
    electronicPartstore,
    conditionByTypeObject[type].saveFuncType === "put" || phase === 2,
    phase >= 3 ? electronicPartstore.library?.id : params.id,
    "",
    () => {
      if (phase === 2) {
        if (
          !(
            (!!stateObj.price && stateObj.price !== " ") ||
            stateObj.tentativePrice
          )
        ) {
          alert(txt.elec.alertPriceTentative);
          throw new Error();
        }
      }
    }
  );

  const saveTempFunc = useTempSaveFunction(
    phase >= 3 ? "library" : "item",
    phase >= 3 ? appendLibraryForm : appendElectronicPartForm,
    electronicPartstore,
    conditionByTypeObject[type].saveFuncType === "put",
    phase >= 3 ? electronicPartstore.library?.id : params.id
  );
  const saveFuncForNoRouteLibrary = useSaveFunction(
    "library",
    appendLibraryForm,
    electronicPartstore,
    true,
    electronicPartstore.library?.id || "nolibrary"
  );
  const saveFuncForNoRouteItem = useSaveFunction(
    "item",
    appendElectronicPartForm,
    electronicPartstore,
    true,
    params.id
  );
  const saveWithNoRouteFunc = async () => {
    await saveFuncForNoRouteItem();
    if (electronicPartstore.library?.id) {
      await saveFuncForNoRouteLibrary();
    }
    electronicPartstore.setisRouteActive(false);
    alert("done!");
    navigate("/");
  };
  const onSwitchButton = async () => {
    const response = await tokenAxios.put(`formal/${electronicPartstore.id}`);
    electronicPartstore.setStateByKey("type")("1");
    electronicPartstore.setStateByKey("tmpNumber")(
      electronicPartstore.stateObj.itemNumber
    );
    electronicPartstore.setStateByKey("itemNumber")(response.data?.result.data);
    electronicPartstore.setisRouteActive(true);
    electronicPartstore.setisSwitching(true);
  };
  // ---BL
  const getJsonData = async () => {
    const classId =
      electronicPartstore.stateObj.classificationId?.id ||
      searchParam.get("classId");
    if (classId) {
      const response = await tokenAxios.get(`attributes/${classId}`);
      setStateByKey("property")(response.data.result.data);
    }
  };
  const { userData } = userStore();
  const getElectronicPartData = useGetElectronicPartData(
    params.id,
    electronicPartstore,
    getJsonData
  );
  const getLibraryData = useGetLibraryData(params.id, electronicPartstore);
  useEffect(() => {
    setisRouteActive(conditionByTypeObject[type].isRouteActive);
    setid(params.id);
    if (params.id === "new") {
      setStateByKey("classificationId")({
        classId: searchParam.get("classId"),
        name: searchParam.get("classificationName"),
      });
    }

    if (params.id !== "new") {
      getElectronicPartData();
    } else {
      getJsonData();
    }
    if (phase >= 3) {
      getLibraryData();
    }
    if (phase > 4) {
      // request replacement 단계
      electronicPartstore.setisSwitching(true);
      setswitchRouteList(["Item Purchase Related Properties"]);
    }
    if (searchParam.get("classificationName")?.indexOf("TESTPOINT") > -1) {
      electronicPartstore.setisTestPoint(true);
    }
    return () => {
      electronicPartstore.initDefaultStore();
      electronicPartstore.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  useEffect(() => {
    if (
      searchParam.get("classificationName")?.indexOf("Electronic") > -1 ||
      stateObj.classificationId?.value?.indexOf("Electronic") > -1
    ) {
      electronicPartstore.setattachmentTagOptionList([
        { id: 2, name: "ETC" },
        { id: 6, name: "Datasheet", option: 1 },
        { id: 7, name: txt.elec.credibilityReport },
      ]);
    }
    if (
      searchParam.get("classificationName")?.indexOf("Mechanical") > -1 ||
      stateObj.classificationId?.value?.indexOf("Mechanical") > -1
    ) {
      electronicPartstore.setisMech(true);
    }
  }, [stateObj.classificationId]);

  return (
    <PageStyle>
      <ScrollContainer
        scrollRefList={[
          [informationRef, "Item Information"],
          [attachmentRef, "Item Attachment"],
          [routeRef, "Route Information"],
        ]}
        type={
          electronicPartstore.searchBoxType.lastIndexOf("-J-") > 0
            ? electronicPartstore.searchBoxType.replace("-J-", "")
            : electronicPartstore.searchBoxType
        }
        setproperty={
          electronicPartstore.searchBoxType.lastIndexOf("-J-") > 0
            ? electronicPartstore.setsearchBoxPropertyForJson
            : electronicPartstore.setsearchBoxProperty
        }
        searchBoxComponent={
          <SearchBoxGroup
            store={electronicPartstore}
            additionalBoxArr={[
              <SymbolFootprintSearchBox
                key={3}
                width="100%"
                height="100vh - 218px"
                setstate={(val) => {
                  electronicPartstore.setSymbolFootprint(
                    categorySearchBoxType,
                    val
                  );
                }}
                type={categorySearchBoxType}
                targetState={
                  electronicPartstore.stateObj[categorySearchBoxType]
                }
              />,
              <PopupImgBox
                key={4}
                type={electronicPartstore.libraryPopupData?.type}
                title={
                  electronicPartstore.libraryPopupData[0]
                    ? electronicPartstore.libraryPopupData[0].name
                    : electronicPartstore.libraryPopupData?.name
                }
                imgOrigin={
                  electronicPartstore.libraryPopupData?.type === "symbol"
                    ? getSymbolSource(
                        electronicPartstore.libraryPopupData[0]?.image
                      )
                    : getFootprintSource(
                        electronicPartstore.libraryPopupData?.image
                      )
                }
                isActive={
                  electronicPartstore.libraryPopupData &&
                  !dataSearchBoxType &&
                  !searchBoxType &&
                  !categorySearchBoxType
                }
              />,
            ]}
          />
        }
        tempButtonTitle="Save as Draft"
        tempButtonOnclick={saveTempFunc}
      >
        <ElectronicPartInformationSection
          readOnly={isInfoSectionReadOnly}
          noRoute={noRoute}
          onSwitchButton={onSwitchButton}
        />
        <SectionGap />
        <InputGrid
          readOnly={isInfoSectionReadOnly}
          modulestore={electronicPartstore}
          jsonDataState={stateObj.property}
          setjsonDataState={setStateByKey("property")}
        />
        {isInfoSection2Active && userData.position.includes("구매담당자") && (
          <>
            {/* 부품구매 상세정보 */}
            <ElectronicPartInformation2 readOnly={isInfoSection2ReadOnly} />
            <SectionGap />
          </>
        )}
        <AttachmentSection
          editMode={isAttachmentEdit}
          title="Item Attachment"
          readOnly={isAttachmentReadOnly}
          moduleStore={electronicPartstore}
        />
        {isLibrarySectionActive && (
          <>
            <SectionGap />
            <LibraryInformation readOnly={isLibrarySectionReadOnly} />
          </>
        )}
        <SectionGap />
        {isWhereUseSectionActive && (
          <>
            <WhereUseSection data={electronicPartstore.whereUsed} />
            <SectionGap />
          </>
        )}

        {!isRouteActive && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={noRoute ? saveWithNoRouteFunc : saveFunc}
            condition={isSaveButtonActive}
          >
            {noRoute ? "Save" : "Save and Route"}
          </Button>
        )}
        {isRouteActive &&
          (conditionByTypeObject[type].routeSectionType === "init" &&
          phase < 2 ? (
            <RouteInitSection
              activate
              routeType="Item"
              itemId={electronicPartstore.id}
              typeId={electronicPartstore.id}
              moduleStore={electronicPartstore}
              afterUrl="/item"
            />
          ) : (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={electronicPartstore}
              routeArrayForAddingMember={
                electronicPartstore.isSwitching && switchRouteList
              }
              callBack={electronicPartstore.isSwitching && appendNewNumber}
            />
          ))}
        <SectionGap />
      </ScrollContainer>
    </PageStyle>
  );
}
