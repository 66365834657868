import React, { useEffect } from "react";
import styled from "styled-components";

const SelectBoxForClassificationContainerStyle = styled.div`
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  display: flex;
  align-items: center;

  .fillIn {
    height: 15px;
    margin-top: 5px;
    font: 8px red;
  }
  option {
    font-size: inherit;
  }
  .nav:hover {
    display: block;
  }
`;
const SelectBoxForClassificationStyle = styled.select`
  width: 100%;
  height: calc(${(props) => props.height});
  padding-left: 10px;

  background-color: ${(props) => props.backgroundColor};
  border: ${(props) =>
    props.borderColor ? `${props.borderColor}solid 1px` : "none"};
  outline: none;
  border-radius: 5px;

  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};

  color: ${(props) => props.color};
  .block {
    display: none;
  }
  ${(props) =>
    props.readOnly &&
    `-webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  pointer-events: none;
  `}
  option:hover {
    display: block;
  }
  ${(props) => props.cssStyle}
`;
// const ReadOnlyBox = styled.div`
//   width: 100%;
//   height: calc(${(props) => props.height});
//   padding-left: 10px;

//   background-color: ${(props) => props.backgroundColor};
//   border: ${(props) =>
//     props.borderColor ? `${props.borderColor}solid 1px` : "none"};
//   outline: none;
//   border-radius: 5px;

//   font-size: ${(props) => props.fontSize};
//   color: ${(props) => props.color};
//   pointer-events: none;

//   .block {
//     display: none;
//   }
// `;
export default function SelectBoxForClassification({
  width,
  height,
  backgroundColor,
  borderColor,
  fontSize,
  color,
  children,
  state,
  setState,
  requiredError,
  readOnly,
  value,
  inRef,
  defaultValue,
  fontWeight,
  cssStyle,
}) {
  const handleChange = (e) => {
    // event handler
    if (setState) {
      setState(e.target.value);
    }
  };
  useEffect(() => {}, [state, value]);
  return (
    <SelectBoxForClassificationContainerStyle
      width={width}
      height={`${height} + 20px`}
    >
      <SelectBoxForClassificationStyle
        fontWeight={fontWeight}
        ref={inRef}
        width={width}
        height={height}
        color={color}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        fontSize={fontSize}
        onChange={handleChange}
        value={state}
        placeholder=" "
        disabled={readOnly}
        title={
          typeof state === "string" || typeof state === "number" ? state : " "
        }
        cssStyle={cssStyle}
      >
        {!state && (
          <option value={value} className="block">
            {value * 1 === -1
              ? "no"
              : value * 1 === 0
              ? "required"
              : value * 1 === 1
              ? "option"
              : value * 1 === 2
              ? "specification"
              : "no"}
          </option>
        )}
        {children}
      </SelectBoxForClassificationStyle>
      {requiredError ? <div className="fillIn">fill in</div> : ""}
    </SelectBoxForClassificationContainerStyle>
  );
}
