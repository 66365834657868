import React from "react";
import styled from "styled-components";
import Input from "../atom/Input";
import { LightGrayInputContainer } from "../Style";

const LabelInputStyle = styled.div`
  width: ${(props) => props.width};
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${(props) => (props.height ? props.height : "40px")};
  .labelTitle {
    font-size: 12px;
    font-weight: 600;
    color: ${(props) =>
      props.titleColor ? props.titleColor : "var(--textDarkGray)"};
    margin-right: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .required {
    width: 5px;
    height: 5px;
    border-radius: 10px;
    margin-left: 3px;
    display: inline-block;
    background-color: var(--required);
  }
`;

export default function LabelInput({
  state,
  setstate,
  width,
  height = "40px",
  title,
  required,
  backgroundColor,
  readOnly,
  titleColor,
}) {
  return (
    <LabelInputStyle
      backgroundColor={backgroundColor}
      width={width}
      titleColor={titleColor}
    >
      <div className="labelTitle" title={title}>
        {title}
      </div>
      <LightGrayInputContainer backgroundColor={backgroundColor}>
        <Input
          height={height}
          state={state}
          setState={setstate}
          fontSize="10px"
          width="100%"
          required
          readOnly={readOnly}
          placeholder={title}
        />
      </LightGrayInputContainer>
      {required && <div className="required" />}
    </LabelInputStyle>
  );
}
