import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ViewSelectbox from "../molecule/ViewSelectbox";
import BarGraph from "../molecule/BarGraph";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import { useGetDataByPeriod } from "../utility";
import SelectBox from "../../atom/SelectBox";
import StatisticCircleBox from "../molecule/StatisticCircleBox";
import Legend from "../molecule/Legend";
import txt from "../../Text";

const GraphChangeStyle = styled.div`
  padding: 8px 20px;
  height: 50vh;
`;
const ReasonContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  /* justify-content: space-between; */
`;
const DoughnutContainer = styled.div`
  width: 280px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  position: relative;
  height: 400px;
  .title {
    font-size: 16px;
    font-weight: 600;
    color: var(--textDarkGray);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 16px;
    z-index: 10;
  }
  .graphContainer {
    margin-top: 56px;
  }
  .legendContainer {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    align-content: center;
    justify-content: baseline;
  }
`;
export default function GraphChange() {
  const tokenAxios = useTokenAxios();
  const CRCOTYPELIST = ["CR", "CO"];
  const TYPELIST = txt.dashBoard.changeGraphTypeList;
  const BARCOLORARR = [
    "rgba(76, 101, 215, 1)",
    "#5F99E7",
    "#5CC0D2",
    "#E5794C",
    "#D34161",
    "#B5662D",
    "#7D5CE4",
  ];
  const REASONOBJ = txt.dashBoard.changeGraphReasonObj;
  const ALLSTR = txt.dashBoard.all;

  // ---MODEL
  const [crcoType, setcrcoType] = useState(CRCOTYPELIST[0]);
  const [type, settype] = useState(TYPELIST[0]);
  const [reason, setreason] = useState(ALLSTR);

  const [month, setmonth, year, setyear, data] = useGetDataByPeriod(
    `home-${crcoType?.toLowerCase()}-date`
  );
  const [
    reasonMonth,
    setreasonMonth,
    reasonYear,
    setreasonYear,
    reasonBarData,
  ] = useGetDataByPeriod(`home-${crcoType?.toLowerCase()}-reason`);
  const [targetReasonData, settargetReasonData] = useState([
    {
      name: "err",
      val: {
        err: 1,
      },
    },
  ]);
  // ---VIEW
  const barGraphColorArr =
    reason === ALLSTR
      ? BARCOLORARR
      : [BARCOLORARR[Object.keys(REASONOBJ).indexOf(reason)]];
  // ---BL
  useEffect(() => {
    if (reason === ALLSTR) {
      settargetReasonData(reasonBarData.list);
    } else {
      const newData = reasonBarData.list.map((obj) => ({
        name: obj.name,
        val: { [REASONOBJ[reason]]: obj.val[reason] },
      }));
      settargetReasonData(newData);
    }
  }, [reason, reasonBarData]);
  console.log(reasonBarData.type, reason);

  return (
    <GraphChangeStyle>
      <ViewSelectbox
        state={crcoType}
        setstate={setcrcoType}
        optionArr={CRCOTYPELIST}
      >
        <SelectBox
          height="40px"
          setState={settype}
          backgroundColor="white"
          color="var(--textDarkGray)"
          fontSize="13px"
          state={type}
          defaultValue={TYPELIST[0]}
        >
          {TYPELIST.map((str, i) => (
            <option key={i} value={str}>
              {str}
            </option>
          ))}
        </SelectBox>
      </ViewSelectbox>
      {type === TYPELIST[0] && (
        <BarGraph
          borderIndex={13 - month}
          data={data.list}
          colorArr={["rgba(196, 196, 196, 1)"]}
          periodObj={{ month, year, setmonth, setyear }}
        />
      )}
      {type === TYPELIST[1] && (
        <ReasonContainer>
          <DoughnutContainer>
            <div className="title">
              {`${crcoType} ${txt.dashBoard.reason}`}
              <SelectBox
                height="24px"
                width="160px"
                setState={setreason}
                backgroundColor="var(--lightGray)"
                color="var(--textDarkGray)"
                fontSize="14px"
                state={reason}
                defaultValue={ALLSTR}
              >
                {[ALLSTR, ...Object.keys(REASONOBJ)].map((reasonKey, i) => (
                  <option key={i} value={reasonKey}>
                    {REASONOBJ[reasonKey] || reasonKey}
                  </option>
                ))}
              </SelectBox>
            </div>

            <div className="graphContainer">
              <StatisticCircleBox
                centerText={REASONOBJ[reason]}
                dataObj={
                  reason === ALLSTR
                    ? reasonBarData.type
                    : { [reason]: reasonBarData.type[reason] }
                }
                colorArr={barGraphColorArr}
                size="250"
                noPadding
              />
            </div>

            <div className="legendContainer">
              {Object.values(REASONOBJ).map((reasonStr, i) => (
                <Legend
                  key={i}
                  text={reasonStr}
                  color={BARCOLORARR[i]}
                  width="50%"
                />
              ))}
            </div>
          </DoughnutContainer>

          <BarGraph
            borderIndex={13 - reasonMonth}
            data={targetReasonData}
            colorArr={barGraphColorArr}
            height="336px"
            periodObj={{
              month: reasonMonth,
              year: reasonYear,
              setmonth: setreasonMonth,
              setyear: setreasonYear,
            }}
          />
        </ReasonContainer>
      )}
    </GraphChangeStyle>
  );
}
