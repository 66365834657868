import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const ContextMenuStyle = styled.div`
  width: 180px;
  z-index: 1;
  border-radius: 8px;
  padding: 4px;
  background-color: var(--eciBlue);
  position: fixed;
  top: 0;
  left: 0;
  transform: translate(${(props) => props.left}px, ${(props) => props.top}px);

  color: white;
  /* transform: translate(30px, 10px); */
  > * {
    height: 32px;
    line-height: 32px;
    padding: 0 12px;
    border-bottom: solid white;
    cursor: pointer;
    color: white;
    font-size: 12px;
    font-weight: 200;
    :hover {
      background-color: white;
      color: var(--eciBlue);
    }
    color: white;
  }
`;
export default function ContextMenu({ children, setisActive, top, left }) {
  return (
    <ContextMenuStyle
      top={top}
      left={left}
      onClick={(e) => {
        e.stopPropagation();
        setisActive(false);
      }}
    >
      {children}
    </ContextMenuStyle>
  );
}
