import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { PageStyle, ListPageStyle } from "../../Style";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";

import PaginationTable from "../../molecule/PaginationTable";
import SearchBar from "../../molecule/SearchBar";
import DropdownButton from "../../organism/DropdownButton";
import ProjectManageButton from "../../molecule/ProjectManageButton";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import usePageConditionList from "../../utility/hooks/usePageConditionList";
import txt from "../../Text";

export default function ProjectListPage() {
  const tokenAxios = useTokenAxios();
  const navigate = useNavigate();
  const [pageCondition, setpageCondition, projectListData] =
    usePageConditionList("project", "searchText", "isManager");
  const sendDrop = async (id) => {
    await tokenAxios.put(`/project-drop/${id}`);
    setpageCondition("reRender");
  };
  const sendDelete = async (id) => {
    await tokenAxios.put(`/project-delete/${id}`);
    setpageCondition("reRender");
  };
  const sendPending = async (id) => {
    await tokenAxios.put(`/project-pending/${id}`);
    setpageCondition("reRender");
  };
  const projectRowList =
    projectListData &&
    projectListData.map((item, i) => {
      return (
        <TableRow
          key={i}
          onClick={
            pageCondition.isManager
              ? () => {
                  navigate(`/project/page/edit/${item.id}`);
                }
              : () => {
                  navigate(`/project/page/detail/${item.id}`);
                }
          }
          itemArray={[
            item.number,
            item.name,
            item.type === 1 ? txt.project.mass : txt.project.pro,
            item.allDoStartPeriod, // 여기나오면 하기
            item.allDoOverPeriod, // 여기나오면 하기
            item.produceOrganizationId?.history2,
            item.buyerOrganizationId?.history2,
            item.phase,
            item.status,
            pageCondition.isManager && (
              <ProjectManageButton
                disable={item.delete}
                onClick={() => {
                  sendDelete(item.id);
                }}
              >
                {txt.project.delete}
              </ProjectManageButton>
            ),
            pageCondition.isManager && (
              <ProjectManageButton
                disable={item.drop}
                onClick={() => {
                  sendDrop(item.id);
                }}
              >
                Drop
              </ProjectManageButton>
            ),
            pageCondition.isManager && (
              <ProjectManageButton
                disable={item.pending}
                onClick={() => {
                  sendPending(item.id);
                }}
              >
                Pending
              </ProjectManageButton>
            ),
          ]}
          widthArray={
            pageCondition.isManager
              ? [4, 5, 4, 4, 4, 3, 3, 3, 5, 4, 4, 4]
              : [4, 5, 4, 4, 4, 3, 3, 3, 5]
          }
        />
      );
    });

  return (
    <PageStyle>
      <ListPageStyle>
        <div className="searchSection">
          <SearchBar
            width="170px"
            height="30px"
            placeholder="Search Items"
            state={pageCondition.searchText}
            setState={(val) => {
              setpageCondition("searchText", val);
            }}
          />
          <div className="filter">
            <DropdownButton
              buttonTitle="Filters"
              width="80px"
              height="30px"
              right
              title="Refine Results"
              uiObject={{
                "Project Manager": {
                  "Show Every Project": false,
                  "Managing Projects": true,
                },
              }}
              setStateList={[
                (val) => {
                  setpageCondition("isManager", val);
                },
              ]}
            />
          </div>
        </div>
        <PaginationTable
          width="100%"
          minHeight="300px"
          innerWidth="100%"
          totalPage={pageCondition.totalPage}
          rowsPerPageDefault={10}
          pageNum={pageCondition.pageNum}
          setRowsPerPage={(val) => {
            setpageCondition("rowsPerPage", val);
          }}
          rowsPerPage={pageCondition.rowsPerPage}
          setpageNum={(val) => {
            setpageCondition("pageNum", val);
          }}
          noshadow
        >
          <TableIndexRow
            itemArray={[
              "Number",
              "Project Name",
              txt.project.type,
              txt.project.startDate,
              txt.project.endDate,
              txt.project.producer,
              txt.project.orderer,
              "Phase",
              "Status",
              pageCondition.isManager && txt.project.isDel,
              pageCondition.isManager && txt.project.isDrop,
              pageCondition.isManager && txt.project.isPending,
            ]}
            widthArray={
              pageCondition.isManager
                ? [4, 5, 4, 4, 4, 3, 3, 3, 5, 4, 4, 4]
                : [4, 5, 4, 4, 4, 3, 3, 3, 5]
            }
            width="100%"
          />
          {projectRowList}
        </PaginationTable>
      </ListPageStyle>
    </PageStyle>
  );
}
