import React from "react";
import { useNavigate } from "react-router-dom";

import { PageStyle, ListPageStyle, BlueButtonStyle } from "../../Style";
import TableIndexRow from "../../atom/TableIndexRow";
import TableRow from "../../atom/TableRow";

import PaginationTable from "../../molecule/PaginationTable";
import SearchBar from "../../molecule/SearchBar";
import usePageConditionList from "../../utility/hooks/usePageConditionList";

export default function ReleaseListPage() {
  const navigate = useNavigate();
  const [pageCondition, setpageCondition, releaseList] = usePageConditionList(
    "release",
    "searchText"
  );
  const releaseRowList =
    releaseList &&
    releaseList.map((itemObj, i) => {
      return (
        <TableRow
          width="1400px"
          key={i}
          onClick={() => {
            navigate(`page/detail/${itemObj.id}`);
          }}
          itemArray={[
            itemObj.releaseNumber,
            itemObj.releaseTitle,
            itemObj.itemNumber?.join(", "),
            itemObj.itemName?.join(", "),
            itemObj.revision?.join(", "),
          ]}
          widthArray={[4, 4, 4, 4, 4]}
        />
      );
    });

  return (
    <PageStyle>
      <ListPageStyle>
        <div className="searchSection">
          <SearchBar
            width="400px"
            height="30px"
            placeholder="Search Items"
            state={pageCondition.searchText}
            setState={(val) => {
              setpageCondition("searchText", val);
            }}
          />
          {/* <BlueButtonStyle
            height="max-content"
            width="200px"
            css="padding:5px 10px"
            onClick={() => {
              navigate("page/add/new");
            }}
          >
            <span className="material-icons">add</span>
            Create New Release
          </BlueButtonStyle> */}
        </div>
        <PaginationTable
          width="100%"
          minHeight="300px"
          height="60vh"
          innerWidth="1400px"
          totalPage={pageCondition.totalPage}
          rowsPerPageDefault={10}
          pageNum={pageCondition.pageNum}
          setRowsPerPage={(val) => {
            setpageCondition("rowsPerPage", val);
          }}
          rowsPerPage={pageCondition.rowsPerPage}
          setPageNum={(val) => {
            setpageCondition("pageNum", val);
          }}
          noshadow
        >
          <TableIndexRow
            itemArray={[
              "Release Number",
              "Release Title",
              "Item Number",
              "Item Name",
              "Revision",
            ]}
            widthArray={[4, 4, 4, 4, 4]}
            width="1400px"
          />

          {releaseRowList}
        </PaginationTable>
      </ListPageStyle>
    </PageStyle>
  );
}
