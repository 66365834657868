import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ScrollContainer from "../../organism/ScrollContainer";
import AttachmentSection from "../../organism/AttachmentSection";
import Button from "../../atom/Button";
import RouteInitSection from "../../organism/RouteInitSection";
import RouteSection from "../../organism/RouteSection";

import { appendFirmwareForm, useGetFirmwareData } from "../utility";
import { PageStyle } from "../../Style";
import { conditionByTypeObject } from "../../utility/Source";

import firmwareStore from "../../store/firmwareStore";
import FirmwareInformationSection from "../organism/FirmwareInformation";
import useSaveFunction from "../../utility/hooks/useSaveFunction";
import useTempSaveFunction from "../../utility/hooks/useTempSaveFunction";
import SearchBoxGroup from "../../organism/SearchBoxGroup";

export default function FirmwarePage() {
  const params = useParams();
  const { search } = useLocation();
  const searchParam = new URLSearchParams(search);
  const navigate = useNavigate();
  //    ---MODEL
  const firmwarestore = firmwareStore();
  const phase = searchParam.get("phase");
  const { type } = params;
  //    ---VIEW

  //    ---ACTION
  const saveFunc = useSaveFunction(
    "firmware",
    appendFirmwareForm,
    firmwarestore,
    conditionByTypeObject[type].saveFuncType === "put" || phase === "2",
    params.id
  );

  const saveTempFunc = useTempSaveFunction(
    "firmware",
    appendFirmwareForm,
    firmwarestore,
    conditionByTypeObject[type].saveFuncType === "put",
    params.id
  );
  //  ---BUSINESS LOGIC
  const getFirmwareData = useGetFirmwareData(params.id, firmwarestore);
  useEffect(() => {
    firmwarestore.setisRouteActive(conditionByTypeObject[type].isRouteActive);
    firmwarestore.setid(params.id);
    if (params.id === "new") {
      firmwarestore.setStateByKey("classificationId")({
        id: searchParam.get("classId"),
        name: searchParam.get("classificationName"),
      });
    }
    if (params.id !== "new") {
      getFirmwareData();
    }
    firmwarestore.setphase(phase);
    firmwarestore.setattachmentTagOptionList(
      {
        1: [{ id: 2, name: "ETC" }],
        2: [
          { id: 2, name: "ETC" },
          { id: 5, name: "ROM package", option: 1 },
        ],
      }[phase]
    );

    return () => {
      firmwarestore.initDefaultStore();
      firmwarestore.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);

  return (
    <PageStyle>
      <ScrollContainer
        isWideScroll={conditionByTypeObject[type].isScrollWide}
        scrollRefList={[
          [firmwarestore.informationRef, "Item Information"],
          [firmwarestore.attachmentRef, "Item Attachment"],
          [firmwarestore.routeRef, "Route Information"],
        ]}
        searchBoxComponent={<SearchBoxGroup store={firmwarestore} />}
        tempButtonTitle="Save as Draft"
        tempButtonOnclick={saveTempFunc}
      >
        <FirmwareInformationSection readOnly={firmwarestore.isRouteActive} />
        <br />
        <br />
        <br />
        <br />

        <AttachmentSection
          editMode={conditionByTypeObject[type].isAttachmentEdit || phase > 1}
          title="Item Attachment"
          readOnly={firmwarestore.isRouteActive}
          moduleStore={firmwarestore}
        />
        <br />
        <br />
        <br />
        <br />
        {!firmwarestore.isRouteActive && (
          <Button
            backgroundColor="var(--eciBlue)"
            width="100%"
            height="30px"
            color="white"
            onClick={saveFunc}
            condition={firmwarestore.getisConditionFullfill(phase)}
          >
            Save and Route
          </Button>
        )}
        {firmwarestore.isRouteActive &&
          (conditionByTypeObject[type].routeSectionType === "init" &&
          phase < 2 ? (
            <RouteInitSection
              activate
              routeType="Firmware"
              itemId={firmwarestore.id}
              moduleStore={firmwarestore}
              afterUrl="/item"
            />
          ) : (
            <RouteSection
              activate
              readOnly={conditionByTypeObject[type].isRouteReadOnly}
              moduleStore={firmwarestore}
            />
          ))}
        <br />
        <br />
        <br />
        <br />
      </ScrollContainer>
    </PageStyle>
  );
}
