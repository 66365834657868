import React, { useState } from "react";
import styled from "styled-components";
import PCBStore from "../store/PCBStore";
import { BoldBlueTextStyle } from "../Style";
import useTokenAxios from "../utility/hooks/useTokenAxios";
import { useInterval } from "../utility/hooks/useInterval";
import commonStore from "../store/commonStore";

const DesignUploaderStyle = styled.div`
  .uploader {
    display: flex;
    padding-right: 10%;
    align-items: center;
    justify-content: space-between;
    .radioGroup {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
  }
  a {
    text-decoration: none;
  }
  .openButton {
    background-color: ${(props) =>
      props.isButtonActive ? "var(--eciBlue)" : "var(--textGray)"};

    width: 90px;
    height: 28px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: ${(props) => props.isButtonActive && "pointer"};
    margin: 0 auto;

    color: ${(props) =>
      props.isButtonActive ? "white" : "var(--textDarkGray)"};
  }
`;
const TitleStyle = styled.div`
  font-size: 16px;
  color: var(--eciBlue);
  font-weight: ${(props) => (props.bold ? 600 : 300)};
`;
export default function DesignUploader({
  title,
  optionArray,
  bold,
  pollingUrl,
  setState = () => {},
}) {
  const store = PCBStore();
  const commonstore = commonStore();
  const tokenAxios = useTokenAxios();
  const [uploaderType, setuploaderType] = useState({ name: "", url: "" });
  const radioList = optionArray.map((obj) => {
    return (
      <div className="radio" key={obj.name}>
        <input
          type="radio"
          id={obj.name}
          name={obj.name}
          value={obj.name}
          onChange={() => {
            setuploaderType(obj);
          }}
          checked={uploaderType.name === obj.name}
        />
        <label htmlFor={obj.name}>{obj.name}</label>
      </div>
    );
  });

  const [isPolling, setisPolling] = useState(false);
  const sendPollingRequest = async (url) => {
    try {
      const response = await tokenAxios.get(url);
      const data = response.data.result?.data;
      if (data.possible) {
        setState(data);
        commonstore.setisLoading(false);
        commonstore.setloadingBoxType("SPINNER");

        setisPolling(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useInterval(
    () => {
      sendPollingRequest(pollingUrl);
    },
    isPolling ? 3000 : null
  );
  const isButtonActive = !!uploaderType.name;
  return (
    <DesignUploaderStyle isButtonActive={isButtonActive}>
      <TitleStyle bold={bold}>{title}</TitleStyle>
      <br />
      <br />
      <div className="uploader">
        <BoldBlueTextStyle fontSize="14px">Uploader</BoldBlueTextStyle>
        <div className="radioGroup">{radioList}</div>
      </div>
      <br />
      <br />
      {isButtonActive ? (
        <a href={uploaderType.url}>
          <div
            className="openButton"
            onClick={() => {
              setisPolling(true);
              commonstore.setloadingBoxType("RETRY");
              commonstore.setloadingClickEvent((e) => {
                window.location = uploaderType.url;
              });
              commonstore.setloadingOnExit((e) => {
                setisPolling(false);
              });
              commonstore.setisLoading(true);
            }}
          >
            OK
          </div>
        </a>
      ) : (
        <div className="openButton">OK</div>
      )}

      <br />
      <br />
    </DesignUploaderStyle>
  );
}
