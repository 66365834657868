import { useEffect, useState } from "react";
import useTokenAxios from "../utility/hooks/useTokenAxios";

export function useGetDataByPeriod(uri, additionalUri = "") {
  const tokenAxios = useTokenAxios();
  const [month, setmonth] = useState(1);
  const [year, setyear] = useState(2023);
  const [data, setdata] = useState({
    total: 0,
    type: {
      customer: 1,
      quality: 2,
      standardization: 0,
      productivity: 1,
      fix: 8,
      price: 7,
      etc: 2,
    },
    list: [
      {
        name: "2021",
        val: {
          a: 1,
          b: 2,
        },
      },
      {
        name: "2022",
        val: {
          a: 1,
          b: 2,
        },
      },
      {
        name: "2023",
        val: {
          a: 1,
          b: 2,
        },
      },
    ],
  });

  const getData = async () => {
    const response = await tokenAxios.get(
      `${uri}/${year}/${month}${additionalUri}`
    );
    setdata(response.data.result.data);
  };
  useEffect(() => {
    getData();
  }, [month, year, uri, additionalUri]);

  return [month, setmonth, year, setyear, data];
}
