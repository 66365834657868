import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import TableIndexRow from "../../atom/TableIndexRow";
import ScrollController from "../../molecule/ScrollController";
import ModalBox from "../../organism/ModalBox";
import productStore from "../../store/productStore";
import { BlueButtonStyle, SectionGap, WhitePlusButton } from "../../Style";
import BoardRow from "../../molecule/BoardRow";
import { useSaveSR, useGetProductData, useGetSRBomData } from "../utility";
import RouteSection from "../../organism/RouteSection";
import ProductModal from "../organism/ProductModal";
import { conditionByTypeObject } from "../../utility/Source";
import DeleteButton from "../../atom/DeleteButton";
import PriceModal from "../organism/PriceModal";
import AttachmentWarningSign from "../../molecule/AttachmentWarningSign";
import { ProductBomPageStyle } from "./ProductBomPage";
import useTokenAxios from "../../utility/hooks/useTokenAxios";
import txt from "../../Text";
import ProductChildRow from "../../molecule/ProductChildRow";

export default function SRBomPage() {
  const tokenAxios = useTokenAxios();
  const store = productStore();
  const params = useParams();
  const { type } = params;
  // VIEW
  const [isFold, setisFold] = useState(false);
  const formRef = useRef();
  const informationRef = useRef();

  //    Action
  const saveFunc = useSaveSR(params.id, store);
  //  BUSINESS LOGIC

  // modal
  const getClassificationTree = async () => {
    const response = await tokenAxios.get("classification-tree");
    const { data } = response.data.result;
    store.setmodalClassificationTree(
      data.filter(
        (obj) =>
          (store.modalType === "BOM"
            ? obj.name !== "Product"
            : obj.name === "Product") && obj.name !== " "
      )
    );
  };
  const getModalList = async () => {
    let response = {};
    if (store.modalSelectedItemClassification.classificationId) {
      if (store.modalType === "BOM") {
        response = await tokenAxios.get(
          `product/item-classification/${store.modalSelectedItemClassification.classificationId}?size=${store.rowsPerPage}&page=${store.pageNum}&name=${store.searchText}`
        );
      }
      if (store.modalType === "SR") {
        response = await tokenAxios.get(
          `sr/product-page/${store.modalSelectedItemClassification.classificationId}?size=${store.rowsPerPage}&page=${store.pageNum}&name=${store.searchText}`
        );
      }
    }
    store.setmodalItemList(
      response.data?.result?.data.content || response.data?.content || []
    );
    store.settotalPage(response?.data?.totalPages || 1);
  };
  useEffect(() => {
    getModalList();
  }, [
    store.modalSelectedItemClassification,
    store.rowsPerPage,
    store.pageNum,
    store.searchText,
  ]);
  useEffect(() => {
    if (store.modalType !== "price") {
      getClassificationTree();
    }
  }, [store.modalType]);

  // data
  const getPriceData = async () => {
    const response = await tokenAxios.get(`sr-price/${params.id}`);
    store.initChangedPriceObj(JSON.parse(response.data.result.data) || []);
  };
  const getProductData = useGetProductData(params.id, store);
  const getBomData = useGetSRBomData(params.id, store);
  useEffect(() => {
    store.setisRouteActive(conditionByTypeObject[type].isRouteActive);
    if (type !== "detail") {
      getClassificationTree();
    }
    getBomData();
    getProductData();
    getPriceData();
    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, []);
  // console.log(store.priceBomTree);
  // console.log(store.changedPriceObj);
  return (
    <ProductBomPageStyle childNum={store.productChildList.length}>
      <ModalBox
        isActivate={!!store.modalType}
        setisActivate={store.closeModal}
        onExit={store.exitModalCallBack}
      >
        {(store.modalType === "BOM" || store.modalType === "SR") && (
          <ProductModal
            onCheckState={store.onCheckModalItem}
            applyLv2={store.applyModalLv2}
            applyLv3={store.applyModalLv3}
            cancelFunc={store.cancelModal}
            onDeleteModalAddedItems={store.onDeleteModalAddedItems}
          />
        )}
        {store.modalType === "price" && (
          <PriceModal cancelFunc={store.cancelModal} sr />
        )}
      </ModalBox>

      <ScrollController
        width="100px"
        buttonArray={[
          [informationRef, "BOM Information"],
          [store.routeRef, "RouteInformation"],
        ]}
        scrollRef={formRef}
      />
      <div className="mainPart" ref={formRef}>
        <div className="searchSection">
          <WhitePlusButton onClick={store.openPriceModal}>
            {txt.product.calcPrice}
          </WhitePlusButton>
          <WhitePlusButton onClick={store.openSRModal}>
            {txt.product.openSRModal}
          </WhitePlusButton>
        </div>
        <div className="gap" />
        {type !== "detail" && (
          <AttachmentWarningSign name="" text={txt.product.bomWarning} />
        )}
        <div className="gap" />

        <div className="flex">
          <TableIndexRow
            widthArray={[5, 5]}
            itemArray={["", "ItemNumber"]}
            backgroundColor="white"
          />
          <div className="rightIndex">
            <TableIndexRow
              widthArray={[14, 10, 5]}
              itemArray={["Item Name", "Specification", "Revision"]}
              backgroundColor="white"
            />
          </div>
        </div>

        <BoardRow
          isFold={isFold}
          setisFold={setisFold}
          leftItemArray={["Product", store.stateObj.partNumber]}
          rightItemArray={[
            store.stateObj.name,
            store.stateObj.specification,
            store.stateObj.revision,
          ]}
          rightWidthArray={[14, 10, 5]}
        />
        {!isFold && (
          <div className="childRows">
            {store.productChildList.map((childObj, i) => (
              <ProductChildRow
                widthArray={[14, 10, 5]}
                leftItemArray={
                  childObj?.id
                    ? [childObj.type, childObj.number || childObj.cardNumber]
                    : [
                        <span
                          className={type !== "detail" && "newPBA"}
                          onClick={() =>
                            type !== "detail" && store.openBOMModal()
                          }
                        >
                          {type !== "detail" ? "Click to add Item" : "no item"}
                        </span>,
                      ]
                }
                rightItemArray={
                  childObj?.id
                    ? [
                        childObj.name || childObj.cardName,
                        "",
                        childObj.revision,
                        !store.isRouteActive && (
                          <DeleteButton
                            onDelete={() => {
                              store.deleteProductChild(childObj);
                            }}
                            color="var(--textDarkGray)"
                            height="50px"
                          />
                        ),
                      ]
                    : ""
                }
                treeData={childObj?.children}
                onClick={() => store.openBOMModal()}
                isFirst={i === 0}
                key={i}
                border={!childObj.id && "solid 3px var(--textGray)"}
                backgroundColor={
                  !childObj.id || store.isRouteActive
                    ? "white"
                    : "rgba(253, 191, 77, 0.3)"
                }
                readOnly={type === "detail" || store.isRouteActive}
                isLastChild={store.productChildList.length - 1 === i}
              />
            ))}
          </div>
        )}
        {!store.isRouteActive && (
          <BlueButtonStyle
            height="36px"
            width="400px"
            onClick={saveFunc}
            css="justify-content:center; margin-top:80px;"
          >
            Save
          </BlueButtonStyle>
        )}
        <SectionGap />
        <div className="routeBox">
          {store.isRouteActive && (
            <RouteSection
              activate
              readOnly={type === "detail"}
              moduleStore={store}
            />
          )}
        </div>
      </div>
    </ProductBomPageStyle>
  );
}
