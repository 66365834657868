import React from "react";
import styled from "styled-components";
import { WidthHeightStyle } from "../../Style";

const ModuleCountBoxStyle = styled.div`
  ${WidthHeightStyle}
  background-color: white;
  border-radius: 8px;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 20px;
  font-size: 11px;
  color: var(--textDarkGray);
  font-weight: 600;
  box-shadow: 1px 0px 100px #f5f5f5;
  .gap {
    height: 30px;
  }
  cursor: pointer;
`;
const BlueCircle = styled.div`
  border-radius: 10000px;
  background-color: var(--eciBlue);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  color: white;
`;
export default function ModuleCountBox({
  width = "60px",
  height = "140px",
  count,
  title,
  onClick,
}) {
  return (
    <ModuleCountBoxStyle width={width} height={height} onClick={onClick}>
      <BlueCircle>{count}</BlueCircle>
      <div className="gap" />
      {title}
    </ModuleCountBoxStyle>
  );
}
