import React from "react";
import styled from "styled-components";
import LineTitle from "../../atom/LineTitle";
import AnimationSearchInput from "../../molecule/AnimationSearchInput";
import InputGrid from "../../organism/InputGrid";
import electronicPartStore from "../../store/electronicPartStore";
import { PageStyle } from "../../Style";
import PopupInput from "../molecule/PopupInput";

const LibraryInfoStyle = styled(PageStyle)`
  .footPrintReadOnly {
    display: flex;
    justify-content: space-between;
    .left {
      font-size: 12px;
      font-weight: 600;
      color: var(--eciBlue);
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .right {
      width: 70%;
    }
  }
`;
const LabelInputStyle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${(props) => (props.height ? props.height : "40px")};
  margin-bottom: 20px;
  .flex {
    display: flex;
  }
  .labelTitle {
    font-size: 12px;
    font-weight: 600;
    color: var(--eciBlue);
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .input {
    width: 70%;
    transform: translateY(10px);
  }
  .required {
    width: 5px;
    height: 5px;
    border-radius: 10px;
    margin-left: 3px;
    display: inline-block;
    background-color: var(--required);
    transform: translate(2px, 5px);
  }
`;
const DeleteInputStyle = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: white;
  padding: 0 10px;
  div {
    color: var(--eciBlue);
    font-size: 14px;
  }
  .delete {
    cursor: pointer;
    color: var(--textGray);
  }
`;
export default function LibraryInformationSection({ readOnly }) {
  const electronicPartstore = electronicPartStore();
  const openPopup = (val) => {
    electronicPartstore.setlibraryPopupData(val);
    electronicPartstore.setsearchBoxType("");
    electronicPartstore.setcategorySearchBoxType("");
  };
  return (
    <LibraryInfoStyle>
      <LineTitle
        fontSize="16px"
        color="var(--eciBlue)"
        width="100%"
        height="60px"
      >
        CAD Library Information
      </LineTitle>
      {readOnly && (
        <LabelInputStyle>
          <div className="flex">
            <div className="labelTitle">Symbol</div>
          </div>
          <div className="input">
            {readOnly && (
              <PopupInput
                title={
                  electronicPartstore.stateObj.symbol[0]?.name ||
                  electronicPartstore.stateObj.symbol?.name
                }
                onClick={() => {
                  openPopup({
                    ...electronicPartstore.stateObj.symbol,
                    type: "symbol",
                  });
                }}
              />
            )}
          </div>
        </LabelInputStyle>
      )}

      {!readOnly && (
        <LabelInputStyle>
          <div className="flex">
            <div className="labelTitle">Symbol</div>
            <div className="required" />
          </div>
          <div className="input">
            {electronicPartstore.stateObj?.symbol[0] ? (
              <DeleteInputStyle>
                <div>{electronicPartstore.stateObj?.symbol[0]?.name}</div>
                {!readOnly && (
                  <div
                    className="material-icons delete"
                    onClick={() =>
                      electronicPartstore.setSymbolFootprint(
                        "symbol",
                        electronicPartstore.stateObj.symbol[0]
                      )
                    }
                  >
                    delete
                  </div>
                )}
              </DeleteInputStyle>
            ) : (
              <AnimationSearchInput
                readOnly={readOnly}
                width="100%"
                height="40px"
                placeholder="Symbol"
                state={
                  electronicPartstore.stateObj.symbol.name ||
                  electronicPartstore.stateObj.symbol
                }
                onClick={() => {
                  electronicPartstore.setcategorySearchBoxType("symbol");
                }}
                isNow={electronicPartstore.categorySearchBoxType === "symbol"}
              />
            )}
          </div>
        </LabelInputStyle>
      )}

      {readOnly && (
        <div className="footPrintReadOnly">
          <div className="left">Footprint</div>
          <div className="right">
            {electronicPartstore.stateObj.footPrint.map((footprintObj, j) => (
              <PopupInput
                key={j}
                title={footprintObj.name}
                onClick={() => {
                  openPopup({ type: "Footprint", ...footprintObj });
                }}
              />
            ))}
          </div>
        </div>
      )}
      {!readOnly && (
        <>
          <LabelInputStyle>
            <div className="flex">
              <div className="labelTitle">Footprint</div>
              <div className="required" />
            </div>
            <div className="input">
              <AnimationSearchInput
                readOnly={readOnly}
                width="100%"
                height="40px"
                placeholder="Footprint"
                onClick={() => {
                  electronicPartstore.setcategorySearchBoxType("footPrint");
                }}
                isNow={
                  electronicPartstore.categorySearchBoxType === "footPrint"
                }
              />
            </div>
          </LabelInputStyle>
          {electronicPartstore.stateObj.footPrint.map((footprintObj, i) => (
            <LabelInputStyle key={i}>
              <DeleteInputStyle>
                <div> {footprintObj.name}</div>
                {!readOnly && (
                  <div
                    className="material-icons delete"
                    onClick={() =>
                      electronicPartstore.setSymbolFootprint(
                        "footPrint",
                        footprintObj
                      )
                    }
                  >
                    delete
                  </div>
                )}
              </DeleteInputStyle>
            </LabelInputStyle>
          ))}
        </>
      )}
    </LibraryInfoStyle>
  );
}
