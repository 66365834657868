import React from "react";
import styled from "styled-components";
import SelectBox from "../../atom/SelectBox";
import mPBAStore from "../../store/mPBAStore";
import { WhitePlusButton } from "../../Style";
import txt from "../../Text";

const SearchSectionStyle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .selectboxContainer {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: var(--textDarkGray);
  }
  .gap {
    width: 20px;
    flex-shrink: 0;
  }
`;
export default function MPBASearchSection({ openMPBAModal }) {
  const mpbastore = mPBAStore();

  return (
    <SearchSectionStyle>
      <div className="selectboxContainer">
        MODE
        <div className="gap" />
        <SelectBox
          width="180px"
          height="30px"
          state={mpbastore.mode}
          setState={mpbastore.setmode}
          backgroundColor="white"
          color="var(--deemGray)"
          borderColor="var(--deemGray)"
          fontSize="12px"
          defaultValue={mpbastore.mode}
        >
          <option value="0">Default</option>
          {mpbastore.modeListByUser?.some((item) => item === "master edit") && (
            <option value="1">Master PBA BOM Edit</option>
          )}
          {mpbastore.modeListByUser?.some((item) => item === "pba edit") && (
            <option value="2">PBA BOM Edit</option>
          )}
        </SelectBox>
      </div>
      <div className="gap" />
      <div className="selectboxContainer">
        VIEW <div className="gap" />
        <SelectBox
          width="120px"
          height="30px"
          state={mpbastore.isOneByOne}
          setState={mpbastore.setisOneByOne}
          backgroundColor="white"
          color="var(--deemGray)"
          borderColor="var(--deemGray)"
          fontSize="12px"
          defaultValue
        >
          <option value="012">1:n</option>

          <option value="1">1:1</option>
        </SelectBox>
      </div>
      <div className="gap" />
      <div className="gap" />
      <div className="gap" />
      {mpbastore.mode === "1" && mpbastore.isOneByOne === "1" ? (
        <WhitePlusButton onClick={openMPBAModal}>
          <div className="material-icons">add</div>
          {txt.mpba.addPart}
        </WhitePlusButton>
      ) : (
        ""
      )}
    </SearchSectionStyle>
  );
}
