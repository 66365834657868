import { useEffect, useState } from "react";
import { conditionByTypeObject } from "../Source";

export default function useInitPage({
  getData,
  callBack = () => {},
  store,
  phase,
  type,
  id,
}) {
  const [initFlag, setinitFlag] = useState(0);
  useEffect(() => {
    store.setisRouteActive(conditionByTypeObject[type].isRouteActive);
    store.setid(id);
    if (id !== "new") {
      getData();
    }
    callBack();

    return () => {
      store.initDefaultStore();
      store.initModuleStore();
      // 페이지 탈출시 init
    };
  }, [initFlag]);

  return () => {
    setinitFlag((flag) => flag + 1);
  };
}
