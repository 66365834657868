// get language

const textList = {
  eng: {
    dashBoard: {
      gun: "총",
      period: "기간",
      all: "전체",
      reason: "이유",
      month: "월",
      chartTypeList: ["Project", "Product", "설계변경", "Release"],
      projectGraphTypeList: ["단계별 working", "월별"],
      productGraphTypeList: ["종류별", "월별"],
      TotalTitle: `건 Product Complete`,
      changeGraphTypeList: ["월별 등록건수", "이유별"],
      changeGraphReasonObj: {
        customer: "고객요구",
        quality: "품질개선",
        standardization: "표준화",
        productivity: "생산성 향상",
        fix: "오기 정정",
        price: "원가 절감",
        etc: "기타",
      },
    },
    admin: {
      step: "단계",
      reason: "사유",
      addChildClassification: "하위 분류 추가",
      addClassification: "단계 추가",
      editProp: "속성 편집",
      editTitle: "타이틀 편집",
      delete: "삭제",
      enterName: "이름을 입력해주세요.",
      enterCode: "코드를 입력해주세요.",
      apply: "반영",
      propName: "속성 이름",
      propInputType: "속성 입력 방식",
      enterUnit: "입력 단위",
      fromUnit: "From 단위",
      toUnit: "TO 단위",
      userChoiceList: "유저 선택지",
      dbIndex: ["코드", "내용"],
      blockNumber: "칸수",
      block: "칸",
      memberPageIndex: [
        "이름",
        "소속",
        "직위/권한",
        "Admin 여부",
        "ID",
        "계정 생성일",
      ],
      registerDate: "등록일",
      footprintPageIndex: [
        "Footprint명",
        "Footprint Path",
        "등록일",
        "Padstack명",
        "Comment",
        "Image Open",
        "삭제",
      ],
      symbolPageIndex: [
        "symbol명",
        "Symbol Path",
        "등록일",
        "Comment",
        "Image Open",
        "삭제",
      ],
      lockPageIndex: [
        "Item Number",
        "Item Name",
        "Item Classification",
        "Specification",
        "제조사",
        "Lock",
      ],
      enterReason: "사유를 입력해주세요.",
      manageProperty: "속성관리",
      manageMember: "Member 관리",
      manageItem: "Item 변경",
      cadLibraryManagement: "CAD Library 속성관리",
      classificationManagement: "아이템분류관리",
    },
    board: {
      modalFirstIndex: ["", "Part Number", "부품명", "revision", "phase"],
      modalSecondIndex: ["Part Number", "부품명"],
    },
    crco: {
      coListPageIndex: [
        "",
        "CO Number",
        "제목",
        "기안자",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      crListPageIndex: [
        "",
        "CR Number",
        "CR 이유",
        "중요도",
        "출처",
        "제목",
        "기안자",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      reason: "이유",
      buyerOrganization: "구매처",
      reasonDetail: "이유 상세",
      crImportance: "CR 중요도",
      crSource: "CR 출처",
      buyerOrganizationId: "고객",
      clientPublishedPeriod: "고객 CO 발행일",
      clientReceivedPeriod: "고객 CO 접수일",
      reasonId: "CO 이유",
      clientItemNumber: `고객 CO Number`,
      applyPeriod: "CO적용시점",
      stageId: "CO 적용단계",
      crTitle: "CR 제목",
      crContent: "CR 내용",
      cocrModalIndex: [
        "",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
        "CR Number",
        "CR 이유",
        "중요도",
        "출처",
        "제목",
        "기안자",
      ],
      coCheckBoxCheckList: [
        { id: 1, name: "도면수정" },
        { id: 2, name: "부품 변경" },
        { id: 3, name: "금형수정" },
        { id: 4, name: "차공구 변경" },
        { id: 5, name: "설비 변경" },
        { id: 6, name: "DFMEA변경" },
        { id: 7, name: "공정 변경" },
      ],
    },
    elec: {
      registerDate: "등록일",
      symbolSearchBoxSymbolIndex: ["", "Symbol명", "등록일", "Image"],
      footprintSearchBoxFootprintIndex: ["", "Footprint명", "등록일", "Image"],
      purchaseDetail: "부품구매 상세정보",
      produceOrganizationId: "제조사",
      produceOrganizationNumber: "제조사 부품 번호",
      substituteToFormal: "정식 부품으로 치환하기",
      credibilityReport: "신뢰성 Report",
      tempFormal: "임시/정식 부품",
      formal: "정식부품",
      temp: "임시부품",
      detail: "상세정보",
      note: "비고",
      level: "등급",
      extinct: "단종",
      extinctExpected: "단종예정",
      changing: "제조변경",
      price: "구매단가",
      truePrice: "정단가",
      tentativePrice: "가단가",
      approvedPrice: "인정가",
      change: "변경",
      save: "저장",
      alertPriceTentative:
        "부품 구매 상세 정보의 정단가와 가단가 중 하나는 반드시 입력해야 합니다.",
    },
    mpba: {
      modalIndex: ["", "Part Number", "부품명", "설계자", "version", "state"],
      receipt: "수량",
      addPart: "부품 추가",
      produceOrganizationId: "제조사",
      completeDate: "PCB 설계완료요청일",
      mpbaModalIndex: ["단위", "수량", "부품분류", "제조사", "제조사 부품번호"],
      edit: "편집",
      use: "사용",
      notUse: "미사용",
      pickOther: "대치품 지정",
    },
    pba: {
      produceOrganizationId: "제작사",
    },
    pcb: {
      compareTableIndex: [
        "부품 분류",
        "제조사",
        "제조사 부품 번호",
        "임시 단가",
        "부품 용도",
        "품명",
        "Footprint",
        "Symbol",
      ],
      checkSchematicPartList: "Schematic PartList 확인",
      noMatchWarning:
        "은 Part Number가 없거나 일치하는 Part Number가 없습니다.",
      propertyNoMatchWarning:
        "의 속성과 PDM이 관리하는 속성이 일치하지 않습니다.",
      schematicIndex: [
        "Ref",
        "Part Number",
        "단위",
        "수량",
        "부품분류",
        "제조사",
        "제조사 부품번호",
        "임시단가",
        "부품용도",
        "품명",
        "Footprint",
        "Symbol",
      ],
      circuit: "회로",
      produceOrganizationId: "제조사",
      checkPcbPartList: "PCB PartList 확인 ",
      pcbIndex: [
        "Ref",
        "Part Number",
        "단위",
        "수량",
        "부품분류",
        "제조사",
        "제조사 부품번호",
        "임시단가",
        "부품용도",
        "품명",
        "Footprint",
        "Symbol",
        "Schematic",
        "PCB",
      ],
      pcbFileIndex: [
        "Ref",
        "Part Number",
        "단위",
        "수량",
        "부품분류",
        "제조사",
        "제조사 부품번호",
        "임시단가",
        "부품용도",
        "품명",
        "Footprint",
        "Symbol",
        "Rotation",
        "Side",
        "좌표값",
        "PCB 종류",
      ],
      schematicNoMatch: "Schematic Partlist와 일치하지 않습니다.",
      design: "디자인",
      schematicDesign: "회로 디자인",
      psr_color: "PSR Color",
      making_color: "Making Color",
      pcbMaterial: "PCB 재질",
      pcbThickness: "PCB 두께",
      innerOz: "내층온즈",
      outerOz: "외층온즈",
      plating: "표면처리",
      width: "가로",
      height: "세로",
      arrayCnt: "Array 개수",
      special: "특수사양",
    },
    product: {
      productModalIndex1: ["", "Part Number", "부품명", "Revision"],
      productModalIndex2: ["Part Number", "부품명"],
      supplier: "공급업체",
      priceType: "단가종류",
      price: "단가",
      dealCondition: "거래조건",
      additionalCost: "부대경비",
      subSum: "SUB합계",
      compareMethod: "Compare 방법",
      bomWarning: "반드시 편집중인 BOM을 (임시)저장한 뒤 단가를 계산하세요.",
      calcPrice: "단가계산",
      openSRModal: "이전 SR BOM 불러오기",
      selectCarType: "차종선택",
      unitCostE: "단가 적용 환율",
      recognizedCostE: "인정가 적용 환율",
      wonDollarE: "원달러 환율",
      wonYuanE: "원엔 환율",
      currency: "통화",
      carType: "차종",
      unSelected: "미지정",
      unitCostIndexList: [
        "",
        "Level",
        "Item Number",
        "Item Name",
        "Specification",
        "구매처",
        "단가종류",
        "단가",
        "거래조건",
        "부대경비",
        "총단가",
        "인정가",
        "총인정가",
      ],
      unitCostCarModalIndex: ["차종", "달러>원화", "엔화>원화"],
    },
    project: {
      proto: "Proto",
      allDo: "All도",
      projectInfoIndex: ["제품번호", "제품명", "제품군"],
      delete: "삭제",
      mass: "양산개발",
      pro: "선행개발",
      type: "개발 유형",
      startDate: "계획시작일",
      endDate: "계획종료일",
      producer: "생산 조직",
      orderer: "발주처",
      isDel: "삭제 여부",
      isDrop: "Drop 여부",
      isPending: "Pending 여부",
      carType: "차종",
    },
    release: {
      modalIndex1: ["", "Part Number", "부품명", "Revision"],
      modalIndex2: ["Part Number", "부품명"],
      releaseProduct: "배포 Product",
      infoIndex: ["Product Number", "Project 단계"],
      infoTitle: "배포 제목",
      infoContent: "배포 내용",
      distributor: "배포처",
    },
    subsidiary: {
      supplier: "제조사",
      supplierNumber: "제조사 부품 번호",
      detail: "상세정보",
      sharing: "공용",
    },
    tokenExpired:
      "로그인 이후 많은 시간이 경과해 자동으로 로그아웃 되었습니다. 다시 로그인 해주세요.",
    needRefresh: "리프레시가 필요합니다.",
    priceTypeList: ["정단가", "가단가"],
    routeRegistered: "라우트 등록이 완료되었습니다.",
    routeRejected: "라우트 거절이 완료되었습니다.",
    batchMember: "담당자 일괄 선택하기",
    passwordErrorMessage:
      "최소 8자리이면서 1개 이상의 알파벳, 숫자, 특수문자를 포함",
    positionCheckList: [
      // { name: "ADMIN", id: 0 },
      { name: "전무", id: 1 },
      { name: "상무", id: 2 },
      { name: "이사", id: 3 },
      { name: "수석연구원", id: 4 },
      { name: "선임연구원", id: 5 },
      { name: "연구원", id: 6 },
      { name: "구매담당자", id: 7 },
    ],
    grantAdmin: "Admin 권한 부여",
    currentPosition: "현재 권한",
  },
  // TODO : 중국어 추가 지원 필요
  chn: {
    dashBoard: {
      gun: "총",
      period: "기간",
      all: "전체",
      reason: "이유",
      month: "월",
      chartTypeList: ["Project", "Product", "설계변경", "Release"],
      projectGraphTypeList: ["단계별 working", "월별"],
      productGraphTypeList: ["종류별", "월별"],
      TotalTitle: `건 Product Complete`,
      changeGraphTypeList: ["월별 등록건수", "이유별"],
      changeGraphReasonObj: {
        customer: "고객요구",
        quality: "품질개선",
        standardization: "표준화",
        productivity: "생산성 향상",
        fix: "오기 정정",
        price: "원가 절감",
        etc: "기타",
      },
    },
    admin: {
      step: "단계",
      reason: "사유",
      addChildClassification: "하위 분류 추가",
      addClassification: "단계 추가",
      editProp: "속성 편집",
      editTitle: "타이틀 편집",
      delete: "삭제",
      enterName: "이름을 입력해주세요.",
      enterCode: "코드를 입력해주세요.",
      apply: "반영",
      propName: "속성 이름",
      propInputType: "속성 입력 방식",
      enterUnit: "입력 단위",
      fromUnit: "From 단위",
      toUnit: "TO 단위",
      userChoiceList: "유저 선택지",
      dbIndex: ["코드", "내용"],
      blockNumber: "칸수",
      block: "칸",
      memberPageIndex: [
        "이름",
        "소속",
        "직위/권한",
        "Admin 여부",
        "ID",
        "계정 생성일",
      ],
      registerDate: "등록일",
      footprintPageIndex: [
        "Footprint명",
        "Footprint Path",
        "등록일",
        "Padstack명",
        "Comment",
        "Image Open",
        "삭제",
      ],
      symbolPageIndex: [
        "symbol명",
        "Symbol Path",
        "등록일",
        "Comment",
        "Image Open",
        "삭제",
      ],
      lockPageIndex: [
        "Item Number",
        "Item Name",
        "Item Classification",
        "Specification",
        "제조사",
        "Lock",
      ],
      enterReason: "사유를 입력해주세요.",
      manageProperty: "속성관리",
      manageMember: "Member 관리",
      manageItem: "Item 변경",
      cadLibraryManagement: "CAD Library 속성관리",
      classificationManagement: "아이템분류관리",
    },
    board: {
      modalFirstIndex: ["", "Part Number", "부품명", "revision", "phase"],
      modalSecondIndex: ["Part Number", "부품명"],
    },
    crco: {
      coListPageIndex: [
        "",
        "CO Number",
        "제목",
        "기안자",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      crListPageIndex: [
        "",
        "CR Number",
        "CR 이유",
        "중요도",
        "출처",
        "제목",
        "기안자",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      reason: "이유",
      buyerOrganization: "구매처",
      reasonDetail: "이유 상세",
      crImportance: "CR 중요도",
      crSource: "CR 출처",
      buyerOrganizationId: "고객",
      clientPublishedPeriod: "고객 CO 발행일",
      clientReceivedPeriod: "고객 CO 접수일",
      reasonId: "CO 이유",
      clientItemNumber: `고객 CO Number`,
      applyPeriod: "CO적용시점",
      stageId: "CO 적용단계",
      crTitle: "CR 제목",
      crContent: "CR 내용",
      cocrModalIndex: [
        "",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
        "CR Number",
        "CR 이유",
        "중요도",
        "출처",
        "제목",
        "기안자",
      ],
      coCheckBoxCheckList: [
        { id: 1, name: "도면수정" },
        { id: 2, name: "부품 변경" },
        { id: 3, name: "금형수정" },
        { id: 4, name: "차공구 변경" },
        { id: 5, name: "설비 변경" },
        { id: 6, name: "DFMEA변경" },
        { id: 7, name: "공정 변경" },
      ],
    },
    elec: {
      registerDate: "등록일",
      symbolSearchBoxSymbolIndex: ["", "Symbol명", "등록일", "Image"],
      footprintSearchBoxFootprintIndex: ["", "Footprint명", "등록일", "Image"],
      purchaseDetail: "부품구매 상세정보",
      produceOrganizationId: "제조사",
      produceOrganizationNumber: "제조사 부품 번호",
      substituteToFormal: "정식 부품으로 치환하기",
      credibilityReport: "신뢰성 Report",
      tempFormal: "임시/정식 부품",
      formal: "정식부품",
      temp: "임시부품",
      detail: "상세정보",
      level: "등급",
      extinct: "단종",
      extinctExpected: "단종예정",
      changing: "제조변경",
      price: "구매단가",
      truePrice: "정단가",
      tentativePrice: "가단가",
      approvedPrice: "인정가",
      change: "변경",
      save: "저장",
      alertPriceTentative:
        "부품 구매 상세 정보의 정단가와 가단가 중 하나는 반드시 입력해야 합니다.",
    },
    mpba: {
      modalIndex: ["", "Part Number", "부품명", "설계자", "version", "state"],
      receipt: "수량",
      addPart: "부품 추가",
      produceOrganizationId: "제조사",
      completeDate: "PCB 설계완료요청일",
      mpbaModalIndex: ["단위", "수량", "부품분류", "제조사", "제조사 부품번호"],
      edit: "편집",
      use: "사용",
      notUse: "미사용",
      pickOther: "대치품 지정",
    },
    pba: {
      produceOrganizationId: "제작사",
    },
    pcb: {
      compareTableIndex: [
        "부품 분류",
        "제조사",
        "제조사 부품 번호",
        "임시 단가",
        "부품 용도",
        "품명",
        "Footprint",
        "Symbol",
      ],
      checkSchematicPartList: "Schematic PartList 확인",
      noMatchWarning:
        "은 Part Number가 없거나 일치하는 Part Number가 없습니다.",
      propertyNoMatchWarning:
        "의 속성과 PDM이 관리하는 속성이 일치하지 않습니다.",
      schematicIndex: [
        "Ref",
        "Part Number",
        "단위",
        "수량",
        "부품분류",
        "제조사",
        "제조사 부품번호",
        "임시단가",
        "부품용도",
        "품명",
        "Footprint",
        "Symbol",
      ],
      circuit: "회로",
      produceOrganizationId: "제조사",
      checkPcbPartList: "PCB PartList 확인 ",
      pcbIndex: [
        "Ref",
        "Part Number",
        "단위",
        "수량",
        "부품분류",
        "제조사",
        "제조사 부품번호",
        "임시단가",
        "부품용도",
        "품명",
        "Footprint",
        "Symbol",
        "Schematic",
        "PCB",
      ],
      pcbFileIndex: [
        "Ref",
        "Part Number",
        "단위",
        "수량",
        "부품분류",
        "제조사",
        "제조사 부품번호",
        "임시단가",
        "부품용도",
        "품명",
        "Footprint",
        "Symbol",
        "Rotation",
        "Side",
        "좌표값",
        "PCB 종류",
      ],
      schematicNoMatch: "Schematic Partlist와 일치하지 않습니다.",
      design: "디자인",
      schematicDesign: "회로 디자인",
      psr_color: "PSR Color",
      making_color: "Making Color",
      pcbMaterial: "PCB 재질",
      pcbThickness: "PCB 두께",
      innerOz: "내층온즈",
      outerOz: "외층온즈",
      plating: "표면처리",
      width: "가로",
      height: "세로",
      arrayCnt: "Array 개수",
      special: "특수사양",
    },
    product: {
      productModalIndex1: ["", "Part Number", "부품명", "Revision"],
      productModalIndex2: ["Part Number", "부품명"],
      supplier: "공급업체",
      priceType: "단가종류",
      price: "단가",
      dealCondition: "거래조건",
      additionalCost: "부대경비",
      subSum: "SUB합계",
      compareMethod: "Compare 방법",
      bomWarning: "반드시 편집중인 BOM을 (임시)저장한 뒤 단가를 계산하세요.",
      calcPrice: "단가계산",
      openSRModal: "이전 SR BOM 불러오기",
      selectCarType: "차종선택",
      unitCostE: "단가 적용 환율",
      recognizedCostE: "인정가 적용 환율",
      wonDollarE: "원달러 환율",
      wonYuanE: "원엔 환율",
      currency: "통화",
      carType: "차종",
      unSelected: "미지정",
      unitCostIndexList: [
        "",
        "Level",
        "Item Number",
        "Item Name",
        "Specification",
        "구매처",
        "단가종류",
        "단가",
        "거래조건",
        "부대경비",
        "총단가",
        "인정가",
        "총인정가",
      ],
      unitCostCarModalIndex: ["차종", "달러>원화", "엔화>원화"],
    },
    project: {
      proto: "Proto",
      allDo: "All도",
      projectInfoIndex: ["제품번호", "제품명", "제품군"],
      delete: "삭제",
      mass: "양산개발",
      pro: "선행개발",
      type: "개발 유형",
      startDate: "계획시작일",
      endDate: "계획종료일",
      producer: "생산 조직",
      orderer: "발주처",
      isDel: "삭제 여부",
      isDrop: "Drop 여부",
      isPending: "Pending 여부",
      carType: "차종",
    },
    release: {
      modalIndex1: ["", "Part Number", "부품명", "Revision"],
      modalIndex2: ["Part Number", "부품명"],
      releaseProduct: "배포 Product",
      infoIndex: ["Product Number", "Project 단계"],
      infoTitle: "배포 제목",
      infoContent: "배포 내용",
      distributor: "배포처",
    },
    subsidiary: {
      supplier: "제조사",
      supplierNumber: "제조사 부품 번호",
      detail: "상세정보",
      sharing: "공용",
    },
    tokenExpired:
      "로그인 이후 많은 시간이 경과해 자동으로 로그아웃 되었습니다. 다시 로그인 해주세요.",
    needRefresh: "리프레시가 필요합니다.",
    priceTypeList: ["정단가", "가단가"],
    routeRegistered: "라우트 등록이 완료되었습니다.",
    routeRejected: "라우트 거절이 완료되었습니다.",
    batchMember: "담당자 일괄 선택하기",
    passwordErrorMessage:
      "최소 8자리이면서 1개 이상의 알파벳, 숫자, 특수문자를 포함",
    positionCheckList: [
      // { name: "ADMIN", id: 0 },
      { name: "전무", id: 1 },
      { name: "상무", id: 2 },
      { name: "이사", id: 3 },
      { name: "수석연구원", id: 4 },
      { name: "선임연구원", id: 5 },
      { name: "연구원", id: 6 },
      { name: "구매담당자", id: 7 },
    ],
    grantAdmin: "Admin 권한 부여",
    currentPosition: "현재 권한",
  },
  kor: {
    dashBoard: {
      gun: "총",
      period: "기간",
      all: "전체",
      reason: "이유",
      month: "월",
      chartTypeList: ["Project", "Product", "설계변경", "Release"],
      projectGraphTypeList: ["단계별 working", "월별"],
      productGraphTypeList: ["종류별", "월별"],
      TotalTitle: `건 Product Complete`,
      changeGraphTypeList: ["월별 등록건수", "이유별"],
      changeGraphReasonObj: {
        customer: "고객요구",
        quality: "품질개선",
        standardization: "표준화",
        productivity: "생산성 향상",
        fix: "오기 정정",
        price: "원가 절감",
        etc: "기타",
      },
    },
    admin: {
      step: "단계",
      reason: "사유",
      addChildClassification: "하위 분류 추가",
      addClassification: "단계 추가",
      editProp: "속성 편집",
      editTitle: "타이틀 편집",
      delete: "삭제",
      enterName: "이름을 입력해주세요.",
      enterCode: "코드를 입력해주세요.",
      apply: "반영",
      propName: "속성 이름",
      propInputType: "속성 입력 방식",
      enterUnit: "입력 단위",
      fromUnit: "From 단위",
      toUnit: "TO 단위",
      userChoiceList: "유저 선택지",
      dbIndex: ["코드", "내용"],
      blockNumber: "칸수",
      block: "칸",
      memberPageIndex: [
        "이름",
        "소속",
        "직위/권한",
        "Admin 여부",
        "ID",
        "계정 생성일",
      ],
      registerDate: "등록일",
      footprintPageIndex: [
        "Footprint명",
        "Footprint Path",
        "등록일",
        "Padstack명",
        "Comment",
        "Image Open",
        "삭제",
      ],
      symbolPageIndex: [
        "symbol명",
        "Symbol Path",
        "등록일",
        "Comment",
        "Image Open",
        "삭제",
      ],
      lockPageIndex: [
        "Item Number",
        "Item Name",
        "Item Classification",
        "Specification",
        "제조사",
        "Lock",
      ],
      enterReason: "사유를 입력해주세요.",
      manageProperty: "속성관리",
      manageMember: "Member 관리",
      manageItem: "Item 변경",
      cadLibraryManagement: "CAD Library 속성관리",
      classificationManagement: "아이템분류관리",
    },
    board: {
      modalFirstIndex: ["", "Part Number", "부품명", "revision", "phase"],
      modalSecondIndex: ["Part Number", "부품명"],
    },
    crco: {
      coListPageIndex: [
        "",
        "CO Number",
        "제목",
        "기안자",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      crListPageIndex: [
        "",
        "CR Number",
        "CR 이유",
        "중요도",
        "출처",
        "제목",
        "기안자",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
      ],
      reason: "이유",
      buyerOrganization: "구매처",
      reasonDetail: "이유 상세",
      crImportance: "CR 중요도",
      crSource: "CR 출처",
      buyerOrganizationId: "고객",
      clientPublishedPeriod: "고객 CO 발행일",
      clientReceivedPeriod: "고객 CO 접수일",
      reasonId: "CO 이유",
      clientItemNumber: `고객 CO Number`,
      applyPeriod: "CO적용시점",
      stageId: "CO 적용단계",
      crTitle: "CR 제목",
      crContent: "CR 내용",
      cocrModalIndex: [
        "",
        "Item Number",
        "Item Name",
        "Item Classification",
        "Revision",
        "Status",
        "CR Number",
        "CR 이유",
        "중요도",
        "출처",
        "제목",
        "기안자",
      ],
      coCheckBoxCheckList: [
        { id: 1, name: "도면수정" },
        { id: 2, name: "부품 변경" },
        { id: 3, name: "금형수정" },
        { id: 4, name: "차공구 변경" },
        { id: 5, name: "설비 변경" },
        { id: 6, name: "DFMEA변경" },
        { id: 7, name: "공정 변경" },
      ],
    },
    elec: {
      registerDate: "등록일",
      symbolSearchBoxSymbolIndex: ["", "Symbol명", "등록일", "Image"],
      footprintSearchBoxFootprintIndex: ["", "Footprint명", "등록일", "Image"],
      purchaseDetail: "부품구매 상세정보",
      produceOrganizationId: "제조사",
      produceOrganizationNumber: "제조사 부품 번호",
      substituteToFormal: "정식 부품으로 치환하기",
      credibilityReport: "신뢰성 Report",
      tempFormal: "임시/정식 부품",
      formal: "정식부품",
      temp: "임시부품",
      note: "비고",
      detail: "상세정보",
      level: "등급",
      extinct: "단종",
      extinctExpected: "단종예정",
      changing: "제조변경",
      price: "구매단가",
      truePrice: "정단가",
      tentativePrice: "가단가",
      approvedPrice: "인정가",
      change: "변경",
      save: "저장",
      alertPriceTentative:
        "부품 구매 상세 정보의 정단가와 가단가 중 하나는 반드시 입력해야 합니다.",
    },
    mpba: {
      modalIndex: ["", "Part Number", "부품명", "설계자", "version", "state"],
      receipt: "수량",
      addPart: "부품 추가",
      produceOrganizationId: "제조사",
      completeDate: "PCB 설계완료요청일",
      mpbaModalIndex: ["단위", "수량", "부품분류", "제조사", "제조사 부품번호"],
      edit: "편집",
      use: "사용",
      notUse: "미사용",
      pickOther: "대치품 지정",
    },
    pba: {
      produceOrganizationId: "제작사",
    },
    pcb: {
      compareTableIndex: [
        "부품 분류",
        "제조사",
        "제조사 부품 번호",
        "임시 단가",
        "부품 용도",
        "품명",
        "Footprint",
        "Symbol",
      ],
      checkSchematicPartList: "Schematic PartList 확인",
      noMatchWarning:
        "은 Part Number가 없거나 일치하는 Part Number가 없습니다.",
      propertyNoMatchWarning:
        "의 속성과 PDM이 관리하는 속성이 일치하지 않습니다.",
      schematicIndex: [
        "Ref",
        "Part Number",
        "단위",
        "수량",
        "부품분류",
        "제조사",
        "제조사 부품번호",
        "임시단가",
        "부품용도",
        "품명",
        "Footprint",
        "Symbol",
      ],
      circuit: "회로",
      produceOrganizationId: "제조사",
      checkPcbPartList: "PCB PartList 확인 ",
      pcbIndex: [
        "Ref",
        "Part Number",
        "단위",
        "수량",
        "부품분류",
        "제조사",
        "제조사 부품번호",
        "임시단가",
        "부품용도",
        "품명",
        "Footprint",
        "Symbol",
        "Schematic",
        "PCB",
      ],
      pcbFileIndex: [
        "Ref",
        "Part Number",
        "단위",
        "수량",
        "부품분류",
        "제조사",
        "제조사 부품번호",
        "임시단가",
        "부품용도",
        "품명",
        "Footprint",
        "Symbol",
        "Rotation",
        "Side",
        "좌표값",
        "PCB 종류",
      ],
      schematicNoMatch: "Schematic Partlist와 일치하지 않습니다.",
      design: "디자인",
      schematicDesign: "회로 디자인",
      psr_color: "PSR Color",
      making_color: "Making Color",
      pcbMaterial: "PCB 재질",
      pcbThickness: "PCB 두께",
      innerOz: "내층온즈",
      outerOz: "외층온즈",
      plating: "표면처리",
      width: "가로",
      height: "세로",
      arrayCnt: "Array 개수",
      special: "특수사양",
    },
    product: {
      productModalIndex1: ["", "Part Number", "부품명", "Revision"],
      productModalIndex2: ["Part Number", "부품명"],
      supplier: "공급업체",
      priceType: "단가종류",
      price: "단가",
      dealCondition: "거래조건",
      additionalCost: "부대경비",
      subSum: "SUB합계",
      compareMethod: "Compare 방법",
      bomWarning: "반드시 편집중인 BOM을 (임시)저장한 뒤 단가를 계산하세요.",
      calcPrice: "단가계산",
      openSRModal: "이전 SR BOM 불러오기",
      selectCarType: "차종선택",
      unitCostE: "단가 적용 환율",
      recognizedCostE: "인정가 적용 환율",
      wonDollarE: "원달러 환율",
      wonYuanE: "원엔 환율",
      currency: "통화",
      carType: "차종",
      unSelected: "미지정",
      unitCostIndexList: [
        "",
        "Level",
        "Item Number",
        "Item Name",
        "Specification",
        "구매처",
        "단가종류",
        "단가",
        "거래조건",
        "부대경비",
        "총단가",
        "인정가",
        "총인정가",
      ],
      unitCostCarModalIndex: ["차종", "달러>원화", "엔화>원화"],
    },
    project: {
      proto: "Proto",
      allDo: "All도",
      projectInfoIndex: ["제품번호", "제품명", "제품군"],
      delete: "삭제",
      mass: "양산개발",
      pro: "선행개발",
      type: "개발 유형",
      startDate: "계획시작일",
      endDate: "계획종료일",
      producer: "생산 조직",
      orderer: "발주처",
      isDel: "삭제 여부",
      isDrop: "Drop 여부",
      isPending: "Pending 여부",
      carType: "차종",
    },
    release: {
      modalIndex1: ["", "Part Number", "부품명", "Revision"],
      modalIndex2: ["Part Number", "부품명"],
      releaseProduct: "배포 Product",
      infoIndex: ["Product Number", "Project 단계"],
      infoTitle: "배포 제목",
      infoContent: "배포 내용",
      distributor: "배포처",
    },
    subsidiary: {
      supplier: "제조사",
      supplierNumber: "제조사 부품 번호",
      detail: "상세정보",
      sharing: "공용",
    },
    tokenExpired:
      "로그인 이후 많은 시간이 경과해 자동으로 로그아웃 되었습니다. 다시 로그인 해주세요.",
    needRefresh: "리프레시가 필요합니다.",
    priceTypeList: ["정단가", "가단가"],
    routeRegistered: "라우트 등록이 완료되었습니다.",
    routeRejected: "라우트 거절이 완료되었습니다.",
    batchMember: "담당자 일괄 선택하기",
    passwordErrorMessage:
      "최소 8자리이면서 1개 이상의 알파벳, 숫자, 특수문자를 포함",
    positionCheckList: [
      // { name: "ADMIN", id: 0 },
      { name: "전무", id: 1 },
      { name: "상무", id: 2 },
      { name: "이사", id: 3 },
      { name: "수석연구원", id: 4 },
      { name: "선임연구원", id: 5 },
      { name: "연구원", id: 6 },
      { name: "구매담당자", id: 7 },
    ],
    grantAdmin: "Admin 권한 부여",
    currentPosition: "현재 권한",
  },
};

const txt = (currentLang) => {
  if (!currentLang) {
    localStorage.setItem("lang", "eng");
    return textList.eng;
  }
  return textList[currentLang];
};
export default txt(localStorage.getItem("lang"));
