import React, { useState, useEffect } from "react";

import styled from "styled-components";
// import { flushSync } from "react-dom"; // Note: react가 아닌 react-dom이다
import TeamTree from "./TeamTree";
import SearchBar from "../../molecule/SearchBar";
import { ScrollDefault } from "../../Style";

const TeamSearchUIStyle = styled.div`
  padding: 20px;
  width: calc(${(props) => props.width});
  height: calc(${(props) => props.height});
  background-color: white;
  border-radius: 10px;
  .propList {
    font-size: 14px;
    color: var(--textDarkGray);
  }
  .garo {
    width: 100%;
    border-bottom: solid 1px;
  }
  .treeContainer {
    max-height: calc(90% - 70px);
    ${ScrollDefault}
    overflow-x: hidden;
  }
  .resultContainer {
    max-height: 55px;
    ${ScrollDefault}
    overflow-x: hidden;
  }
  //teamTree의 css를 최적화
  .foldButton {
    .foldSpan {
      color: var(--eciBlue);
      font-size: 40px;
      transform: translate(3px, -10px);
    }
    .box {
      font-size: 14px;
    }

    margin-left: 8px;
    height: 25px;
    /* width: 30px; */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    .minus {
      color: var(--textGray);
    }
  }
  .vertical {
    border-left: solid 1px black;
    /* opacity: ${(props) => (props.line ? 1 : 0)}; */
    height: calc(100% - 12px);
    position: absolute;
    top: 8px;
    left: 24px;
  }
  .eraseVertical {
    border-left: solid 1px white;
    /* opacity: ${(props) => (props.lastChild ? 1 : 0)}; */
    height: calc(100% - 4px);
    position: absolute;
    top: 3px;
    left: 9px;
  }

  .horizon {
    /* opacity: ${(props) => (props.leftLine ? 1 : 0)}; */

    border-bottom: solid 1px black;
    width: 10px;
    position: absolute;
    top: 2px;
    left: 9px;
  }
`;
const ResultBoxStyle = styled.div`
  height: 30px;
  border-bottom: solid 1px var(--textGray);
  color: var(--textDarkGray);
  font-size: 10px;
  padding-left: 10px;
  line-height: 30px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export default function TeamSearchUi({
  teamTreeData,
  state,
  setstate,
  width,
  height,
  unfold,
}) {
  // foldArr관리
  const [unFoldArray, setunFoldArray] = useState([]);

  const addunFold = (indexArr) => {
    const tmpArr = [...unFoldArray];
    const tmpSet = new Set(tmpArr);
    tmpSet.add([...indexArr]);
    const tmpArr2 = Array.from(tmpSet);
    setunFoldArray(tmpArr2);
  };
  const deleteUnfold = (indexArr) => {
    const strArr = unFoldArray.map((item) => JSON.stringify(item));
    const newVal = JSON.stringify(indexArr);
    const tmpArr = [...unFoldArray];
    tmpArr.splice(strArr.indexOf(newVal), 1);
    setunFoldArray(tmpArr);
  };

  const tree =
    teamTreeData &&
    teamTreeData.map((item, i) => {
      return (
        <TeamTree
          key={i}
          data={item}
          indent="15px"
          indexArray={[i]}
          state={state}
          setstate={setstate}
          unfoldArray={unFoldArray}
          setunfold={addunFold}
          deleteunfold={deleteUnfold}
          allFold={!unfold}
        />
      );
    });
  // 검색어 value추가
  const [resultValueArray, setresultValueArray] = useState([]);
  const unfoldSet = (indexArray) => {
    const safeIndexArr = [...indexArray];
    const tempFoldArr = [];
    for (let j = 0; j < indexArray.length + 1; j += 1) {
      tempFoldArr[j] = [...safeIndexArr];
      safeIndexArr.pop();
    }
    const noDupArr = [...unFoldArray, ...tempFoldArr].filter(
      (indexArr, index) => {
        const stringArr = [...unFoldArray, ...tempFoldArr].map((target) =>
          JSON.stringify(target)
        );
        return stringArr.indexOf(JSON.stringify(indexArr)) === index;
      }
    );
    setunFoldArray([]);
    setunFoldArray(noDupArr);
  };
  const resultList = resultValueArray.map((item, i) => {
    return (
      <ResultBoxStyle
        key={i}
        title={item.value}
        onClick={() => unfoldSet(item.indexArray)}
      >
        {item.value}
      </ResultBoxStyle>
    );
  });
  /// 검색하기
  const [teamSearchWord, setteamSearchWord] = useState("");

  const checkTree = (Data, IndexArray, Word) => {
    const valueArr = [];

    const callTree = (data, indexArray, word) => {
      if (data) {
        data.forEach((item, i) => {
          if (item.name && item.name.match(word)) {
            // // unfold추가
            // val추가;
            valueArr.push({
              value: item.value,
              indexArray: [...indexArray, i],
            });
          }
          callTree(item.c2SelectDtos, [...indexArray, i], word);
        });
      }
    };
    callTree(Data, IndexArray, Word, valueArr);
    setresultValueArray(valueArr);
  };

  useEffect(() => {
    if (teamSearchWord) {
      checkTree(teamTreeData, [], teamSearchWord);
    }
  }, [teamSearchWord]);
  return (
    <TeamSearchUIStyle width={width} height={height}>
      <SearchBar
        width="70%"
        height="28px"
        placeholder="Search Items"
        state={teamSearchWord}
        setState={setteamSearchWord}
      />

      <br />
      <div className="resultContainer">{resultList}</div>
      <br />
      <div className="garo" />
      <br />
      <div className="treeContainer">
        <br />
        {tree}{" "}
      </div>
    </TeamSearchUIStyle>
  );
}
